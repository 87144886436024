import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { deleteLoanInstalmentsCorrection } from '../service/loanService'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'

export const useDeleteLoanInstalmentsCorrection = (loanId: number) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: (correctionId: number) =>
      deleteLoanInstalmentsCorrection(companyId, loanId, correctionId, budgetingScenario?.id)
  })
}
