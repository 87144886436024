import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Typography, Checkbox, Button, Collapse, Form } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  plannedDeprecationRowIdSelector,
  acceleratedDeprecationRowIdSelector,
  acceleratedDeprecationDeltaRowIdsSelector
} from '../../../../redux/config/financialStatement/selectors'
import {
  depreciableBalanceSheetRowIdsSelector,
  getBalanceSheetRowByAccountCode
} from '../../../../redux/entities/statementRows/selectors'
import { Investment } from '../../../../types/investment/Investment'
import InvestmentFundingFormFields from './InvestmentFundingFormFields'
import InvestmentDeprecationFields from './InvestmentDeprecationFields'
import InvestmentFormFields from './InvestmentFormFields'
import { useAccounts } from '../../../account/queries/useFetchAccounts'
import { FormItemGroup } from '../../../../components/Form/FormItemContext'
import { ContractProduct } from '../../../../types/contract/Contract'
import { useAuthority } from '../../../../utils/Authorizable/authorize'
import { Account } from '../../../account/types/Account'

interface InvestmentFormProps {
  investment?: Investment
  isExisting: boolean
  formType?: 'investment' | 'divestment'
  sourceDisabled: boolean
  onlyDepreciable?: boolean
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({
  investment,
  isExisting,
  formType,
  sourceDisabled,
  onlyDepreciable
}) => {
  const { t } = useTranslation()
  const [taxDeprecationPlanEqual, setTaxDeprecationPlanEqual] = useState<boolean>(!investment?.taxDeprecationPlan)
  const plannedDeprecationRowId = useSelector(plannedDeprecationRowIdSelector)
  const depreciableBalanceSheetRowIds = useSelector(depreciableBalanceSheetRowIdsSelector)
  const acceleratedDeprecationRowId = useSelector(acceleratedDeprecationRowIdSelector)
  const acceleratedDeprecationDeltaRowIds = useSelector(acceleratedDeprecationDeltaRowIdsSelector)
  const isPro = useAuthority({ product: ContractProduct.PRO })
  const { data: accounts } = useAccounts()
  const form = useFormInstance()
  const accountCode = useWatch(['accountCode'], form)
  const balanceSheetRowId = useWatch(['balanceSheetRowId'], form)
  const { depreciable } =
    useSelector(
      getBalanceSheetRowByAccountCode(
        balanceSheetRowId || accounts?.find((a: Account) => a.code === accountCode)?.statementRowId
      )
    ) || {}

  return (
    <>
      <Typography.Title level={4}>
        {formType === 'investment' ? t('investmentsPage:investmentFields') : t('financialStatementsPage:divestment')}
      </Typography.Title>

      <InvestmentFormFields
        onlyDepreciable={onlyDepreciable}
        isExisting={isExisting}
        sourceDisabled={sourceDisabled}
        formType={formType}
      />

      {formType !== 'divestment' && depreciable && isPro && (
        <>
          <Typography.Title level={4}>{t('investmentsPage:deprecationFields')}</Typography.Title>
          <InvestmentDeprecationFields
            plSelectAccountIds={plannedDeprecationRowId || []}
            bsSelectBalanceSheetRowIds={depreciableBalanceSheetRowIds || []}
            fieldParent="deprecationPlan"
          />
          <Checkbox
            style={{ marginBottom: '1.5rem' }}
            checked={taxDeprecationPlanEqual}
            onChange={() => setTaxDeprecationPlanEqual(!taxDeprecationPlanEqual)}
          >
            {t('investmentsPage:taxDeprecationFieldsEqual')}
          </Checkbox>
          {!taxDeprecationPlanEqual && (
            <>
              <Typography.Title level={4}>{t('investmentsPage:taxDeprecationFields')}</Typography.Title>
              <InvestmentDeprecationFields
                plSelectAccountIds={acceleratedDeprecationDeltaRowIds || []}
                bsSelectBalanceSheetRowIds={acceleratedDeprecationRowId ? [acceleratedDeprecationRowId] : []}
                fieldParent="taxDeprecationPlan"
                showDimension={false}
              />
            </>
          )}
        </>
      )}
      {!isExisting && (
        <>
          <Form.List key="fundings" name="fundings">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Typography.Title level={4}>
                  {formType === 'investment'
                    ? t('investmentsPage:funding', { count: fields?.length })
                    : t('financialStatementsPage:divestment-counter-entries')}
                </Typography.Title>
                <Collapse
                  defaultActiveKey={[0]}
                  items={fields.map((field, i) => ({
                    key: field.key,
                    label:
                      formType === 'investment'
                        ? `${t('financialStatementsPage:funding')} ${field.name + 1}`
                        : `${t('financialStatementsPage:counter-part')} ${field.name + 1}`,
                    children: (
                      <FormItemGroup prefix={[field.name]}>
                        <InvestmentFundingFormFields formType={formType || 'investment'} />
                      </FormItemGroup>
                    ),

                    extra: i > 0 && (
                      <Button
                        danger
                        type="text"
                        size="small"
                        icon={<DeleteOutlined style={{ fontSize: '1.1rem' }} />}
                        style={{ float: 'right' }}
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    )
                  }))}
                />
                <Form.Item>
                  <Button block style={{ marginTop: 24 }} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                    {t('global:add-new')}
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}
    </>
  )
}

export default InvestmentForm
