import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Input, DatePicker, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { Investment } from '../../../../types/investment/Investment'
import {
  depreciableBalanceSheetRowIdsSelector,
  investableeBalanceSheetRowIdsSelector,
  investableStatementRowSelector
} from '../../../../redux/entities/statementRows/selectors'
import { requireContractProductExact } from '../../../../components/Misc/RestrictedProduct'
import { contextContractProductSelector } from '../../../../redux/context/contract/selectors'
import { ContractProduct } from '../../../../types/contract/Contract'
import AccountSelect from '../../../account/components/AccountSelect'
import { forecastCursorSelector } from '../../../../redux/context/filters/selectors'
import { currentAndFutureFiscalYearDateRangeSelector } from '../../../../redux/context/fiscalYears/selectors'

interface InvestmentFormFieldsProps {
  isExisting: boolean
  sourceDisabled: boolean
  investment?: Investment
  onlyDepreciable?: boolean
  formType?: 'investment' | 'divestment'
}

const InvestmentFormFields: React.FC<InvestmentFormFieldsProps> = ({
  investment,
  formType,
  isExisting,
  sourceDisabled,
  onlyDepreciable
}) => {
  const { t } = useTranslation()
  const contextContractProduct = useSelector(contextContractProductSelector)
  const investableStatementRows = useSelector(investableStatementRowSelector)
  const depreciableBalanceSheetRowIds = useSelector(depreciableBalanceSheetRowIdsSelector)
  const investableBalanceSheetRowIds = useSelector(investableeBalanceSheetRowIdsSelector)
  const fiscalYearsDateRange = useSelector(currentAndFutureFiscalYearDateRangeSelector)
  const forecastCursor = useSelector(forecastCursorSelector)

  const startDateDisabledDate = useCallback(
    (current: Dayjs) => {
      return isExisting
        ? // Can not select days after today
          current && current >= dayjs().endOf('day')
        : // Can not select days before forecastcursor if set or current
          // fiscalyear and after future fiscalyears
          current &&
            !current.isBetween(
              forecastCursor?.clone().add(1, 'month').startOf('month') || fiscalYearsDateRange?.[0],
              fiscalYearsDateRange?.[1],
              undefined,
              '[]'
            )
    },
    [fiscalYearsDateRange, isExisting]
  )

  const deprecationBsSelectBalanceSheetRowIds = onlyDepreciable
    ? depreciableBalanceSheetRowIds
    : [...depreciableBalanceSheetRowIds, ...investableBalanceSheetRowIds]

  const statementRowSelectOptions = () => {
    return investableStatementRows.map(row => (
      <Select.Option key={row.id} value={row.id}>
        {t(`balanceSheet:${row.id}`)}
      </Select.Option>
    ))
  }

  return (
    <Row gutter={16} align="bottom">
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Col span={12}>
        {contextContractProduct && requireContractProductExact(contextContractProduct, ContractProduct.PRO) ? (
          <Form.Item
            label={
              formType === 'investment'
                ? `${t('financialStatementsPage:source')}`
                : `${t('financialStatementsPage:divesting-what')}`
            }
            name="accountCode"
            rules={[{ required: true, message: '' }]}
          >
            <AccountSelect
              balanceSheetRowIds={investment ? [investment.balanceSheetRowId] : deprecationBsSelectBalanceSheetRowIds}
              disabled={sourceDisabled}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={
              formType === 'investment'
                ? `${t('financialStatementsPage:source')}`
                : `${t('financialStatementsPage:divesting-what')}`
            }
            name="balanceSheetRowId"
            rules={[{ required: true, message: '' }]}
          >
            <Select disabled={sourceDisabled} showSearch optionFilterProp="children">
              {statementRowSelectOptions()}
            </Select>
          </Form.Item>
        )}
      </Col>
      <Col span={12}>
        <Form.Item
          label={
            formType === 'investment'
              ? t('financialStatementsPage:value')
              : `${t('financialStatementsPage:divestment-amount')}`
          }
          name="value"
          rules={[{ required: true, message: '' }]}
        >
          <FormattedInputNumber
            min={formType === 'investment' ? 0 : undefined}
            max={formType === 'investment' ? undefined : 0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={t('financialStatementsPage:date')} name="date" rules={[{ required: true, message: '' }]}>
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} disabledDate={startDateDisabledDate} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="name"
          label={t('financialStatementsPage:description')}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default InvestmentFormFields
