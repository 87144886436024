import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { MultiDashboard } from '../types/MultiDashboard'
import { useDimensionDashboardsOptions } from './useDimensionDashboards'
import { updateDimensionDashboard } from '../service/dashboardService'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'

export const useUpdateDimensionDashboard = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useDimensionDashboardsOptions()

  return useMutation({
    mutationFn: ({ data, id }: { data: Partial<MultiDashboard>; id: number }) =>
      updateDimensionDashboard({ data, companyId, id }),
    onSuccess: newItem => {
      queryClient.setQueryData(options.queryKey, (oldItems = []) => {
        return oldItems.map(item => (item.id === newItem.id ? { ...item, ...newItem } : item))
      })
    }
  })
}
