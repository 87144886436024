import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { MultiDashboard } from '../types/MultiDashboard'
import { useScenarioDashboardsOptions } from './useScenarioDashboards'
import { createScenarioDashboard } from '../service/dashboardService'

export const useCreateScenarioDashboard = () => {
  const dispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useScenarioDashboardsOptions()

  return useMutation({
    mutationFn: (data: Partial<MultiDashboard>) => createScenarioDashboard({ data, companyId }),
    onSuccess: newItem => {
      queryClient.setQueryData(options.queryKey, (oldItems = []) => {
        return [...oldItems, newItem]
      })
      dispatch(setMultiPurposeDashboardActiveDashboard(newItem.id))
    }
  })
}
