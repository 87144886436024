import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChartCategories, useChartSeries } from '../../../../../components/Chart/hooks'
import { ReportTableRow } from '../../../../../components/Table/types'
import { Report, ReportDataType } from '../../../../../redux/context/reports/types'

HighchartsExporting(Highcharts)

interface KeyPerformanceIndicatorChartProps {
  reportTableRow: ReportTableRow
}

const KeyPerformanceIndicatorChart: React.FC<KeyPerformanceIndicatorChartProps> = (
  props: KeyPerformanceIndicatorChartProps
) => {
  const { reportTableRow } = props
  const [report, setReport] = useState<Report>([])
  const { t } = useTranslation()
  const categories = useChartCategories(ReportDataType.actuals)
  const series: any[] = useChartSeries(report, { dataType: ReportDataType.actuals })

  const rowSerie = series[0]

  useEffect(() => {
    setReport([reportTableRow] as Report)
  }, [reportTableRow])

  const name = t(`keyFigureStatement:${reportTableRow.id}`)

  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    chart: {
      backgroundColor: undefined,
      borderWidth: 0,
      type: 'areaspline',
      margin: [0, 0, 0, 0],
      width: 150,
      height: 20
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
      categories
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        }
        // fillOpacity: 0.25
      },
      column: {
        negativeColor: '#910000',
        borderColor: 'silver'
      }
    },
    tooltip: {
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${name}: <b>{point.y}</b> <br />`,
      headerFormat: '<b>{point.key}</b><br>',
      hideDelay: 0,
      useHTML: true,
      outside: true,
      shared: true
    },
    series: [
      {
        ...rowSerie,
        pointStart: 0,
        type: 'areaspline'
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default KeyPerformanceIndicatorChart
