import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { Investment } from '../../../types/investment/Investment'
import { updateInvestment } from '../service/investmentService'
import { useInvestmentOptions } from './useInvestment'
import loanQueryKeys from '../../loan/queries/loanQueryKeys'

export const useUpdateInvestment = (isExisting: boolean) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useInvestmentOptions(isExisting)
  const { message } = App.useApp()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (investment: Investment) => updateInvestment(companyId, investment),
    mutationKey: ['investment'],
    onSuccess: () => {
      message.success(t('messages:updateSuccess', 'Success'))
      queryClient.invalidateQueries({
        queryKey: loanQueryKeys.all
      })
      return queryClient.invalidateQueries({ queryKey: options.queryKey })
    }
  })
}
