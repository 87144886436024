import dayjs from 'dayjs'
import { BackendRequest } from '../../../services/backend'
import { CustomReport } from '../../../redux/context/customReports/types'
import { Company } from '../../../types/company/Company'
import { QuickFilterForm } from './components/QuickFilters'
import { setAllSectionData, setCalculatedReport, setSectionsData } from '../../../redux/context/customReports/actions'
import { CustomReportSectionsType } from '../../../redux/context/customReports/typesSection'
import { Language } from '../../../types/user/User'
import { notificationAction } from '../../../redux/middleware/actions'
import { chunkArray } from '../../../utils/helpers'

export const fetchCustomReportSection = async (
  request: BackendRequest,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilter?: QuickFilterForm,
  allReports?: CustomReportSectionsType[] | undefined
): Promise<CustomReportSectionsType> => {
  return request
    .get({
      urlParams: { companyId, reportId, sectionId },
      body: {
        params: {
          date: date && dayjs(date).format('YYYY-MM-DD'),
          quickFilter,
          dimensions: undefined
        }
      }
    })
    .then((res: CustomReportSectionsType) => res)
    .catch(() => allReports?.find(r => r?.id === sectionId) || [])
}

export const fetchCustomReportSectionNew = async (
  request: BackendRequest,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  sectionId: CustomReportSectionsType['id'],
  date?: string,
  quickFilter?: QuickFilterForm,
  language?: Language
): Promise<CustomReportSectionsType> => {
  return request
    .get({
      urlParams: { companyId, reportId, sectionId },
      body: {
        params: {
          lng: language,
          date: date && dayjs(date).format('YYYY-MM-DD'),
          quickFilter,
          dimensions: undefined
        }
      }
    })
    .catch(() => {
      return []
    })
}

export const getCustomReportSections = async (
  dispatch: Function,
  sections: string[],
  request: BackendRequest,
  companyId: Company['id'],
  reportId: CustomReport['id'],
  date?: string,
  quickFilter?: QuickFilterForm,
  addRows?: boolean,
  allReports?: CustomReportSectionsType[] | undefined
) => {
  const s = 6 // Number of simultaneous requests (bäkkiserverien määrä)
  const k = 6 // Maximum sections per request.

  // Function to send a batch of requests in parallel.
  const sendBatch = async (sectionsBatch: string[]): Promise<CustomReportSectionsType[]> => {
    return Promise.all(
      sectionsBatch.map(c => {
        return fetchCustomReportSection(request, companyId, reportId, +c, date, quickFilter, allReports)
      })
    )
  }

  // Chunk the items into arrays of size k.
  const sectionChunks = chunkArray(sections, k)

  // Function to send requests in parallel.
  async function sendRequestsInParallel() {
    let responses: CustomReportSectionsType[] = []
    for (let i = 0; i < sectionChunks.length; i += s) {
      const batch = sectionChunks.slice(i, i + s)
      for (const b of batch) {
        // eslint-disable-next-line no-await-in-loop
        const awaited = await sendBatch(b)
        for (const a of awaited) {
          responses = [...responses, a]
        }
      }
    }
    return responses
  }

  // Start sending requests.
  const responses = await sendRequestsInParallel()
  if (addRows) {
    dispatch(setSectionsData(responses))
  } else {
    responses && dispatch(setAllSectionData(responses))
  }
  return 'Success'
}

export async function fetchInBatches<T>(
  requestFactories: (() => Promise<T>)[],
  batchSize: number,
  callback: (results: T[]) => void
) {
  for (let i = 0; i < requestFactories.length; i += batchSize) {
    const batch = requestFactories.slice(i, i + batchSize).map(factory => factory())
    // eslint-disable-next-line no-await-in-loop
    const batchResults = await Promise.all(batch)
    callback(batchResults)
  }
}

export const fetchReportData = async (
  dispatch: Function,
  getReportDataRequest: BackendRequest,
  companyId: string,
  report?: CustomReport
) => {
  if (report) {
    await getReportDataRequest
      .get({ urlParams: { companyId, reportId: report.id } })
      .then((res: CustomReport) => dispatch(setCalculatedReport(res)))
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'Error fetching data'
          })
        )
        dispatch(setCalculatedReport(null))
      })
  }
}
