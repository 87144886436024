import React from 'react'
import { App, Upload } from 'antd'
import { useSelector } from 'react-redux'
import { UploadProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { createInvestmentImport } from '../service/investmentService'
import investmentQueryKeys from '../queries/investmentQueryKeys'

const InvestmentImport = () => {
  const { t } = useTranslation()
  const { notification } = App.useApp()
  const queryClient = useQueryClient()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario: { id: budgetingScenarioId } = {} } = useSelector(filtersSelector)

  const handleSubmit: UploadProps['customRequest'] = async ({ file }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      await createInvestmentImport({ data: formData, companyId, budgetingScenarioId })
      queryClient.invalidateQueries({
        queryKey: investmentQueryKeys.summaries()
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        // TODO: Mayde general handler
        notification.error({
          message: 'Error!',
          description: `${t(`error:${error?.response?.data.message}`, 'Hupsista!')}!`
        })
      }
    }
  }

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    customRequest: handleSubmit
  }

  return <Upload {...props}>{t('investmentsPage:csvImport')}</Upload>
}

export default InvestmentImport
