import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { updateInvestmentDepreciation } from '../service/investmentService'
import { DepreciationItem } from '../components/InvestmenDeprecationModal'

export const useUpdateInvestmentDepreciation = () => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return useMutation({
    mutationFn: ({
      data,
      deprecationPlanId,
      correctionId
    }: {
      data: Partial<DepreciationItem>
      deprecationPlanId: number
      correctionId: number
    }) => updateInvestmentDepreciation(data, companyId, deprecationPlanId, correctionId)
  })
}
