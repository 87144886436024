import { useTranslation } from 'react-i18next'
import { Typography, Table, TableProps } from 'antd'
import React from 'react'
import './_FormulaHelp.sass'

const { Text, Paragraph } = Typography

interface DataType {
  function: string
  explanation: string
}

const FormulaHelpHelp = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Paragraph>{t('formulaHelp:helpHeader1')}</Paragraph>
      <Paragraph>
        <ol>
          <li>{t('formulaHelp:helpList1')}</li>
          <li>{t('formulaHelp:helpList2')}</li>
          <li>{t('formulaHelp:helpList3')}</li>
        </ol>
      </Paragraph>
      <Paragraph>
        <Text strong>{t('formulaHelp:helpFunctionHeader')}</Text>
        <Table
          size="small"
          pagination={false}
          className="formulaHelpTable"
          rowKey={r => r.function}
          columns={
            [
              {
                title: t('formulaHelp:function'),
                dataIndex: 'function',
                key: 'function'
              },
              {
                title: t('formulaHelp:explanation'),
                dataIndex: 'explanation',
                key: 'explanation'
              }
            ] as TableProps<DataType>['columns']
          }
          dataSource={[
            {
              function: 'account',
              explanation: t('formulaHelp:explanationAccount')
            },
            {
              function: 'account_entries',
              explanation: t('formulaHelp:account_entries')
            },
            {
              function: 'bs',
              explanation: t('formulaHelp:explanationBs')
            },
            {
              function: 'customer',
              explanation: t('formulaHelp:customer')
            },
            {
              function: 'customer_group',
              explanation: t('formulaHelp:customer_group')
            },
            {
              function: 'delta',
              explanation: t('formulaHelp:delta')
            },
            {
              function: 'div',
              explanation: t('formulaHelp:explanationDiv')
            },
            {
              function: 'global_ref',
              explanation: t('formulaHelp:global_ref')
            },
            {
              function: 'group_account',
              explanation: t('formulaHelp:group_account')
            },
            {
              function: 'growth',
              explanation: t('formulaHelp:growth')
            },
            {
              function: 'gs',
              explanation: t('formulaHelp:explanationGs')
            },
            {
              function: 'if',
              explanation: t('formulaHelp:explanationIf')
            },
            {
              function: 'max',
              explanation: t('formulaHelp:explanationMax')
            },
            {
              function: 'min',
              explanation: t('formulaHelp:explanationMin')
            },
            {
              function: 'onedrive',
              explanation: t('formulaHelp:onedrive')
            },
            {
              function: 'pl',
              explanation: t('formulaHelp:explanationPl')
            },
            {
              function: 'product',
              explanation: t('formulaHelp:product')
            },
            {
              function: 'product_family',
              explanation: t('formulaHelp:product_family')
            },
            {
              function: 'product_group',
              explanation: t('formulaHelp:product_group')
            },
            {
              function: 'project',
              explanation: t('formulaHelp:project')
            },
            {
              function: 'receipts',
              explanation: t('formulaHelp:receipts')
            },
            {
              function: 'ref',
              explanation: t('formulaHelp:explanationRef')
            },
            {
              function: 'route',
              explanation: t('formulaHelp:route')
            },
            {
              function: 'sales',
              explanation: t('formulaHelp:sales')
            },
            {
              function: 'use_dimension',
              explanation: t('formulaHelp:use_dimension')
            },
            {
              function: 'variable',
              explanation: t('formulaHelp:variable')
            },
            {
              function: 'work_hour',
              explanation: t('formulaHelp:work_hour')
            }
          ]}
        />
      </Paragraph>
      <Paragraph>{t('formulaHelp:explanationInfo')}</Paragraph>
    </div>
  )
}

export default FormulaHelpHelp
