export enum ChartBasicType {
  column = 'column',
  line = 'line',
  spline = 'spline'
}

export enum ChartSpecialType {
  stackedColumn = 'stackedColumn',
  groupedColumn = 'groupedColumn'
}

export const ChartType = { ...ChartBasicType, ...ChartSpecialType }
export type ChartType = ChartBasicType | ChartSpecialType
