import React, { ReactNode } from 'react'
import { useAuthority } from '../../utils/Authorizable/authorize'
import { Authority } from '../../utils/Authorizable/types'

interface AuthorizedProps {
  authority: Authority
  children: ReactNode
}

const Authorized = ({ authority, children }: AuthorizedProps) => {
  const authorized = useAuthority(authority)

  if (!authorized) return null

  return <>{children}</>
}

export default Authorized
