import React from 'react'

interface CustomHeaderCellProp extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string
}

const CustomHeaderCell: React.FC<CustomHeaderCellProp> = props => {
  const { style: propsStyle } = props

  const style: React.CSSProperties = {
    ...propsStyle,
    fontSize: '10px'
  }

  return <th {...props} style={style} />
}

export default CustomHeaderCell
