import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useCompaniesDashboardsOptions } from './useCompaniesDasboards'
import { deleteCompaniesDashboard } from '../service/dashboardService'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { AppDispatch } from '../../../redux/store'
import { getPrevOrNextId } from '../utils'
import useDeleteQueryItem from '../../../services/api/hooks/useDeleteQueryItem'

export const useDeleteCompaniesDashboard = () => {
  const dispatch: AppDispatch = useDispatch()
  const queryClient = useQueryClient()
  const options = useCompaniesDashboardsOptions()
  const deleteQueryItem = useDeleteQueryItem()

  return useMutation({
    mutationFn: (id: number) => deleteCompaniesDashboard(id),
    onSuccess: newItem => {
      deleteQueryItem(newItem, options)
      dispatch(
        setMultiPurposeDashboardActiveDashboard(getPrevOrNextId(newItem, queryClient.getQueryData(options.queryKey)))
      )
    }
  })
}
