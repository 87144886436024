import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { logoutAction } from '../../../redux/session/authentication/actions'
import { AppDispatch } from '../../../redux/store'

export function useLogout() {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return () => {
    dispatch(logoutAction())
    queryClient.invalidateQueries()
    navigate('/user/login', { replace: true })
  }
}
