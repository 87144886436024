import { Button, Form, Row, Input, Divider, Popconfirm, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import ColorPicker from '../../../../reporting/custom/components/categorySelection/ColorPicker'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  updateCompanyChartColorsRequest,
  deleteCompanyChartColorsRequest
} from '../../../../../redux/context/companySettings/actions'
import { AppDispatch } from '../../../../../redux/store'
import { companySettingsChartColorSelector } from '../../../../../redux/context/companySettings/selectors'
import { contextRequest } from '../../../../../redux/context/actions'
import { loadingSelector } from '../../../../../redux/loading/selectors'

const { Title } = Typography
export interface ChartColorPalette {
  companyId: string
  colors: string[]
}

export const initialColors = [
  '#0225A1',
  '#408C96',
  '#F6B847',
  '#EA4749',
  '#2E5266',
  '#C5C5C5',
  '#F4B895',
  '#ED7C7C',
  '#80A1D4',
  '#74C7C7',
  '#124D87',
  '#F5E6A2'
]

const GraphColorSettings: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const companyId = useSelector(contextCompanyIdSelector)
  const chartColors = useSelector(companySettingsChartColorSelector)
  const dispatch: AppDispatch = useDispatch()
  const { companyChartColors: companyChartColorsLoading, deleteCompanyChartColors: deleteCompanyChartColorsLoading } =
    useSelector(loadingSelector)

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ colorPalette: chartColors.map(i => ({ color: i, hex: i })) })
  }, [companyId, chartColors])

  const onSubmit = () => {
    if (companyId) {
      const hexColors = form.getFieldsValue().colorPalette.map((c: { hex?: string; color: string }) => c?.hex)
      const dataToBackend: ChartColorPalette = {
        companyId,
        colors: hexColors
      }
      dispatch(contextRequest(updateCompanyChartColorsRequest, dataToBackend))
    }
  }

  const handleColorChange = (name: number, newColor?: string) => {
    if (newColor) {
      const rgb2hex = (c: string) =>
        `#${(c.match(/\d+/g) || []).map(x => (+x).toString(16).padStart(2, '0')).join(``)}`.slice(0, -2)
      form.setFieldValue(['colorPalette', name, 'hex'], rgb2hex(newColor).toUpperCase())
    } else {
      form.setFieldValue(['colorPalette', name, 'hex'], '#FFFFFF')
    }
  }

  return (
    <>
      <Title level={5}>{t('appearancePage:color-palette-info')}</Title>
      <Form form={form} layout="horizontal" onFinish={onSubmit}>
        <Form.List name="colorPalette">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Row key={field.name} style={{ width: 210 }}>
                    <Form.Item style={{ width: 70 }}>
                      {t('global:variable')} {index + 1}
                    </Form.Item>
                    <Form.Item style={{ width: 30 }} name={[field.name, 'color']}>
                      <ColorPicker
                        verticalPosition="bottom"
                        onChange={(color: string | undefined) => handleColorChange(field.name, color)}
                      />
                    </Form.Item>
                    <Form.Item style={{ width: 72 }} name={[field.name, 'hex']}>
                      <Input readOnly variant="borderless" />
                    </Form.Item>
                    <Button icon={<DeleteOutlined />} type="text" danger onClick={() => remove(field.name)} />
                    <Divider style={{ marginTop: -10, marginBottom: 0 }} />
                  </Row>
                ))}
                <Row style={{ marginTop: '20px' }}>
                  <Button onClick={() => add()}>{`+ ${t('global:add-new')}`}</Button>

                  <Button
                    style={{ marginLeft: '20px' }}
                    loading={companyChartColorsLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    {t('global:save')}
                  </Button>
                  <Popconfirm
                    placement="topRight"
                    title={t('global:delete-confirm')}
                    onConfirm={() => dispatch(contextRequest(deleteCompanyChartColorsRequest, initialColors))}
                  >
                    <Button loading={deleteCompanyChartColorsLoading} style={{ marginLeft: '20px' }} danger>
                      {t('global:restoreDefaultColors')}
                    </Button>
                  </Popconfirm>
                </Row>
              </>
            )
          }}
        </Form.List>
      </Form>
    </>
  )
}

export default GraphColorSettings
