import { queryOptions, useQuery } from '@tanstack/react-query'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dashboardQueryKeys from './dashbordQueryKeys'
import { fetchDimensionDashboards } from '../service/dashboardService'
import { MultiDashboard } from '..'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { AppDispatch } from '../../../redux/store'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { Orderable } from '../../../types/orderable/Orderable'

export const useDimensionDashboardsOptions = <TData = MultiDashboard[]>(select?: (data: MultiDashboard[]) => TData) => {
  const { companyId } = useContextParameters()

  return queryOptions({
    queryKey: dashboardQueryKeys.dimensions({ companyId }),
    queryFn: fetchDimensionDashboards,
    select
  })
}

export const useDimensionDashboardsQuery = <TData = MultiDashboard[]>(select?: (data: MultiDashboard[]) => TData) => {
  const options = useDimensionDashboardsOptions(select)
  return useQuery(options)
}

const orderSort = <T extends Orderable>(a: T, b: T) => {
  if (a.order === undefined) return 1 // Push `a` to the end
  if (b.order === undefined) return -1 // Push `b` to the end
  return a.order - b.order // Normal comparison
}

export const useDimensionDashboards = () => useDimensionDashboardsQuery(dashboards => dashboards.sort(orderSort))

export const useDimensionSubtypeDashboardsPage = (subType: MultiDashboard['subType'] = 'DEFAULT') => {
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)

  const query = useDimensionDashboardsQuery(dashboards => dashboards.sort(orderSort).filter(d => d.subType === subType))

  React.useEffect(() => {
    if (query.data && !activeDashboard) {
      dispatch(setMultiPurposeDashboardActiveDashboard(query.data?.[0]?.id))
    }
  }, [query.data, activeDashboard])

  return query
}

export const useDimensionSubtypeDashboards = (subType: MultiDashboard['subType'] = 'DEFAULT') => {
  return useDimensionDashboardsQuery(dashboards => dashboards.sort(orderSort).filter(d => d.subType === subType))
}

// COMPACT
export const compactDimensionDashboarSelector = (dashboards?: MultiDashboard[]) =>
  dashboards?.sort(orderSort).filter(d => d.subType === 'COMPACT')

export const useDimensionCompactDashboardsOptions = () =>
  useDimensionDashboardsOptions(compactDimensionDashboarSelector)

export const useDimensionCompactDashboardsPage = () => useDimensionSubtypeDashboardsPage('COMPACT')
export const useDimensionCompactDashboards = () => useDimensionSubtypeDashboards('COMPACT')

// DEFAULT
export const defaultDimensionDashboarSelector = (dashboards?: MultiDashboard[]) =>
  dashboards?.sort(orderSort).filter(d => d.subType === 'DEFAULT')

export const useDimensionDefaultDashboardsOptions = () =>
  useDimensionDashboardsOptions(defaultDimensionDashboarSelector)

export const useDimensionDefaultDashboardsPage = () => useDimensionSubtypeDashboardsPage('DEFAULT')
export const useDimensionDefaultDashboards = () => useDimensionSubtypeDashboards('DEFAULT')

export default useDimensionDashboards
