/* eslint-disable no-nested-ternary */
import { Skeleton } from 'antd'
import React, { createRef, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { CustomReportSectionsType } from '../../../../redux/context/customReports/typesSection'
import CategoryProvider from './categorySelection/CategoryContext'
import ReportSection from './section/CustomReportSection'
import SectionModal from './section/CustomReportSectionModal'
import { StyledResponsiveGridLayout } from './layout/StyledComponents'
import LayoutItem from './layout/LayoutItem'
import Loading from '../../../../shared/components/Loading'
import { BREAKPOINTS, COLS, ROW_HEIGHT, getDataGrid } from './grid.utils'
import { LayoutsContext, LayoutsContextType } from './layout/LayoutContext'

interface CustomReportGridProps {
  itemsLoading?: number[]
  calculatedReport?: CustomReport
}

const CustomReportGrid: React.FC<CustomReportGridProps> = ({ calculatedReport, itemsLoading }) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const [sectionModalVisible, setSectionModalVisible] = useState(false)
  const [editSectionData, setEditSectionData] = useState<CustomReportSectionsType | null>(null)
  const ref = createRef()
  const [initialLoading, setLoading] = useState(true)
  const { layouts, currentBreakPoint, setCurrentBreakPoint } = useContext(LayoutsContext) as LayoutsContextType

  const loadingHandler = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const editModal = (editSection: CustomReportSectionsType | null) => {
    setEditSectionData(editSection)
    setSectionModalVisible(true)
  }

  useEffect(() => {
    loadingHandler()
  }, [companyId])

  const children = calculatedReport?.sections?.map(section => {
    const dataGrid = getDataGrid(section, currentBreakPoint, layouts)
    return (
      <LayoutItem
        ref={ref}
        key={section.id}
        data-grid={{
          ...dataGrid,
          static: false,
          isDraggable: false,
          isResizable: false
        }}
        section={section}
        content={
          itemsLoading?.includes(section.id) ? (
            <Skeleton active style={{ padding: 16 }} />
          ) : (
            <ReportSection key={section.id} section={section} editModal={editModal} />
          )
        }
      />
    )
  })

  if (initialLoading) return <Loading />

  return (
    <>
      {sectionModalVisible && (
        <CategoryProvider visible={sectionModalVisible} categories={(editSectionData as any)?.categories || []}>
          <SectionModal
            visible={sectionModalVisible}
            setVisible={setSectionModalVisible}
            key="add-section"
            reportId={calculatedReport?.id}
            section={editSectionData || null}
          />
        </CategoryProvider>
      )}

      <StyledResponsiveGridLayout
        onBreakpointChange={setCurrentBreakPoint}
        breakpoints={BREAKPOINTS}
        cols={COLS}
        rowHeight={ROW_HEIGHT}
        resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      >
        {children}
      </StyledResponsiveGridLayout>
    </>
  )
}

export default CustomReportGrid
