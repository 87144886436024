import { red, green } from '@ant-design/colors'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Popover, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { multiPurposeDashboardPageSelector } from '../../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { formatValueByType } from '../../../../utils/helpers'
import { globalKeyFigureConfig, GlogalKeyFigureMap } from '../config'
import { MultiPurposeDashboardContext, MultiPurposeDashboardContextType } from '../MultiPurposeDashboardProvider'
import { vatIdSlicer, flattenObject } from '../utils'
import { useFixedNameRow } from '../../../../components/Table/hooks'
import { VariableType } from '../../../../components/Table/types'

interface MultiCompanyDashboardTableTwoProps {
  tableData?: any
}

interface CurrentValue {
  label: string
  value?: number
  type: VariableType
}

interface HistoryValue {
  label: string
  value?: number
  type: VariableType
}

const MultiPurposeDashboardTableTwo = ({ tableData }: MultiCompanyDashboardTableTwoProps) => {
  const { formulas } = useSelector(multiPurposeDashboardPageSelector)
  const tableElement = useRef<HTMLDivElement>(null)
  const fixedNameRow = useFixedNameRow()

  const { t } = useTranslation()
  const { loadingDimensionData, dashboard, loading, formSaveLoading } = useContext(
    MultiPurposeDashboardContext
  ) as MultiPurposeDashboardContextType

  const getCompareComponent = (currentValue: CurrentValue, compareValue: HistoryValue) => {
    if (!currentValue?.value || !compareValue?.value) return null
    const compareIcon =
      currentValue.value < compareValue.value ? (
        <ArrowDownOutlined style={{ marginRight: 4, color: red.primary }} />
      ) : (
        <ArrowUpOutlined style={{ marginRight: 4, color: green.primary }} />
      )

    return (
      <Popover content={formatValueByType(compareValue.value, compareValue.type)} title={compareValue.label}>
        {compareIcon}
      </Popover>
    )
  }

  const generateColumns = () => {
    const render = (val: { currentValue: CurrentValue; historyValue: HistoryValue }) => {
      const { currentValue, historyValue } = val || {}
      if (!currentValue || !historyValue) return ''
      const compareComponent = getCompareComponent(currentValue, historyValue)
      return (
        <>
          {compareComponent}
          {currentValue.value && formatValueByType(currentValue.value, currentValue.type)}
        </>
      )
    }

    const columns: ColumnProps<any>[] = [
      {
        title: 'Tunnusluku',
        dataIndex: 'keyFigure',
        width: 200,
        fixed: fixedNameRow,
        key: 'name'
      }
    ]

    const companies = tableData.map((company: any) => ({
      title: (
        <>
          <div>{company.name}</div>
          <div>{vatIdSlicer(company?.id.toString(), company)}</div>
        </>
      ),
      width: 150,
      dataIndex: company?.id,
      key: company?.id.toString(),
      align: 'right' as const,
      render
    }))

    return columns.concat(companies)
  }

  const generateData = (config: GlogalKeyFigureMap) => {
    const obj = {}
    tableData.map((element: any) => {
      Object.assign(obj, { [element.id]: element[config.id] })
      return null
    })
    return obj
  }

  const keyfigureData = globalKeyFigureConfig
    .filter(cfg => (dashboard?.keyFigures || []).includes(cfg.id))
    .map(config => ({
      title: t(`globalKeyFigureStatement:${config.id}`),
      key: config?.id.toString(),
      keyFigure: t(`globalKeyFigureStatement:${config.id}`),
      ...generateData(config)
    }))

  const formulaData = flattenObject(formulas)
    .filter(f => (dashboard?.formulas || []).includes(f?.id))
    .map(formula => ({
      title: formula.name,
      keyFigure: formula.name,
      key: formula?.id.toString(),
      ...generateData(formula)
    }))

  return (
    <div ref={tableElement}>
      <Table
        tableLayout="fixed"
        loading={loading || loadingDimensionData || formSaveLoading}
        className="table-darken-levels report-table"
        rowKey={record => `${record.dimensionId ? 'dimension' : 'company'}-${record.id?.toString()}`}
        size="middle"
        columns={generateColumns()}
        dataSource={[...keyfigureData, ...formulaData]}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default MultiPurposeDashboardTableTwo
