import { dimensionsArgumentOptionsSelector } from '../../../../../redux/context/dimensions/selectors'
import { refArgumentOptionsSelector, varArgumentOptionsSelector } from '../../../../../redux/context/formulas/selectors'
import { subsidiariesArgumentOptionsSelector } from '../../../../../redux/context/subsidiaries/selectors'
import {
  profitAndLossArgumentOptionSelector,
  balanceSheetArgumentOptionSelector
} from '../../../../../redux/entities/statementRows/selectors'
import { currentUserCompaniesArgumentOptionsSelector } from '../../../../../redux/session/currentUser/selectors'
import { isGroupCompany } from '../../../../../routes/utils'
import { useAccountsArgumentOptions } from '../../../../../features/account/queries/useFetchAccounts'
import { ContractFranchise } from '../../../../../types/contract/Contract'
import { CustomerGroup } from '../../../../../types/customer/customer'
import { TaskApplication } from '../../../../superuser/types'
import {
  aggregationOptions,
  booleanOptions,
  periodOptions,
  projectSourceOptions,
  receiptsMeasureOptions,
  receiptsSourceOptions,
  routeSourceOptions,
  salesItemMeasureOptions,
  salesItemSourceOptions,
  salesSourceOptions,
  sourceOptions,
  workhourSourceOptions
} from './config'
import { FormulaFunction, FormulaProduct, WorkType } from './types'

export const useFunctions = () => {
  const { data: companyAccountsArgumentOptionsSelector } = useAccountsArgumentOptions()
  const functions: {
    [key: string]: FormulaFunction
  } = {
    account: {
      name: 'account',
      arguments: [
        {
          label: 'code',
          example: '[3000, 3001, 3002]',
          optionsConfig: {
            optionsSelector: companyAccountsArgumentOptionsSelector
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    group_account: {
      name: 'group_account',
      authority: {
        custom: isGroupCompany
      },
      arguments: [
        {
          label: 'company_id',
          example: "'29091575'",
          optionsConfig: {
            optionsSelector: subsidiariesArgumentOptionsSelector
          }
        },
        {
          label: 'code',
          example: '[3000, 3001, 3002]',
          optionsConfig: {
            optionsSelector: companyAccountsArgumentOptionsSelector
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    global_ref: {
      name: 'global_ref',
      authority: {
        custom: isGroupCompany
      },
      arguments: [
        {
          label: 'company_id',
          example: "'29091575'",
          optionsConfig: {
            optionsSelector: currentUserCompaniesArgumentOptionsSelector
          }
        },
        {
          label: 'code',
          example: `'liikevaihto-kum'`,
          optionsByPrevArgument: {
            default: {
              request: {
                url: [`/api/companies/{companyId}/settings/key-figures/formulas`, undefined],
                prevArgumentCompany: true,
                dataTransform: formula =>
                  formula.code && {
                    name: formula.name,
                    value: `'${formula.code}'`
                  }
              }
            }
          }
        }
      ]
    },
    pl: {
      name: 'pl',
      arguments: [
        {
          label: 'key',
          example: '5',
          optionsConfig: {
            optionsSelector: profitAndLossArgumentOptionSelector
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    bs: {
      name: 'bs',
      arguments: [
        {
          label: 'key',
          example: '[58, 59]',
          optionsConfig: {
            optionsSelector: balanceSheetArgumentOptionSelector
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: {
            options: periodOptions
          }
        }
      ]
    },
    ref: {
      name: 'ref',
      arguments: [
        {
          label: 'code',
          example: "['code1', 'code2']",
          optionsConfig: {
            optionsSelector: refArgumentOptionsSelector
          }
        }
      ]
    },
    gs: {
      name: 'gs',
      arguments: [
        {
          label: 'code',
          example: "'code'",
          optionsByPrevArgument: {
            default: {
              request: {
                url: [`/api/companies/{companyId}/integrations/google/sheets/external`, undefined],
                dataTransform: sheet => ({
                  name: sheet.code,
                  value: `'${sheet.code}'`
                })
              }
            }
          }
        },
        { label: 'key', example: '2' },
        {
          label: 'aggregation function',
          example: 'lastValue',
          optionsConfig: {
            options: aggregationOptions
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: {
            options: periodOptions
          }
        }
      ]
    },
    onedrive: {
      name: 'onedrive',
      arguments: [
        {
          label: 'code',
          example: "'code'",
          optionsByPrevArgument: {
            default: {
              request: {
                url: [`/onedrive/sheets/{companyId}/external`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: sheet => ({
                  name: sheet.code,
                  value: `'${sheet.code}'`
                })
              }
            }
          }
        },
        { label: 'key', example: '2' },
        {
          label: 'aggregation function',
          example: 'lastValue',
          optionsConfig: {
            options: aggregationOptions
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: {
            options: periodOptions
          }
        }
      ]
    },
    div: {
      name: 'div',
      arguments: [
        { label: 'numerator', example: 'pl(25, current)' },
        { label: 'denominator', example: 'pl(1, current)' },
        { label: 'expression if denominator is 0', example: '0' }
      ]
    },
    delta: {
      name: 'delta',
      arguments: [
        {
          label: 'source',
          example: 'account',
          optionsConfig: {
            options: sourceOptions
          }
        },
        {
          label: 'key',
          example: '[2971, 2972]',
          optionsByPrevArgument: {
            pl: { optionsSelector: profitAndLossArgumentOptionSelector },
            bs: { optionsSelector: balanceSheetArgumentOptionSelector },
            account: {
              optionsSelector: companyAccountsArgumentOptionsSelector
            }
          }
        },
        {
          label: 'inversed',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        }
      ]
    },
    growth: {
      name: 'growth',
      arguments: [
        {
          label: 'source',
          example: 'account',
          optionsConfig: {
            options: sourceOptions
          }
        },
        {
          label: 'key',
          example: '[3000, 3200]',
          optionsByPrevArgument: {
            pl: { optionsSelector: profitAndLossArgumentOptionSelector },
            bs: { optionsSelector: balanceSheetArgumentOptionSelector },
            account: {
              optionsSelector: companyAccountsArgumentOptionsSelector
            }
          }
        }
      ]
    },
    if: {
      name: 'if',
      arguments: [
        { label: 'logical expression', example: '1 = 1' },
        { label: 'expression if true', example: '1' },
        { label: 'expression if false', example: '0' }
      ]
    },
    max: {
      name: 'max',
      arguments: [
        { label: 'expression', example: '1' },
        { label: 'expression', example: '2' },
        { label: '...', example: '3' }
      ]
    },
    min: {
      name: 'min',
      arguments: [
        { label: 'expression', example: '1' },
        { label: 'expression', example: '2' },
        { label: '...', example: '3' }
      ]
    },
    receipts: {
      name: 'receipts',
      arguments: [
        {
          label: 'source',
          example: 'restolution',
          optionsConfig: {
            options: receiptsSourceOptions
          }
        },
        {
          label: 'measurement',
          example: 'quantity',
          optionsConfig: {
            options: receiptsMeasureOptions
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: {
            options: periodOptions
          }
        }
      ]
    },
    sales: {
      name: 'sales',
      arguments: [
        {
          label: 'source',
          example: 'kotipizza',
          optionsConfig: {
            options: salesSourceOptions
          }
        },
        {
          label: 'measurement',
          example: 'amount',
          optionsConfig: {
            options: salesItemMeasureOptions
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: {
            options: periodOptions
          }
        }
      ]
    },
    product: {
      name: 'product',
      arguments: [
        {
          label: 'source',
          example: 'accounting',
          optionsConfig: { options: salesItemSourceOptions }
        },
        {
          label: 'key',
          example: `['p-1', 'p-2']`,
          optionsByPrevArgument: {
            accounting: {
              request: {
                url: [`/reporting/products/accounting`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            futursoft: {
              request: {
                url: [`/reporting/products/futursoft`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            kotipizza: {
              request: {
                url: [`/reporting/products/kotipizza`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            restolution: {
              request: {
                url: [`/reporting/products/restolution`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            tulopos: {
              request: {
                url: [`/reporting/products/tulopos`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            }
          }
        },
        {
          label: 'measurement',
          example: 'amount',
          optionsConfig: { options: salesItemMeasureOptions }
        },
        {
          label: 'calculate_accruals',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    product_group: {
      name: 'product_group',
      arguments: [
        {
          label: 'source',
          example: 'accounting',
          optionsConfig: { options: salesItemSourceOptions }
        },
        {
          label: 'key',
          example: `'g-1'`,
          optionsByPrevArgument: {
            accounting: {
              request: {
                url: [`/reporting/products/groups/accounting`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            futursoft: {
              request: {
                url: [`/reporting/products/groups/futursoft`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            kotipizza: {
              request: {
                url: [`/reporting/products/groups/kotipizza`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            restolution: {
              request: {
                url: [`/reporting/products/groups/restolution`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            tulopos: {
              request: {
                url: [`/reporting/products/groups/tulopos`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            }
          }
        },
        {
          label: 'measurement',
          example: 'amount',
          optionsConfig: { options: salesItemMeasureOptions }
        },
        {
          label: 'calculate_accruals',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    product_family: {
      name: 'product_family',
      arguments: [
        {
          label: 'source',
          example: 'kotipizza',
          optionsConfig: {
            options: [
              {
                name: 'futursoft',
                value: 'futursoft'
              },
              {
                name: 'kotipizza',
                value: 'kotipizza',
                authority: {
                  franchise: ContractFranchise.KOTIPIZZA
                }
              },
              {
                name: 'restolution',
                value: 'restolution'
              },
              {
                name: 'tulopos',
                value: 'tulopos'
              }
            ]
          }
        },
        {
          label: 'key',
          example: `'f-1'`,
          optionsByPrevArgument: {
            futursoft: {
              request: {
                url: [`/reporting/products/families/futursoft`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            kotipizza: {
              request: {
                url: [`/reporting/products/families/kotipizza`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            restolution: {
              request: {
                url: [`/reporting/products/families/restolution`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            },
            tulopos: {
              request: {
                url: [`/reporting/products/families/tulopos`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (product: FormulaProduct) => ({
                  name: `${product.id} - ${product.name}`,
                  value: `'${product.id}'`
                })
              }
            }
          }
        },
        {
          label: 'measurement',
          example: 'amount',
          optionsConfig: { options: salesItemMeasureOptions }
        },
        {
          label: 'calculate_accruals',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    customer: {
      name: 'customer',
      authority: {
        accountingSoftware: [TaskApplication.NETVISOR, TaskApplication.PROCOUNTOR, TaskApplication.FENNOA]
      },
      arguments: [
        {
          label: 'source',
          example: 'accounting',
          optionsConfig: {
            options: [
              {
                name: 'accounting',
                value: 'accounting'
              }
            ]
          }
        },
        {
          label: 'key',
          example: `['p-1', 'p-2']`,
          optionsByPrevArgument: {
            accounting: {
              request: {
                url: [`/reporting/customers/accounting`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (customer: CustomerGroup) => ({
                  name: `${customer.id} - ${customer.name}`,
                  value: `'${customer.id}'`
                })
              }
            }
          }
        },
        {
          label: 'calculate_accruals',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    customer_group: {
      name: 'customer_group',
      authority: {
        accountingSoftware: [TaskApplication.NETVISOR, TaskApplication.PROCOUNTOR]
      },
      arguments: [
        {
          label: 'source',
          example: 'accounting',
          optionsConfig: {
            options: [
              {
                name: 'accounting',
                value: 'accounting'
              }
            ]
          }
        },
        {
          label: 'key',
          example: `['p-1', 'p-2']`,
          optionsByPrevArgument: {
            accounting: {
              request: {
                url: [`/reporting/customers/groups/accounting`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (customer: CustomerGroup) => ({
                  name: `${customer.id} - ${customer.name}`,
                  value: `'${customer.id}'`
                })
              }
            }
          }
        },
        {
          label: 'calculate_accruals',
          example: 'false',
          optionsConfig: { options: booleanOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    route: {
      name: 'route',
      arguments: [
        {
          label: 'source',
          example: 'mapon',
          optionsConfig: { options: routeSourceOptions }
        },
        {
          label: 'measurement',
          example: 'distance',
          optionsByPrevArgument: {
            ac_panther: {
              options: [
                { name: 'consumption', value: 'consumption' },
                { name: 'distance', value: 'distance' },
                { name: 'duration', value: 'duration' },
                { name: 'idle', value: 'idle' },
                { name: 'park', value: 'park' }
              ]
            },
            kiho: {
              options: [
                { name: 'distance', value: 'distance' },
                { name: 'duration', value: 'duration' }
              ]
            },
            mapon: {
              options: [
                { name: 'consumption', value: 'consumption' },
                { name: 'distance', value: 'distance' },
                { name: 'duration', value: 'duration' }
              ]
            },
            logisystems: {
              options: [
                { name: 'distance', value: 'distance' },
                { name: 'duration', value: 'duration' },
                { name: 'income', value: 'income' }
              ]
            }
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    workhour: {
      name: 'workhour',
      arguments: [
        {
          label: 'source',
          example: 'maraplan',
          optionsConfig: { options: workhourSourceOptions }
        },
        {
          label: 'type',
          example: 'work',
          optionsByPrevArgument: {
            maraplan: {
              request: {
                url: [`/reporting/workhours/types/maraplan`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (type: WorkType) => ({
                  name: `${type.name}`,
                  value: `'${type.id}'`
                })
              }
            },
            hrsuunti: {
              request: {
                url: [`/reporting/workhours/types/hrsuunti`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (type: WorkType) => ({
                  name: `${type.name}`,
                  value: `'${type.id}'`
                })
              }
            },
            työvuorovelho: {
              /** Tarkoituksella maraplan, koska se on sama järjestelmä */
              request: {
                url: [`/reporting/workhours/types/maraplan`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (type: WorkType) => ({
                  name: `${type.name}`,
                  value: `'${type.id}'`
                })
              }
            },
            severa: {
              request: {
                url: [`/reporting/workhours/types/severa`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (type: WorkType) => ({
                  name: `${type.name}`,
                  value: `'${type.id}'`
                })
              }
            },
            tamigo: {
              request: {
                url: [`/reporting/workhours/types/tamigo`, process.env.REACT_APP_INTEGRATION_URL],
                dataTransform: (type: WorkType) => ({
                  name: `${type.name}`,
                  value: `'${type.id}'`
                })
              }
            }
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    project: {
      name: 'project',
      arguments: [
        {
          label: 'source',
          example: 'severa',
          optionsConfig: { options: projectSourceOptions }
        },
        {
          label: 'property',
          example: 'expected_value',
          optionsByPrevArgument: {
            severa: {
              options: [
                { name: 'estimate', value: 'estimate' },
                { name: 'expected_value', value: 'expected_value' }
              ]
            }
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    variable: {
      name: 'variable',
      arguments: [
        {
          label: 'code',
          example: "['code1', 'code2']",
          optionsConfig: {
            optionsSelector: varArgumentOptionsSelector
          }
        },
        {
          label: 'aggregation function',
          example: 'lastValue',
          optionsConfig: { options: aggregationOptions }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    accumulate: {
      name: 'accumulate',
      arguments: [{ label: 'expression', example: 'account(3000, current)' }]
    },
    account_entries: {
      name: 'account_entries',
      arguments: [
        {
          label: 'account',
          example: '[3000, 3001, 3002]',
          optionsConfig: {
            optionsSelector: companyAccountsArgumentOptionsSelector
          }
        },
        {
          label: 'voucher_type',
          example: 'all',
          optionsConfig: {
            options: [
              {
                name: 'all',
                value: 'all'
              }
            ],
            request: {
              url: [`/reporting/vouchers/types`, process.env.REACT_APP_INTEGRATION_URL],
              dataTransform: (type: string) => ({
                name: `${type}`,
                value: `'${type}'`
              })
            }
          }
        },
        {
          label: 'description',
          example: 'description',
          optionsConfig: {
            options: [
              {
                name: 'all',
                value: 'all'
              }
            ]
          }
        },
        {
          label: 'type',
          example: 'all',
          optionsConfig: {
            options: [
              {
                name: 'debit',
                value: 'debit'
              },
              {
                name: 'credit',
                value: 'credit'
              },
              {
                name: 'all',
                value: 'all'
              }
            ]
          }
        },
        {
          label: 'period',
          example: 'current',
          optionsConfig: { options: periodOptions }
        }
      ]
    },
    use_dimension: {
      name: 'use_dimension',
      arguments: [
        {
          label: 'dimension_id',
          example: '32487234',
          optionsConfig: {
            optionsSelector: dimensionsArgumentOptionsSelector
          }
        },
        { label: 'formula', example: '0' }
      ]
    }
  }
  return functions
}
