import { Input, Form } from 'antd'
import Button from 'antd/es/button/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Text from 'antd/lib/typography/Text'
import { Link, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { forgotPasswordRequest } from '../../../redux/session/currentUser/actions'
import { notificationAction } from '../../../redux/middleware/actions'
import { primaryColor } from '../utils'
import { AppDispatch } from '../../../redux/store'

const StyledForgot = styled.div`
  max-width: 400px;
  padding: 0 16px;
  margin: 0 auto;
`

function ForgotPassword() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const handleOk = () => {
    if (form !== null) {
      form.validateFields().then((values: any) => {
        dispatch(
          notificationAction({
            type: 'info',
            message: 'EMAIL_SENT',
            description: 'FORGOT_PASSWORD_EMAIL_DESCRIPTION',
            duration: 10
          })
        )
        form.resetFields()
        dispatch(forgotPasswordRequest(values))
        navigate('/user/login')
      })
    }
    return null
  }

  return (
    <StyledForgot>
      <Form onFinish={handleOk} className="login-form" form={form}>
        <Text>{t('usersPage:forgot-password-info-text')}</Text>
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('email:email')}
        >
          <Input placeholder={t('email:email')} />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: '100%', backgroundColor: primaryColor(window.location.hostname) }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t('global:confirm')}
          </Button>
        </Form.Item>
        <Link to="/user/login">{t('usersPage:back-to-login')}</Link>
      </Form>
    </StyledForgot>
  )
}

export default ForgotPassword
