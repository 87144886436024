import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { Investment } from '../../../types/investment/Investment'
import { createInvestment } from '../service/investmentService'
import { useInvestmentOptions } from './useInvestment'
import loanQueryKeys from '../../loan/queries/loanQueryKeys'
import { filtersSelector } from '../../../redux/context/filters/selectors'

export const useCreateInvestment = (isExisting: boolean) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useInvestmentOptions(isExisting)
  const { t } = useTranslation()
  const { message } = App.useApp()
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: (investment: Investment) => createInvestment(companyId, investment, budgetingScenario?.id),
    mutationKey: ['investment'],
    onSuccess: () => {
      message.success(t('messages:createSuccess', 'Success'))
      queryClient.invalidateQueries({
        queryKey: loanQueryKeys.all
      })
      return queryClient.invalidateQueries({ queryKey: options.queryKey })
    }
  })
}
