import dayjs from 'dayjs'
import { DeprecationPlanFormData } from './types'
import { Investment } from '../../../../../types/investment/Investment'
import { InvestmentFunding } from '../../../../../types/investment/InvestmentFunding'

export const parseInvestment = (investment: Investment) => {
  return {
    ...investment,
    date: dayjs(investment.date).format('YYYY-MM-DD'),
    vat: investment.vat / 100
  }
}

export const parseInvestmentFunding = (investmentFunding: InvestmentFunding) => {
  if (investmentFunding.loan) {
    return {
      ...investmentFunding,
      loan: {
        ...investmentFunding.loan,
        interestRate: investmentFunding.loan.interestRate ? investmentFunding.loan.interestRate : undefined
      }
    }
  }
  return {
    ...investmentFunding
  }
}

export const parseInvestmentGraph = (investmentGraph: Investment) => {
  return {
    ...parseInvestment(investmentGraph),
    fundings:
      investmentGraph.fundings &&
      investmentGraph.fundings
        .filter(investmentFunding => !!investmentFunding)
        .map((investmentFunding: InvestmentFunding) => parseInvestmentFunding(investmentFunding))
  }
}

export const parseDeprecationPlan = (deprecationPlan: DeprecationPlanFormData) => {
  return {
    ...deprecationPlan,
    deprecationMethodId: deprecationPlan.deprecationMethod.id
  }
}
