import { Form, FormItemProps } from 'antd'
import React from 'react'

export const MyFormItemContext = React.createContext<(string | number)[]>([])

interface MyFormItemGroupProps {
  prefix: string | number | (string | number)[]
  children: React.ReactNode
}

interface MyFormItemProps extends FormItemProps {
  name: string | string[]
}

export function toArr(str: string | number | (string | number)[]): (string | number)[] {
  return Array.isArray(str) ? str : [str]
}

export const FormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
  const prefixPath = React.useContext(MyFormItemContext)
  const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix])
  return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>
}

export const FormItem = ({ name, ...props }: MyFormItemProps) => {
  const prefixPath = React.useContext(MyFormItemContext || React.createContext<(string | number)[]>([]))
  const concatName = name !== undefined ? [...(prefixPath as any), ...toArr(name)] : undefined
  return <Form.Item name={concatName} {...props} />
}
