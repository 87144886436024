import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { GroupSettingData } from '../../../pages/settings/company/group/types'
import { Company } from '../../../types/company/Company'
import { GET, GetRequestAction, PUT, PutRequestAction, REQUEST } from '../../middleware/types'
import { Store } from '../../types'
import { contextCompanyIdSelector } from '../company/selectors'
import {
  EmptyGroupSettingsAction,
  EMPTY_GROUP_SETTINGS,
  SetGroupSettingsAction,
  SET_GROUP_SETTINGS,
  UpdateGroupSettingsAction,
  UPDATE_GROUP_SETTINGS
} from './types'

export const emptyGroupSettings = (): EmptyGroupSettingsAction => ({
  type: EMPTY_GROUP_SETTINGS
})

export const setGroupSettings = (groupSettings: GroupSettingData): SetGroupSettingsAction => ({
  type: SET_GROUP_SETTINGS,
  payload: groupSettings
})

export const updateGroupSettings = (groupSettings: GroupSettingData): UpdateGroupSettingsAction => ({
  type: UPDATE_GROUP_SETTINGS,
  payload: groupSettings
})

export const onGroupSettingsUpdated = (data?: GroupSettingData): ThunkAction<void, Store, null, Action<string>> => {
  return (dispatch, getState) => {
    const store: Store = getState()
    const companyId = contextCompanyIdSelector(store)

    if (!companyId) return
    data && dispatch(updateGroupSettings(data))
  }
}

export const getGroupSettingsRequest = (companyId: Company['id']): GetRequestAction => ({
  type: REQUEST,
  payload: {
    method: GET,
    url: `/api/companies/${companyId}/group/settings`,
    success: setGroupSettings
  },
  meta: {
    type: 'GROUP_SETTINGS'
  }
})

export const updateGroupSettingsRequest = (companyId: Company['id'], data: GroupSettingData): PutRequestAction => ({
  type: REQUEST,
  payload: {
    method: PUT,
    url: `/api/companies/${companyId}/group/settings`,
    data,
    success: onGroupSettingsUpdated
  },
  meta: {
    type: 'UPDATE_GROUP_SETTINGS',
    notification: true
  }
})
