import React, { ReactNode } from 'react'
import { ThemeConfig } from 'antd/es/config-provider'
// import AccountorLogo from '../assets/accountor/accountor_a+_black.png'
import AccountorWordmark from '../assets/accountor/accountor_logo.svg'
import ColleqiumWordmark from '../assets/colleqium/colleqium-wordmark.png'
// import EconiaLogo from '../assets/econia/econia-logo.png'
import EconiaWordmark from '../assets/econia/econia-wordmark.png'
import FinadeckLogo from '../assets/finadeck-logo-web.svg'
// import FiscalesLogo from '../assets/fiscales/fiscales-logo.png'
import FiscalesWordmark from '../assets/fiscales/fiscales-wordmark.png'
// import LikvidiLogo from '../assets/likvidi/likvidi-logo.png'
import LikvidiWordmark from '../assets/likvidi/likvidi-wordmark.png'
import PremiumAccountingLogo from '../assets/premium-accounting/premium-accounting-logo.png'
import PremiumAccountingWordmark from '../assets/premium-accounting/premium-accounting-wordmark.png'
import ProfimaWordmark from '../assets/profima/profima-wordmark.png'
import RantalainenLogo from '../assets/rantalainen/rantalainen-logo.png'
import RantalainenWordmark from '../assets/rantalainen/rantalainen-wordmark.png'
import SelectFinancialServicesWordmark from '../assets/select/select-financial-services-wordmark.png'
import StariaWordmark from '../assets/staria/staria-wordmark.png'
// import StariaLogo from '../assets/staria/staria-logo.png'
import TaloustulkkiWordmark from '../assets/taloustulkki/taloustulkki-wordmark.png'
import TalousverkkoLogo from '../assets/talousverkko/talousverkko-logo.png'
import TalousverkkoWordmark from '../assets/talousverkko/talousverkko-wordmark.png'
import TilifixLogo from '../assets/tilifix/tilifix-logo.png'
import TilifixWordmark from '../assets/tilifix/tilifix-wordmark.png'
import VendeaLogo from '../assets/vendea/vendea_logo.png'
import VendeaWordmark from '../assets/vendea/vendea_wordmark.png'
import TililehtinenLogo from '../assets/tililehtinen/tililehtinen-logo.png'
import TililehtinenWordmark from '../assets/tililehtinen/tililehtinen-wordmark.png'
import TilinelosetLogo from '../assets/tilineloset/tilineloset-logo.png'
import TilinelosetWordmark from '../assets/tilineloset/tilineloset-wordmark.png'
import TilitaloWordmark from '../assets/tilitalo/tilitalo-wordmark.png'
// import TilitoimistoJukkaTaskinenLogo from '../assets/tilitoimisto-jukka-taskinen/tilitoimisto-jukka-taskinen-logo.png'
import TilitoimistoJukkaTaskinenWordmark from '../assets/tilitoimisto-jukka-taskinen/tilitoimisto-jukka-taskinen-wordmark.png'
import UniikkiWordmark from '../assets/uniikki/uniikki-wordmark.svg'
import KotipizzaWordmark from '../assets/kotipizza/kotipizza-logo.svg'

export type WhiteLabel =
  | 'app'
  | 'accountor'
  | 'colleqium'
  | 'econia'
  | 'fiscales'
  | 'likvidi'
  | 'premium-accounting'
  | 'profima'
  | 'rantalainen'
  | 'select-financial-services'
  | 'staria'
  | 'taloustulkki'
  | 'talousverkko'
  | 'tilifix'
  | 'tililehtinen'
  | 'tilineloset'
  | 'tilitalo'
  | 'tilitoimisto-jukka-taskinen'
  | 'uniikki'
  | 'kotipizza'
  | 'vendea'

interface CustomerTheme {
  logo: ReactNode | null
  wordmark: ReactNode | any
  token?: ThemeConfig['token']
}

export type CustomerThemeConfig = {
  [key in WhiteLabel]: CustomerTheme
}

export const customerThemes: CustomerThemeConfig = {
  app: {
    logo: FinadeckLogo,
    wordmark: () => (
      <span className="app wordmark" style={{ color: '#0225a1' }}>
        finadeck
      </span>
    ),
    token: {
      colorPrimary: '#0225a1'
    }
  },
  accountor: {
    logo: null,
    // logo: AccountorLogo,
    wordmark: AccountorWordmark,
    token: {
      colorPrimary: '#004650'
    }
  },
  colleqium: {
    logo: null,
    wordmark: ColleqiumWordmark
  },
  econia: {
    // logo: EconiaLogo,
    logo: null,
    wordmark: EconiaWordmark
  },
  fiscales: {
    logo: null,
    // logo: FiscalesLogo,
    wordmark: FiscalesWordmark,
    token: {
      colorPrimary: '#006068'
    }
  },
  likvidi: {
    // logo: LikvidiLogo,
    logo: null,
    wordmark: LikvidiWordmark
  },
  'premium-accounting': {
    logo: PremiumAccountingLogo,
    wordmark: PremiumAccountingWordmark,
    token: {
      colorPrimary: '#005760'
    }
  },
  profima: {
    logo: null,
    wordmark: ProfimaWordmark,
    token: {
      colorPrimary: '#0262CC'
    }
  },
  rantalainen: {
    logo: RantalainenLogo,
    wordmark: RantalainenWordmark
  },
  'select-financial-services': {
    logo: null,
    wordmark: SelectFinancialServicesWordmark,
    token: {
      colorPrimary: 'e6af2e'
    }
  },
  staria: {
    // logo: StariaLogo,
    logo: null,
    wordmark: StariaWordmark,
    token: {
      colorPrimary: '#69188e'
    }
  },
  taloustulkki: {
    logo: null,
    wordmark: TaloustulkkiWordmark
  },
  talousverkko: {
    logo: TalousverkkoLogo,
    wordmark: TalousverkkoWordmark,
    token: {
      colorPrimary: '#91b864'
    }
  },
  tilifix: {
    logo: TilifixLogo,
    wordmark: TilifixWordmark,
    token: {
      colorPrimary: '#e74011'
    }
  },
  tililehtinen: {
    logo: TililehtinenLogo,
    wordmark: TililehtinenWordmark,
    token: {
      colorPrimary: '#8abd24'
    }
  },
  tilineloset: {
    logo: TilinelosetLogo,
    wordmark: TilinelosetWordmark,
    token: {
      colorPrimary: '#006aaf'
    }
  },
  tilitalo: {
    logo: null,
    wordmark: TilitaloWordmark
  },
  'tilitoimisto-jukka-taskinen': {
    // logo: TilitoimistoJukkaTaskinenLogo,
    logo: null,
    wordmark: TilitoimistoJukkaTaskinenWordmark,
    token: {
      colorPrimary: '#33cccc'
    }
  },
  uniikki: {
    logo: null,
    wordmark: UniikkiWordmark,
    token: {
      colorPrimary: '#fcb900'
    }
  },
  kotipizza: {
    logo: null,
    wordmark: KotipizzaWordmark,
    token: {
      colorPrimary: '#009541'
    }
  },
  vendea: {
    logo: VendeaLogo,
    wordmark: VendeaWordmark,
    token: {
      colorPrimary: '#04538a'
    }
  }
}
