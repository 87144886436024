/* eslint-disable no-nested-ternary */
import React from 'react'
import { Badge } from 'antd'
import { ReportTableRow, VariableType } from '../../../../../components/Table/types'
import { formatValueByType } from '../../../../../utils/helpers'
import { format, getBadgeColor } from '../../../../reporting/keyFigures/utils'

export const renderCell = (text: any, reportTableRow: ReportTableRow) => {
  if (!text) return undefined
  const value = formatValueByType(
    text,
    reportTableRow.variableType,
    reportTableRow.style?.decimals
      ? {
          maximumFractionDigits: reportTableRow.style?.decimals,
          minimumFractionDigits: reportTableRow.style?.decimals
        }
      : reportTableRow.variableType === VariableType.percentage
      ? { maximumFractionDigits: 2 }
      : undefined
  )

  const badgeColor = getBadgeColor(reportTableRow, format(reportTableRow, text))

  if (badgeColor) {
    return <Badge color={badgeColor} text={value} />
  }
  return value
}
