import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'
import { ContractProduct } from '../../../types/contract/Contract'
import { DateRangeValue } from '../../../types/date/types'
import { FiscalYear } from '../../../types/fiscalYear/FiscalYear'
import { contextContractSelector } from '../contract/selectors'
import { contextSelector } from '../selectors'

export const contextFiscalYearSelector = createSelector(contextSelector, context => context.fiscalYears)

export const fiscalYearsSelector = createSelector(contextFiscalYearSelector, fiscalYears => {
  const sortedFiscalYearArray: FiscalYear[] = fiscalYears
    ?.slice()
    .sort((n1: FiscalYear, n2: FiscalYear) => +new Date(n1.endDate) - +new Date(n2.endDate))
  return sortedFiscalYearArray
})

export const reversedFiscalYearsSelector = createSelector(fiscalYearsSelector, fiscalYears => {
  return _.clone(fiscalYears).reverse()
})

export const lastClosedFiscalYearsSelector = createSelector(reversedFiscalYearsSelector, fiscalYears => {
  const value = fiscalYears?.filter(f => !f.isOpen)[0] || null
  return value as FiscalYear | null
})

export const firstOpenFiscalYearSelector = createSelector(fiscalYearsSelector, fiscalYears => {
  const value = fiscalYears.filter(f => f.isOpen)[0] || null
  return value as FiscalYear | null
})

export const currentFiscalYearSelector = createSelector(fiscalYearsSelector, fiscalYears => {
  return fiscalYears.find(
    fiscalYear =>
      dayjs(dayjs(fiscalYear.startDate).format('YYYY-MM-DD')) <= dayjs(dayjs().format('YYYY-MM-DD')) &&
      dayjs(dayjs(fiscalYear.endDate).format('YYYY-MM-DD')) >= dayjs(dayjs().format('YYYY-MM-DD'))
  )
})

export const currentAndFutureFiscalYearSelector = createSelector(fiscalYearsSelector, fiscalYears => {
  return fiscalYears.filter(fiscalYear => dayjs(fiscalYear.endDate) >= dayjs())
})

export const currentAndFutureFiscalYearDateRangeSelector = createSelector(
  currentAndFutureFiscalYearSelector,
  fiscalYears => {
    return [
      fiscalYears?.[0]?.startDate ? dayjs(fiscalYears[0].startDate) : null,
      fiscalYears?.[fiscalYears.length - 1]?.endDate ? dayjs(fiscalYears[fiscalYears.length - 1].endDate) : null
    ] as DateRangeValue<Dayjs>
  }
)

export const reportableFiscalYearsSelector = createSelector(
  fiscalYearsSelector,
  currentFiscalYearSelector,
  contextContractSelector,
  (fiscalYears, currentFiscalYear, contract) => {
    if (!contract) return []
    const current = currentFiscalYear || fiscalYears[fiscalYears.length - 1]

    let history
    let future

    switch (contract.product) {
      case ContractProduct.LIGHT:
        history = 4
        future = 1
        break
      case ContractProduct.PRO:
      case ContractProduct.BASIC:
        history = 3
        future = undefined
        break
      default:
        history = 4
        future = 1
        break
    }

    const currentIndex = fiscalYears.indexOf(current)
    const startIndex = currentIndex - history >= 0 ? currentIndex - history : 0
    const endIndex = future && currentIndex + future
    return fiscalYears.slice(startIndex, endIndex)
  }
)
