import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Table, Typography } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import {
  deleteBudgetDriverRequest,
  getBudgetDriversRequest,
  updateBudgetDriverOrdersRequest
} from '../../../../../../redux/context/budgetDrivers/actions'
import { budgetDriversSelector } from '../../../../../../redux/context/budgetDrivers/selectors'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { loadingSelector } from '../../../../../../redux/loading/selectors'
import { BudgetDriver } from '../../../../../../types/budgetDriver/BudgetDriver'
import { useTableProps } from '../../../../../../utils/hooks'
import BudgetDriverModal from './BudgetDriverModal'
import { AppDispatch } from '../../../../../../redux/store'
import DraggableTableRow from '../../../../../../components/Table/DraggableTableRow'

const BudgetDriversTable: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const companyId = useSelector(contextCompanyIdSelector)
  const budgetDrivers = useSelector(budgetDriversSelector)
  const { budgetDrivers: budgetDriversLoading } = useSelector(loadingSelector)
  const contentEl = useRef<HTMLDivElement>(null)
  const [budgetDriversDataSource, setBudgetDriversDataSource] = useState(budgetDrivers)
  const [isEditing, setIsEditing] = useState<BudgetDriver>()
  const [visible, setVisible] = useState(false)

  const tableProps = useTableProps(contentEl, 600)

  const handleEdit = (editBudgetDriver: any) => {
    setIsEditing({ ...editBudgetDriver, code: editBudgetDriver.formula.code, source: editBudgetDriver.formula.source })
    setVisible(true)
  }

  const handleCancel = () => {
    setIsEditing(undefined)
    setVisible(false)
  }

  useEffect(() => {
    setBudgetDriversDataSource(budgetDrivers)
  }, [budgetDrivers])

  useEffect(() => {
    companyId && dispatch(getBudgetDriversRequest(companyId))
  }, [companyId])

  const columns: ColumnProps<BudgetDriver>[] = [
    {
      key: 'key'
    },
    {
      title: t('global:name'),
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: t('global:code'),
      dataIndex: ['formula', 'code'],
      ellipsis: true
    },
    {
      title: t('global:formula'),
      dataIndex: ['formula', 'source'],
      ellipsis: true,
      render: text => <Typography.Text code>{text}</Typography.Text>
    },
    {
      title: t('global:actions'),
      key: 'action',
      width: 100,
      align: 'right',
      render: (record: any) => (
        <span>
          <Button type="text" size="small" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Divider type="vertical" />
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => companyId && dispatch(deleteBudgetDriverRequest(companyId, record.id))}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button danger type="text" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      )
    }
  ]

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = budgetDriversDataSource.findIndex(i => i.id === active.id)
      const overIndex = budgetDriversDataSource.findIndex(i => i.id === over?.id)
      const updatedArray = arrayMove(budgetDriversDataSource, activeIndex, overIndex)
      const orderArray = updatedArray.map((row: any, index: any) => {
        return {
          id: row.id,
          order: index
        }
      })
      setBudgetDriversDataSource(updatedArray)
      companyId && dispatch(updateBudgetDriverOrdersRequest(companyId, orderArray))
    }
  }

  return (
    <>
      <BudgetDriverModal budgetDriver={isEditing} visible={visible} handleCancel={handleCancel} />
      <div ref={contentEl} style={{ margin: '32px 0' }}>
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={budgetDriversDataSource.map(i => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="id"
              loading={budgetDriversLoading}
              {...tableProps}
              columns={columns}
              dataSource={budgetDriversDataSource}
              pagination={false}
              components={{
                body: {
                  row: DraggableTableRow
                }
              }}
            />
          </SortableContext>
        </DndContext>
      </div>
    </>
  )
}

export default BudgetDriversTable
