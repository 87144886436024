/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { Button, Typography, Tabs, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useForm } from 'antd/lib/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import {
  BudgetingLevel,
  CompanySettings,
  CompanySettingsForm
} from '../../../../../../types/companySettings/CompanySettings'
import BudgetingInputFields from './BudgetingInputFields'
import SocialSecurityFields from './SocialSecurityFields'
import { transformFieldData } from '../../../../../../utils/form'
import { Account } from '../../../../../../features/account/types/Account'
import VatInputFields from './VatInputFields'
import { companySettingsSelector } from '../../../../../../redux/context/companySettings/selectors'
import { contextRequest } from '../../../../../../redux/context/actions'
import { updateCompanySettingsRequest } from '../../../../../../redux/context/companySettings/actions'
import { notificationAction } from '../../../../../../redux/middleware/actions'
import { notLocked } from '../../budgetingScenarios/utils'
import { filtersSelector } from '../../../../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../../../../redux/store'
import BudgetSimulationsPage from './BudgetSimulations'
import { parseArrayFromString } from '../../../../../budgeting/financialStatements/components/budget/utils'
import { BudgetingMethod } from '../../../../../budgeting/financialStatements/components/budget/types'
import { useFetchAccounts } from '../../../../../../features/account/queries/useFetchAccounts'
import { companyAccountsByCodeSelector } from '../../../../../../features/account/selectors/selectors'

const { Title } = Typography

const BudgetingInputForm = () => {
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const [form] = useForm<CompanySettingsForm>()
  const { budgetingScenario } = useSelector(filtersSelector)
  const { data: accountsByCode } = useFetchAccounts(companyAccountsByCodeSelector)
  const companySettings = useSelector(companySettingsSelector)

  const reverseConfig: { [key: string]: Function } = {
    budgeting: (value: CompanySettingsForm['budgeting']) => {
      if (value.method.method === BudgetingMethod.reference) {
        return {
          ...value,
          method: {
            ...value.method,
            source:
              value.level === BudgetingLevel.account
                ? `account([${value.method.source.join()}], current)`
                : `pl([${value.method.source.join()}], current)`
          }
        } satisfies CompanySettings['budgeting']
      }
      return value
    },
    sourceAccounts: (value: string[]) =>
      value
        ?.filter(val => val && accountsByCode?.[val])
        .map(v => {
          const { name, code } = accountsByCode?.[v] || {}
          if (!name && !code) return undefined
          return { code, name }
        }),
    account: (value: string) => {
      const { name, code } = accountsByCode?.[value] || {}
      if (!name && !code) return undefined
      return { code, name }
    },
    sales: (arr: { vat: string; value: number }[]) => {
      return arr.reduce((acc, { vat, value }) => ({ ...acc, [`_${vat}`]: value }), {})
    },
    purchases: (arr: { vat: string; value: number }[]) => {
      return arr.reduce((acc, { vat, value }) => ({ ...acc, [`_${vat}`]: value }), {})
    }
  }

  const config: { [key: string]: Function } = {
    source: (val: string) => {
      return parseArrayFromString(val) || []
    },
    sourceAccounts: (value: (Account | null)[]) => value && value.filter(val => val).map(account => account?.code),
    account: (value: Account) => value && value.code,
    sales: (obj: { [key: string]: number }) => {
      return Object.entries(obj).map(([key, value]) => ({ vat: parseFloat(key.replace('_', '')), value }))
    },
    purchases: (obj: { [key: string]: number }) => {
      return Object.entries(obj).map(([key, value]) => ({ vat: parseFloat(key.replace('_', '')), value }))
    }
  }

  const handleSubmit = async () => {
    try {
      const data = await form.validateFields()
      const transformed = transformFieldData(data, reverseConfig)
      dispatch(contextRequest(updateCompanySettingsRequest, transformed))
    } catch (e) {
      console.log('error', e)
      dispatch(
        notificationAction({
          type: 'error',
          message: 'ERROR',
          description: (e as any).errorFields[0].errors[0]
        })
      )
    }
  }

  const handleValuesChange = (val: Partial<CompanySettingsForm>) => {
    const { budgeting: { level } = {} } = val
    if (level) {
      // Jos leveli vaihtuu tyhjätään source, koska statement row id:t ja account id:t ei yhteensopivia
      form.setFieldValue(['budgeting', 'method', 'source'], [])
    }
  }

  useEffect(() => {
    form.resetFields()
  }, [companySettings])

  const items = [
    {
      label: t('menu:/settings/company/budgeting/input'),
      key: 'budjetin-syotto',
      forceRender: true,
      children: <BudgetingInputFields />
    },
    {
      label: t('budgetingInputPage:VAT-distribution'),
      key: 'myynnin-ja-ostojen-arvonlisäverokantojen-jakaumat',
      forceRender: true,
      children: <VatInputFields />
    },
    {
      label: t('budgetingInputPage:social-security-expenses'),
      key: 'security-expenses',
      forceRender: true,
      children: (
        <>
          <Title level={4}>{t('budgetingInputPage:social-security-expenses')}</Title>
          <SocialSecurityFields />
        </>
      )
    },
    {
      label: t('budgetingInputPage:automatic-balance-sheet-simulations', 'Tase-erien automaattilaskenta'),
      key: 'budget-simulations',
      forceRender: true,
      children: <BudgetSimulationsPage />
    }
  ]

  return (
    <div className="settings-form-container budget-input" style={{ marginBlock: 24 }}>
      <Form
        colon
        layout="vertical"
        form={form}
        initialValues={transformFieldData(companySettings, config)}
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
      >
        <Tabs items={items} />
        <div style={{ marginTop: 10 }}>
          <Button disabled={!notLocked(budgetingScenario)} type="primary" onClick={handleSubmit}>
            {t('global:save')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default BudgetingInputForm
