import React, { useContext } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import SelectionTableInput from '../SelectionTableInput'
import DashboardItemFields from '../DasboardItemFieds'
import { formulasSelector } from '../../../../../../../redux/context/formulas/selectors'
import { CompanyDashboardItemContext, CompanyDashboardContextType } from '../../DashboardItemProvider'
import { Formula } from '../../../../../../../types/formula/Formula'
import { VariableType } from '../../../../../../../components/Table/types'
import { useColumnSearchProps } from '../../../../../../../components/Table/useColumSearchProps'

const InternalKeyFigureTableFields = () => {
  const { t } = useTranslation()
  const formulas = useSelector(formulasSelector)
  const { companyDashboardItem } = useContext(CompanyDashboardItemContext) as CompanyDashboardContextType
  let selectableInternalKeyFigures = []
  if (companyDashboardItem?.params.representation.type === 'table') {
    selectableInternalKeyFigures = formulas
  } else {
    selectableInternalKeyFigures = formulas.filter(d => d.type === companyDashboardItem?.params.selectedType)
  }

  const columns: ColumnProps<Formula>[] = [
    {
      title: t(`global:name`),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...useColumnSearchProps('name')
    },
    {
      title: t(`global:type`),
      dataIndex: 'type',
      width: 150,
      render: type => t(`keyFigurePage:${type}`),
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: [...Object.values(VariableType).map(v => ({ text: t(`keyFigurePage:${v}`), value: v }))],
      onFilter: (value, record) => record.type === value
    }
  ]

  return (
    <>
      <DashboardItemFields />
      <Form.Item label={t('global:keyFigure')} name={['params', 'keyFigureIds']}>
        <SelectionTableInput<Formula>
          dataSource={selectableInternalKeyFigures
            .filter(item => item.order !== null)
            .sort((a, b) => a.order - b.order)}
          columns={columns}
        />
      </Form.Item>
    </>
  )
}

export default InternalKeyFigureTableFields
