import React, { useEffect, useState } from 'react'
import { Card, Divider } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { FormulaFunction, Argument, ArgumentOption, ArgumentOptionConfig, ArgumentOptionConfigs } from '../types'
import { HTTPMethod } from '../../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import ArgumentOptionList from './ArgumentOptionList'
import { authTokenSelector } from '../../../../../../redux/session/authentication/selectors'
import LoadingWrapper from '../../../../../../components/Misc/LoadingWrapper'
import { store } from '../../../../../../redux/store'

interface Props {
  currentFunction: FormulaFunction
  currentArgument?: Argument
  prevArgumentValue?: string
  addArgument: (argument: string) => void
}

const FunctionInformation: React.FC<Props> = ({ currentFunction, currentArgument, prevArgumentValue, addArgument }) => {
  const { t } = useTranslation()
  const authToken = useSelector(authTokenSelector)
  const companyId = useSelector(contextCompanyIdSelector)!
  const [options, setOptions] = useState<any>([])
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false)

  const getSourceConfig = (cfg?: ArgumentOptionConfigs): ArgumentOptionConfig | null => {
    if (cfg?.default) return cfg?.default

    const { optionsByPrevArgument } = currentArgument || {}
    if (prevArgumentValue === undefined || optionsByPrevArgument === undefined) return null
    const source = optionsByPrevArgument[prevArgumentValue]
    return source
  }

  const getResource = async (cfg: ArgumentOptionConfig['request']): Promise<ArgumentOption[]> => {
    const [url, baseURL] = cfg?.url || []
    const { dataTransform, prevArgumentCompany } = cfg || {}
    const api = axios.create({
      baseURL: baseURL || process.env.REACT_APP_BACKEND_URL,
      headers: { 'X-Token': authToken ?? '' }
    })
    if (api && url) {
      setOptionsLoading(true)
      try {
        const theRes = await api({
          method: HTTPMethod.GET,
          url: url.replace(
            '{companyId}',
            prevArgumentCompany ? prevArgumentValue?.replaceAll("'", '') || companyId : companyId
          ),
          params: { companyId }
        })

        return dataTransform ? theRes.data.map(dataTransform) : theRes.data
      } catch (error) {
        console.log(error)
      } finally {
        setOptionsLoading(false)
      }
    }

    return []
  }

  const handleOptionConfig = async (cfg: ArgumentOptionConfig) => {
    if (!cfg) return

    const newOptions: ArgumentOptionConfig[] = []
    if (cfg?.options) {
      newOptions.push(...cfg.options)
    }
    if (cfg?.optionsSelector) {
      const state: any = store.getState()
      let selectorData = []
      // TODO: kaikki tarvittava ei vielä react queryssä niin menee kahella eri tavalla
      if (typeof cfg?.optionsSelector === 'function') {
        // redux store muoto
        selectorData = cfg?.optionsSelector(state)
      } else {
        // query muoto
        selectorData = cfg?.optionsSelector
      }
      newOptions.push(...selectorData)
    }
    if (cfg.request) {
      const data = await getResource(cfg.request)
      newOptions.push(...data)
    }

    setOptions(newOptions)
  }

  const getOptions = async () => {
    if (!currentArgument) return undefined

    if (currentArgument.optionsConfig) {
      handleOptionConfig(currentArgument.optionsConfig)
    }

    // Edellisen argumentin arvolla haettava config jolla haetaan optiot
    if (currentArgument.optionsByPrevArgument) {
      const cfg = getSourceConfig(currentArgument?.optionsByPrevArgument)
      cfg && handleOptionConfig(cfg)
    }

    return undefined
  }

  useEffect(() => {
    getOptions()
    return () => {
      setOptions([])
    }
  }, [currentFunction, currentArgument, prevArgumentValue])

  return (
    <>
      <Card size="small">
        <Card.Meta
          // eslint-disable-next-line prettier/prettier
          title={`${currentFunction.name}(${
            currentFunction.arguments ? currentFunction.arguments?.map(arg => `<${arg.label}>`).toString() : ''
          })`}
        />
        <Divider />
        <Card.Meta description={t(`formulaFunctionDescription:${currentFunction.name}`)} />
        {currentFunction.arguments && (
          <>
            <Divider />
            <Card.Meta
              title={t('global:example')}
              style={{ marginBottom: 8 }}
              description={`${currentFunction.name}(${currentFunction.arguments?.map(arg => arg.example).toString()})`}
            />
            {options.length > 0 && (
              <LoadingWrapper loading={optionsLoading}>
                <ArgumentOptionList options={options} height={400} addArgument={addArgument} />
              </LoadingWrapper>
            )}
          </>
        )}
      </Card>
    </>
  )
}

export default FunctionInformation
