import { QueryFunctionContext } from '@tanstack/react-query'
import { GET, PUT } from '../../../redux/middleware/types'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { personnelEndpoints } from '../api/personnelEndpoints'
import request from '../../../services/api/request'
import { personnelQueryKeys } from '..'

export const fetchPersonnel = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof personnelQueryKeys)['list']>>) => {
  return request<KeyFigureFact[]>({
    method: GET,
    url: personnelEndpoints.list(companyId),
    signal
  })
}

export const updatePersonnel = async (companyId: string, data: Partial<KeyFigureFact>) => {
  return request<KeyFigureFact>({
    method: PUT,
    url: personnelEndpoints.list(companyId),
    data
  })
}
