import React, { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Switch, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import {
  deleteFiscalYearRequest,
  getFiscalYearsRequest,
  updateFiscalYearRequest
} from '../../../../redux/context/fiscalYears/actions'
import { reversedFiscalYearsSelector } from '../../../../redux/context/fiscalYears/selectors'
import { FiscalYear } from '../../../../types/fiscalYear/FiscalYear'
import { useTableProps } from '../../../../utils/hooks'
import FiscalYearsForm from './components/FiscalYearsForm'
import { useAuthorizedData } from '../../../../utils/Authorizable/authorize'
import { ContractProduct } from '../../../../types/contract/Contract'
import { AppDispatch } from '../../../../redux/store'

export const budgetableSettingAuthority = { product: [ContractProduct.BASIC, ContractProduct.PRO] }

const FiscalYearsView: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const fiscalYears = useSelector(reversedFiscalYearsSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const [modalVisible, setModalVisibility] = useState(false)
  const [editFiscalYear, setEditFiscalYear] = useState<FiscalYear>()
  const contentEl = useRef(null)
  const tableProps = useTableProps(contentEl, 650)

  useEffect(() => {
    companyId && dispatch(getFiscalYearsRequest(companyId))
  }, [companyId])

  const handleModalOpen = () => {
    setModalVisibility(true)
  }

  const handleUpdate = (values: FiscalYear) => {
    companyId && dispatch(updateFiscalYearRequest(companyId, values, values.id))
  }

  const handleCancel = () => {
    setModalVisibility(false)
    setEditFiscalYear(undefined)
  }

  const handleEdit = (fiscalYear: FiscalYear) => {
    setEditFiscalYear(fiscalYear)
    handleModalOpen()
  }

  const columns = useMemo(
    () => [
      {
        title: t('fiscalYearsPage:year'),
        dataIndex: 'year',
        ellipsis: true
      },
      {
        title: t('fiscalYearsPage:start-date'),
        dataIndex: 'startDate',
        ellipsis: true,
        render: (record: any) => dayjs(record).format('DD.MM.YYYY')
      },
      {
        title: t('fiscalYearsPage:end-date'),
        dataIndex: 'endDate',
        ellipsis: true,
        render: (text: any) => dayjs(text).format('DD.MM.YYYY')
      },
      {
        title: t('fiscalYearsPage:length'),
        dataIndex: 'length',
        align: 'center' as const,
        ellipsis: true
      },
      {
        title: t('fiscalYearsPage:state'),
        dataIndex: 'isOpen',
        width: 150,
        render: (isOpen: boolean, record: FiscalYear) => (
          <>
            <Switch onChange={() => handleUpdate({ ...record, isOpen: !record.isOpen })} checked={isOpen} />
            <span style={{ marginLeft: 8 }}>
              {isOpen ? t('fiscalYearsPage:isopen') : t('fiscalYearsPage:isclosed')}
            </span>
          </>
        )
      },
      {
        title: t('global:budgetable'),
        dataIndex: 'budgetable',
        width: 150,
        render: (budgetable: boolean, record: FiscalYear) => (
          <Switch onChange={() => handleUpdate({ ...record, budgetable: !record.budgetable })} checked={budgetable} />
        ),
        authority: budgetableSettingAuthority
      },
      {
        title: t('global:actions'),
        key: 'action',
        width: 100,
        align: 'right' as const,
        render: (record: FiscalYear) => (
          <span>
            <EditOutlined onClick={() => handleEdit(record)} />
            <Divider type="vertical" />
            <Popconfirm
              placement="bottomRight"
              title={t('global:delete-confirm')}
              onConfirm={() => companyId && dispatch(deleteFiscalYearRequest(companyId, record.id))}
              okText={t('global:yes')}
              cancelText={t('global:no')}
            >
              <DeleteOutlined />
            </Popconfirm>
          </span>
        )
      }
    ],
    []
  )

  const authorizedColumns = useAuthorizedData(columns)

  return (
    <div ref={contentEl} style={{ margin: '32px 0' }}>
      <Button onClick={handleModalOpen} style={{ marginBottom: 8 }}>
        {`+ ${t('global:add-new')}`}
      </Button>
      {modalVisible && (
        <FiscalYearsForm
          handleCancel={handleCancel}
          handleUpdateData={handleUpdate}
          modalVisible={modalVisible}
          fiscalYear={editFiscalYear}
          latestFiscalYear={fiscalYears && fiscalYears.slice(0)[0]}
        />
      )}
      <Table
        {...tableProps}
        // loading={isLoading}
        columns={authorizedColumns}
        dataSource={fiscalYears}
        rowKey={record => record.id.toString()}
      />
    </div>
  )
}

export default FiscalYearsView
