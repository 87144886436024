import { Responsive, WidthProvider } from 'react-grid-layout'
import { styled } from 'styled-components'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const StyledResponsiveGridLayout = styled(ResponsiveGridLayout as any).attrs<{
  $backgroudColor?: string
}>(props => ({
  // or we can define dynamic ones
  $backgroudColor: props.$backgroudColor || 'BF4F74'
}))`
  position: relative;
  transition: height 200ms ease;
  .react-grid-item.react-grid-placeholder {
    background: ${props => props.$backgroudColor};
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`

export const StyledLayoutItem = styled.div`
  /* background-color: antiquewhite; */
  transition: all 200ms ease;
  transition-property: left, top, width, height;
  overflow: hidden;
  border-radius: 4px;

  img {
    pointer-events: none;
    user-select: none;
  }
  &.cssTransforms {
    transition-property: transform, width, height;
  }
  &.resizing {
    transition: none;
    z-index: 1;
    will-change: width, height;
  }

  &.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  &.dropping {
    visibility: hidden;
  }

  &.react-grid-placeholder.placeholder-resizing {
    transition: none;
  }

  .react-resizable-handle {
    font-size: 2rem;
    position: absolute;
    width: 20px;
    height: 20px;
  }

  .react-resizable-handle::after {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  &.react-resizable-hide .react-resizable-handle {
    display: none;
  }

  .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle.react-resizable-handle-se {
    color: red;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle.react-resizable-handle-w,
  .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle.react-resizable-handle-n,
  .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }
`
