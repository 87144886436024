import { Form, Input, Checkbox, Select, theme, InputNumber, Space, Typography, Divider, Cascader, Tooltip } from 'antd'
import { SelectValue } from 'antd/es/select'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReloadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import DimensionSelect from '../../../../../components/Dimension/DimensionSelect'
import { FormItemGroup } from '../../../../../components/Form/FormItemContext'
import FormulaInput from '../../../../../components/inputs/FormulaInput'
import KeyFigureInput from '../../../../../components/inputs/KeyFigureInput'
import { ContractProduct } from '../../../../../types/contract/Contract'
import { useAuthority } from '../../../../../utils/Authorizable/authorize'
import FormulaHelp from '../../../../settings/company/keyFigures/formulas/components/FormulaHelp'
import { internalKeyFigureTypes } from '../../../keyFigures/config'
import SeriesStyleFields from '../chart/SeriesStyleFields'
import { CategoryContext, CategoryContextType } from '../categorySelection/CategoryContext'
import { BudgetingScenario } from '../../../../../types/budgetingScenario/BudgetingScenario'
import { SectionType } from '../../../../../redux/context/customReports/typesSection'
import {
  CustomReportVariable,
  VariableFormValues,
  ThresholdValueSetting
} from '../../../../../redux/context/customReports/typesVariable'
import TranslateFields from '../../../../../components/Form/TranslateFields'
import { formulasSelector } from '../../../../../redux/context/formulas/selectors'
import ColorPicker from '../categorySelection/ColorPicker'
import { generateDataTypeCascaderOptions } from '../section/utils'

export enum VariableSourceType {
  source = 'source',
  formulaId = 'formulaId',
  keyFigureId = 'keyFigureId'
}

interface VariableFormProps {
  chartStylesEnable?: boolean
  sectionType: SectionType
  variable?: CustomReportVariable
}

const CustomReportVariableForm: React.FC<VariableFormProps> = ({ variable, sectionType, chartStylesEnable }) => {
  const form = Form.useFormInstance<VariableFormValues>()
  const variableTitle = Form.useWatch('name', form)
  const formulas = useSelector(formulasSelector)
  const { t } = useTranslation()
  const [showReloadTranslations, setShowReloadTranslations] = useState<boolean>(true)
  const isPro = useAuthority({ product: ContractProduct.PRO })
  const { budgetingScenarioMap, forecastMap, categories, categoryTree } = useContext(
    CategoryContext
  ) as CategoryContextType
  const [varibleSourceType, setVaribleSourceType] = useState<VariableSourceType>(VariableSourceType.keyFigureId)
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const autoFillTitle = (title?: string) => {
    if (!variableTitle) {
      form.setFieldsValue({
        name: title
      })
    }
  }

  const getVariableSourceType = (obj?: Partial<CustomReportVariable>) => {
    if (!obj) return VariableSourceType.keyFigureId
    return Object.values(VariableSourceType).reduce((sourceType, current) => (obj[current] ? current : sourceType))
  }

  useEffect(() => {
    const st = getVariableSourceType(variable)
    setVaribleSourceType(st)
  }, [variable])

  const updateTranslations = (type: 'keyFigure' | 'formula', id?: number) => {
    if (type === 'keyFigure') {
      form.setFieldsValue({
        nameFi: t(`keyFigureStatement:${id}`, {
          lng: 'fi'
        }),
        nameEn: t(`keyFigureStatement:${id}`, {
          lng: 'en'
        }),
        nameSv: t(`keyFigureStatement:${id}`, {
          lng: 'sv'
        })
      })
    } else {
      const formula = formulas.find(f => f.id === id)
      formula &&
        form.setFieldsValue({
          nameFi: formula.nameFi,
          nameEn: formula.nameEn,
          nameSv: formula.nameSv
        })
    }
  }

  const variableSourceInputMap = {
    [VariableSourceType.keyFigureId]: (
      <div key={VariableSourceType.keyFigureId}>
        <Form.Item
          key={VariableSourceType.keyFigureId}
          name="keyFigureId"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={
            <div>
              {t('global:keyFigure')}
              {variable && showReloadTranslations && (
                <Tooltip title={t('customReportPage:updateKeyfigureTranslations')}>
                  <ReloadOutlined
                    style={{ marginLeft: 5, color: '#0225a1', fontSize: 14 }}
                    onClick={() => {
                      updateTranslations('keyFigure', variable?.keyFigureId)
                    }}
                  />
                </Tooltip>
              )}
            </div>
          }
        >
          <KeyFigureInput
            key="keyfigureinput"
            onChange={(value: SelectValue, option: any) => {
              setShowReloadTranslations(false)
              updateTranslations('keyFigure', option?.value)
              autoFillTitle(option?.children)
            }}
          />
        </Form.Item>
        <TranslateFields />
      </div>
    ),
    [VariableSourceType.formulaId]: (
      <div key={VariableSourceType.formulaId}>
        <Form.Item
          key={VariableSourceType.formulaId}
          name="formulaId"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={
            <div>
              {t('global:formula')}
              {variable && showReloadTranslations && (
                <Tooltip title={t('customReportPage:updateFormulaTranslations')}>
                  <ReloadOutlined
                    style={{ marginLeft: 5, color: '#0225a1', fontSize: 14 }}
                    onClick={() => {
                      updateTranslations('formula', variable.formulaId)
                    }}
                  />
                </Tooltip>
              )}
            </div>
          }
        >
          <FormulaInput
            key="formulainput"
            onChange={(value: SelectValue, option: any) => {
              setShowReloadTranslations(false)
              form.setFieldsValue(option?.translations)
              autoFillTitle(option?.children)
            }}
          />
        </Form.Item>
        <TranslateFields />
      </div>
    ),
    [VariableSourceType.source]: (
      <Space.Compact block key={VariableSourceType.source}>
        <Form.Item
          style={{ width: '70%', margin: 0 }}
          name="source"
          label={t('global:formula')}
          key="formulaSource"
          tooltip={{ title: <FormulaHelp />, color: colorBgContainer }}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ width: '30%' }}
          name="type"
          key="type"
          label={t('global:type')}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <Select showSearch optionFilterProp="children">
            {internalKeyFigureTypes.map(keyFigureType => (
              <Select.Option key={keyFigureType} value={keyFigureType}>
                {t(`keyFigurePage:${keyFigureType}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space.Compact>
    )
  }

  const basicFields = [
    <Form.Item key="id" name="id" hidden>
      <Input />
    </Form.Item>,
    <Form.Item
      key="title"
      name="name"
      label={t('customReportPage:title')}
      rules={[{ required: true, message: t('global:required-field') }]}
    >
      <Input />
    </Form.Item>,
    <Form.Item key="type" label={t('global:type')} shouldUpdate>
      <Select
        onChange={e => {
          setShowReloadTranslations(false)
          form.setFieldsValue({
            nameFi: '',
            nameEn: '',
            nameSv: ''
          })
          return setVaribleSourceType(e)
        }}
        value={varibleSourceType}
        options={Object.values(VariableSourceType).map(srcType => ({
          label: t(`customReportPage:${srcType}`),
          value: srcType
        }))}
      />
    </Form.Item>,
    variableSourceInputMap[varibleSourceType]
  ]

  // input kentat paramsseille
  const paramsFields = useMemo(() => {
    const companyId = categoryTree.companies[0]
    let budgets: BudgetingScenario[] = []
    let forecasts: BudgetingScenario[] = []
    if (companyId) {
      budgets = budgetingScenarioMap[companyId]
      forecasts = forecastMap[companyId]
    }

    const fields = [
      <Form.Item hidden key="visible" name={['params', 'visible']}>
        <Input />
      </Form.Item>,
      <Form.Item key="month" name={['params', 'month']} label={t('global:month')}>
        <Select
          allowClear
          options={dayjs.months().map((month, index) => ({
            label: capitalize(month),
            value: index + 1
          }))}
        />
      </Form.Item>,
      <Form.Item key="dataType" name={['params', 'dataType']} label={t('global:dataType')}>
        <Cascader options={generateDataTypeCascaderOptions(budgets, forecasts)} allowClear style={{ width: 200 }} />
      </Form.Item>,
      <Form.Item key="offset" name={['params', 'offset']} label={t('global:monthOffset', '± kuukautta')}>
        <InputNumber />
      </Form.Item>
    ]

    if (isPro) {
      fields.unshift(
        <Form.Item key="dimension" name={['params', 'dimension', 'dimensionId']} label={t('global:dimension')}>
          <DimensionSelect companyId={categoryTree.companies[0]} />
        </Form.Item>
      )
    }

    return fields
  }, [isPro, categories, budgetingScenarioMap, forecastMap])

  const styleFields = useMemo(() => {
    const tableFields = [
      <Form.Item hidden key="fontStyle" name={['style', 'fontStyle']}>
        <Input />
      </Form.Item>,
      <Form.Item hidden key="indentLevel" name={['style', 'indentLevel']}>
        <Input />
      </Form.Item>,
      <Form.Item key="fontWeight" name={['style', 'fontWeight']} valuePropName="checked" label={t('global:style')}>
        <Checkbox>{t('formulaPage:bold')}</Checkbox>
      </Form.Item>,
      <Form.Item key="decimals" name={['style', 'decimals']} label={t('global:decimal', 'Desimaalit')}>
        <Select
          options={[0, 1, 2, 3].map(val => ({
            label: val,
            value: val,
            key: val
          }))}
        />
      </Form.Item>
    ]

    return tableFields
  }, [variable, varibleSourceType])

  // input kentat taulukon tyyleille
  const tableStyleFields = useMemo(() => {
    const tableFields = [
      <Form.Item
        key="thresholdValueSetting"
        name={['style', 'thresholdValueSetting']}
        label={t('customReportPage:thresholdValueSetting')}
      >
        <Select
          options={Object.keys(ThresholdValueSetting).map(val => ({
            label: t(`customReportPage:${val}`),
            value: val
          }))}
        />
      </Form.Item>
    ]

    return tableFields
  }, [variable, varibleSourceType])

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 32 }}>
      <Typography.Title level={5}>{t('customReportPage:basic')}</Typography.Title>
      {basicFields}
      <Divider />
      <Typography.Title level={5}>{t('customReportPage:parameters')}</Typography.Title>
      {paramsFields}
      <Divider />
      <Typography.Title level={5}>{t('customReportPage:styles')}</Typography.Title>
      {styleFields}
      {sectionType === 'table' && tableStyleFields}
      {sectionType === 'graph' && chartStylesEnable && (
        <FormItemGroup prefix="style">
          <SeriesStyleFields />
        </FormItemGroup>
      )}
      {sectionType === 'pie' && (
        <Form.Item key="color" name={['style', 'color']} label={t('global:color')}>
          <ColorPicker />
        </Form.Item>
      )}
    </Space>
  )
}

export default CustomReportVariableForm
