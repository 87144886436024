import React from 'react'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import Loading from './Loading'
import OrderTable from './OrderTable'
import { Orderable } from '../../types/orderable/Orderable'
import { CustomErrorResponseData } from '../../services/api/globalErrorRegistration'

type OrderpageQuery = () => UseQueryResult<Orderable[], AxiosError<CustomErrorResponseData, any>>
type OrderPageMutation = () => UseMutationResult<
  Orderable[],
  AxiosError<CustomErrorResponseData, any>,
  Orderable[],
  unknown
>

interface OrderPageProps {
  query: OrderpageQuery
  mutation: OrderPageMutation
}

const OrderPage = ({ query, mutation }: OrderPageProps) => {
  const { data, isFetching, isLoading } = query()
  const updateOrder = mutation()

  const onOrderChange = (newOrder: Orderable[]) => {
    updateOrder.mutate(newOrder)
  }

  if (isFetching) return <Loading />

  return <OrderTable data={data} onChange={onOrderChange} loading={isLoading || updateOrder.isPending} />
}

export default OrderPage
