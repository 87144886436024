import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import {
  changeMultiPurposeDashboardActiveDashboard,
  setMultiPurposeDashboardActiveDashboard
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'

import MultiPurposeDashboardFormulaCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardFormulaCharts'
import MultiPurposeParamsForm from '../../../features/dashboards/components/MultiDashboardForm'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import MultiPurposeDashboardProvider from '../multiPurposeDashboard/MultiPurposeDashboardProvider'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { getDashboardData } from '../multiPurposeDashboard/utils'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import MultiPurposeDashboardTable from '../multiPurposeDashboard/components/MultiPurposeDashboardTable'
import MultiPurposeDashboardKeyFigureCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardKeyFigureCharts'
import MultiDashboardModal from '../../../features/dashboards/components/MultiDashboardModal'
import MultiToolbar from './MultiCompanyToolbar'
import NoDashboards from '../multiPurposeDashboard/components/NoDashboards'
import { AppDispatch } from '../../../redux/store'
import MultiPurposeDashboardTableTwo from '../multiPurposeDashboard/components/MultiPurposeDashboardTableTwo'
import ReportSelect from '../../../components/Misc/ReportSelect'
import Loading from '../../../shared/components/Loading'
import { DashboardPageType } from '../types'
import { useCompaniesDashboardsPage } from '../../../features/dashboards'

const MultiCompanyDashboard: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)
  const { data: dashboards, isLoading, isFetching } = useCompaniesDashboardsPage()
  const [modalVisible, setModalVisible] = useState(false)
  const [showCharts, setShowCharts] = useState(false)
  const [toggleRowsAndColumns, setToggleRowsAndColumns] = useState(false)
  const multiPurposeDashboard = useSelector(multiPurposeDashboardPageSelector)
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const [loading, setLoading] = useState(false)
  const tableEl = useRef<HTMLDivElement>(null)

  const activeDashboardData = useMemo(
    () => dashboards?.find(d => d.id === activeDashboard),
    [activeDashboard, dashboards]
  )

  const onChange = (id: number) => {
    dispatch(setMultiPurposeDashboardActiveDashboard(id))
  }

  useEffect(() => {
    return () => {
      dispatch(changeMultiPurposeDashboardActiveDashboard(undefined))
    }
  }, [])

  if (isLoading) return <Loading />

  return (
    <MultiPurposeDashboardProvider
      dashboard={activeDashboardData}
      multiPurposeDashboardPage={DashboardPageType.companies}
    >
      <PageHeaderWrapper
        loading={isFetching}
        title={`${t('menu:/dashboard')} - ${t(`menu:/dashboard/${location.pathname.split('/').slice(-1)[0]}`)}`}
        subTitle={<DocumentationLink />}
        ghost={false}
        extra={[
          <ReportSelect onChange={onChange} dashboards={dashboards} activeDashboard={activeDashboard} />,
          <Link to="/settings/dashboards/companies/order">
            <Button icon={<SettingOutlined />}>{t('global:settings')}</Button>
          </Link>,
          <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
            {t('dashboardPage:new-dashboard')}
          </Button>
        ]}
        content={activeDashboard && <MultiPurposeParamsForm setLoading={setLoading} />}
      >
        <LoadingWrapper loading={loading}>
          <MultiDashboardModal
            multiPurposePage={DashboardPageType.companies}
            visible={modalVisible}
            setVisible={setModalVisible}
          />
          {dashboards?.length ? (
            <Card>
              <MultiToolbar
                toggleCharts={val => setShowCharts(val)}
                toggleRowsAndColumns={val => setToggleRowsAndColumns(val)}
                element={tableEl}
                exportName={activeDashboardData?.name}
              />
              <div ref={tableEl}>
                {toggleRowsAndColumns ? (
                  <MultiPurposeDashboardTableTwo
                    tableData={getDashboardData(
                      activeDashboardData,
                      multiPurposeDashboard.keyfigureStatements,
                      multiPurposeDashboard.dimensionKeyfigureStatements,
                      multiPurposeDashboard.dimensions,
                      myCompanies
                    )}
                  />
                ) : (
                  <MultiPurposeDashboardTable
                    tableData={getDashboardData(
                      activeDashboardData,
                      multiPurposeDashboard.keyfigureStatements,
                      multiPurposeDashboard.dimensionKeyfigureStatements,
                      multiPurposeDashboard.dimensions,
                      myCompanies
                    )}
                  />
                )}
              </div>
              {showCharts && (
                <>
                  <MultiPurposeDashboardKeyFigureCharts />
                  <MultiPurposeDashboardFormulaCharts />
                </>
              )}
            </Card>
          ) : (
            <NoDashboards onClick={() => setModalVisible(true)} />
          )}
        </LoadingWrapper>
      </PageHeaderWrapper>
    </MultiPurposeDashboardProvider>
  )
}

export default MultiCompanyDashboard
