import { queryOptions, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { InvestmentsPageReport } from '../../../redux/pages/investments/types'
import investmentQueryKeys from '../queries/investmentQueryKeys'
import { getInvestmentSummary } from '../service/investmentService'

export const useInvestmentOptions = <TData = InvestmentsPageReport>(
  isExisting: boolean,
  select?: (data: InvestmentsPageReport) => TData
) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)

  return queryOptions({
    queryKey: investmentQueryKeys.summary({
      companyId,
      isExisting,
      date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
      budgetingScenarioId: budgetingScenario?.id
    }),
    queryFn: getInvestmentSummary,
    select
  })
}

export const useInvestmentSummary = (isExisting: boolean) => {
  const options = useInvestmentOptions(isExisting)
  return useQuery(options)
}
