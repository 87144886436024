import i18next from 'i18next'
import _ from 'lodash'
import { ReactText } from 'react'
import { ReportTableRow, VariableType } from '../../../../components/Table/types'
import { Column } from '../../../../redux/context/periodGroups/hooks'
import { ReportDataType } from '../../../../redux/context/reports/types'
import { formatValueByType } from '../../../../utils/helpers'
import { GraphType } from './chart/ChartTypeSelect'
import { CustomReportCategory } from '../../../../redux/context/customReports/typesCategory'
import { CustomReportChartSection } from '../../../../redux/context/customReports/typesSection'
import { CustomReportVariable } from '../../../../redux/context/customReports/typesVariable'
import { CustomReport, CustomReportGroup } from '../../../../redux/context/customReports/types'
import { CustomReportData } from './settings/CustomReportOrder'

export type FormatterType = Highcharts.TooltipFormatterContextObject & {
  point?: {
    negative?: boolean
    percent?: boolean
  }
}

export function formatter(this: FormatterType) {
  const percent = this.percentage?.toFixed(1)
  const y = formatValueByType((this.point.negative && this.y ? -this.y : this.y) as ReactText, VariableType.absolute, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  })
  return `${this.key}:<b>${percent}%</b>${
    !this.point.percent ? `<br>${i18next.t('global:currency-symbol')}: <b>${y}</b>` : ''
  }`
}

export function customReportFormatter(this: FormatterType) {
  const y = formatValueByType((this.point.negative && this.y ? -this.y : this.y) as ReactText, VariableType.absolute, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  })
  return `${this.key}:<b>${y}%</b>`
}

export const getCustomChartPlotOptions = (
  type: GraphType,
  section: CustomReportChartSection
): [GraphType, Highcharts.Options['plotOptions']] => {
  switch (type) {
    case GraphType.line:
      return [
        GraphType.line,
        {
          series: {
            animation: false
          },
          line: {
            stacking: section?.style?.stacking,
            dataLabels: {
              enabled: section.style?.showDataLabels === 'true',
              rotation: 270,
              x: 0,
              y: -10
            }
          }
        }
      ]
    case GraphType.spline:
      return [
        GraphType.spline,
        {
          series: {
            animation: false
          },
          spline: {
            stacking: section?.style?.stacking,
            dataLabels: {
              enabled: section.style?.showDataLabels === 'true',
              rotation: 270,
              x: 0,
              y: -10
            }
          }
        }
      ]
    case GraphType.areaspline:
      return [
        GraphType.column,
        {
          series: {
            animation: false
          },
          areaspline: {
            stacking: section?.style?.stacking,
            dataLabels: {
              enabled: section?.style?.showDataLabels === 'true',
              rotation: 270,
              x: 0,
              y: -10
            }
          }
        }
      ]

    case GraphType.column:
    default:
      return [
        GraphType.column,
        {
          series: {
            animation: false
          },
          column: {
            grouping: true,
            stacking: section?.style?.stacking,
            dataLabels: {
              enabled: section.style?.showDataLabels === 'true',
              rotation: 270,
              x: 0,
              y: -10
            }
          }
        }
      ]
  }
}

export const isLeaf = (category: CustomReportCategory): boolean => {
  if (category.type === 'function') return true
  return !category.children || category.children.length < 1
}

export const getLeafNodes = (nodes: ReportTableRow[] | CustomReportCategory[], result: Column[] = []) => {
  for (let i = 0, { length } = nodes; i < length; i += 1) {
    if (!nodes[i].children || nodes[i].children.length === 0) {
      result.push(nodes[i] as unknown as Column)
    } else {
      // eslint-disable-next-line no-param-reassign
      result = getLeafNodes(nodes[i].children, result)
    }
  }
  return result.slice(0) as Column[]
}

export const getTitle = (reportTableRow: ReportTableRow | CustomReportVariable) => {
  if (reportTableRow.params?.dimension) {
    return `${reportTableRow.name} - ${reportTableRow.params.dimension.name}`
  }
  return reportTableRow.name
}

export const getCommonSizeValue = (id: string | undefined, commonSizeVariables?: CustomReportVariable[]): number => {
  if (commonSizeVariables && id) {
    return (
      commonSizeVariables?.reduce(
        (acc: number, cur: CustomReportVariable) =>
          acc + (cur?.balances?.find(a => String(a.groupId) === id)?.value || 0),
        0
      ) || 1
    )
  }
  return 1
}

export const getRight = (categories: CustomReportCategory[]): CustomReportCategory | undefined => {
  let right = categories?.[0]?.children?.[0]?.children?.[1] // Täällä on datatyppi ja period
  if (categories?.[0]?.children?.[0].type === 'dimension') {
    // eslint-disable-next-line prefer-destructuring
    right = categories?.[0]?.children?.[0]?.children?.[0]?.children?.[1] // Täällä on datatyppi ja period
  }
  return right
}

export const getColumn = (category: CustomReportCategory): any => {
  const getChildren = (c: CustomReportCategory) => {
    switch (c.type) {
      case 'function':
        return undefined
      default:
        return c.children && c.children.map(getColumn)
    }
  }

  return {
    ...category,
    dataIndex: `${category.dataType || ReportDataType.actuals}-${category.id}`,
    key: category.id,
    children: getChildren(category)
  }
}

export const getCategoryVariableType = (category?: CustomReportCategory | null): VariableType | undefined => {
  if (
    (category?.type === 'function' && (category?.value === 'growth' || category?.value === 'commonSize')) ||
    category?.variableType === VariableType.percentage
  ) {
    return VariableType.percentage
  }
  return undefined
}

const variableData = ({ keyFigureId, formulaId, source, params }: CustomReportVariable) => {
  const dimensionId = params?.dimension?.dimensionId

  return {
    keyFigureId,
    formulaId,
    ...(keyFigureId || formulaId ? {} : { source }),
    dimensionId,
    params
  }
}

export const variablesAreEqual = (oldVariable: CustomReportVariable, newVariable: CustomReportVariable) => {
  return _.isEqual(variableData(newVariable), variableData(oldVariable))
}

export const getMaxSeriesValue = (chartSeries: Highcharts.Options[]) => {
  const seriesData = []
  for (const serie of chartSeries) {
    seriesData.push(...(serie.data as number[]))
  }
  return Math.abs(Math.max(...seriesData) * 1.3)
}

export const getVariable = (variable: CustomReportVariable, index: number) => {
  const { formulaId, keyFigureId, style, name, type, source, params, id: variableId, nameFi, nameEn, nameSv } = variable
  const [dataTypeName, id] = params?.dataType || []

  return {
    id: variableId,
    formulaId: formulaId || null,
    keyFigureId: keyFigureId || null,
    style,
    name,
    type,
    source,
    order: index,
    nameFi,
    nameEn,
    nameSv,
    params: {
      ...params,
      dimension: params?.dimension?.dimensionId ? params.dimension : undefined,
      dataType: dataTypeName as CustomReportVariable['dataType'],
      ...(id ? { budgetingScenario: { id } } : { budgetingScenario: null })
    }
  }
}

export const getCustomReportTree = (customReportList: (CustomReport | CustomReportGroup)[]) => {
  const traverse: any = (customReport: CustomReport) => {
    if (customReportList) {
      const children = customReportList
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .filter(d => d.groupId === customReport.id)
      return {
        ...customReport,
        title: customReport.name || customReport.title,
        children: children.length > 0 ? children.map(child => traverse(child)) : undefined
      }
    }
    return []
  }
  return (
    customReportList
      ?.filter(d => !d.groupId || !customReportList.find(i => i.id === d.groupId))
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(root => traverse(root)) || []
  )
}

export const firstReport = (data?: CustomReportData[]): CustomReport | undefined => {
  if (!data) return undefined
  let result
  function iter(a: CustomReportData) {
    if (!a?.group) {
      result = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  }
  data.some(iter)
  return result
}
