import { ContractFranchise } from '../../../../../types/contract/Contract'
import { ArgumentOption } from './types'

export const booleanOptions: ArgumentOption[] = [
  { name: 'true', value: 'true' },
  { name: 'false', value: 'false' }
]

export const sourceOptions: ArgumentOption[] = [
  { name: 'pl', value: 'pl' },
  { name: 'bs', value: 'bs' },
  { name: 'account', value: 'account' }
]

export const periodOptions: ArgumentOption[] = [
  { name: 'current', value: 'current' },
  { name: 'previous', value: 'previous' },
  { name: 'cumulative', value: 'cumulative' },
  { name: 'pastyear', value: 'pastyear' }
]

export const aggregationOptions: ArgumentOption[] = [
  { name: 'sum', value: 'sum' },
  { name: 'average', value: 'average' },
  { name: 'firstValue', value: 'firstValue' },
  { name: 'lastValue', value: 'lastValue' }
]

export const receiptsSourceOptions: ArgumentOption[] = [
  {
    name: 'kotipizza',
    value: 'kotipizza',
    authority: {
      franchise: ContractFranchise.KOTIPIZZA
    }
  },
  { name: 'restolution', value: 'restolution' },
  { name: 'tulopos', value: 'tulopos' }
]

export const receiptsMeasureOptions: ArgumentOption[] = [{ name: 'quantity', value: 'quantity' }]

export const salesSourceOptions: ArgumentOption[] = [
  {
    name: 'kotipizza',
    value: 'kotipizza',
    authority: {
      franchise: ContractFranchise.KOTIPIZZA
    }
  },
  { name: 'restolution', value: 'restolution' },
  { name: 'tulopos', value: 'tulopos' }
]

export const salesItemSourceOptions: ArgumentOption[] = [
  {
    name: 'accounting',
    value: 'accounting'
  },
  {
    name: 'futursoft',
    value: 'futursoft'
  },
  {
    name: 'kotipizza',
    value: 'kotipizza',
    authority: {
      franchise: ContractFranchise.KOTIPIZZA
    }
  },
  {
    name: 'restolution',
    value: 'restolution'
  },
  {
    name: 'tulopos',
    value: 'tulopos'
  }
]

export const salesItemMeasureOptions: ArgumentOption[] = [
  { name: 'amount', value: 'amount' },
  { name: 'quantity', value: 'quantity' }
]

export const routeSourceOptions: ArgumentOption[] = [
  { name: 'ac_panther', value: 'ac_panther' },
  { name: 'kiho', value: 'kiho' },
  { name: 'logisystems', value: 'logisystems' },
  { name: 'mapon', value: 'mapon' }
]

export const workhourSourceOptions: ArgumentOption[] = [
  { name: 'maraplan', value: 'maraplan' },
  { name: 'hrsuunti', value: 'hrsuunti' },
  { name: 'työvuorovelho', value: 'työvuorovelho' },
  { name: 'severa', value: 'severa' },
  { name: 'tamigo', value: 'tamigo' }
]

export const projectSourceOptions: ArgumentOption[] = [{ name: 'severa', value: 'severa' }]
