import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { createInvestmentDepreciation } from '../service/investmentService'
import { DepreciationItem } from '../components/InvestmenDeprecationModal'

export const useCreateInvestmentDepreciation = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: ({ data, deprecationPlanId }: { data: Partial<DepreciationItem>; deprecationPlanId: number }) =>
      createInvestmentDepreciation(data, companyId, deprecationPlanId, budgetingScenario?.id)
  })
}
