import React from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../components/Misc/Loading'
import OrderTable from '../../../shared/components/OrderTable'
import { Orderable } from '../../../types/orderable/Orderable'
import { companyDashboardSelector } from '../../../redux/context/dashboard/company/selector'
import useUpdateCompanyDashboardsOrder from '../queries/useUpdateCompanyDasboardsOrder'
import { CompanyDashboard } from '../../../types/dashboard/company/types'

const CompanyDashboardOrderPage = () => {
  const companyDashboards = useSelector(companyDashboardSelector)
  const { mutate, isPending } = useUpdateCompanyDashboardsOrder()

  const onOrderChange = (newOrder: Orderable[]) => {
    mutate(newOrder as Partial<CompanyDashboard>[])
  }

  const isFetching = false

  if (isFetching) return <Loading />

  return <OrderTable data={companyDashboards} onChange={onOrderChange} loading={isPending} />
}

export default CompanyDashboardOrderPage
