import React, { useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Row, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { RuleRender } from 'antd/lib/form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import DimensionSelect from '../../../components/Dimension/DimensionSelect'
import AccountSelect from '../../account/components/AccountSelect'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { longTermLiabilityRowIdsSelector } from '../../../redux/config/financialStatement/selectors'
import { loanRepaymentMethodsSelector } from '../../../redux/entities/loanRepaymentMethods/selectors'
import { LoanRepaymentMethod } from '../../../redux/entities/loanRepaymentMethods/types'
import { liabilityMappingSelector } from '../../../redux/config/liabilityMapping/selectors'
import { normalizedConfigSelector } from '../../../redux/config/selectors'
import { FormItem, MyFormItemContext } from '../../../components/Form/FormItemContext'

interface LoanFormFieldsProps {
  purposes?: string[]
  isExisting: boolean
  addNew?: boolean
}

const infoTooltip = (text: string) => (
  <Tooltip placement="top" title={text}>
    <InfoCircleOutlined style={{ verticalAlign: 'middle', marginLeft: 8 }} />
  </Tooltip>
)

export const LoanFormFields = ({ isExisting, purposes, addNew }: LoanFormFieldsProps) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const {
    financialStatement: { financialExpenseRowId }
  } = useSelector(normalizedConfigSelector)
  const [validationPassed, setValidationPassed] = useState(true)
  const loanRepaymentMethods = useSelector(loanRepaymentMethodsSelector)
  const liabilityMapping = useSelector(liabilityMappingSelector)
  const longTermLiabilityRowIds: any = useSelector(longTermLiabilityRowIdsSelector)
  const prefixPath = React.useContext(MyFormItemContext)
  const balanceSheetRowId = useWatch(
    prefixPath.length ? ['fundings', ...prefixPath, 'balanceSheetRowId'] : 'balanceSheetRowId',
    form
  )
  const termsAndRepaymentsSame = useWatch(
    prefixPath.length ? ['fundings', ...prefixPath, 'termsAndRepaymentsSame'] : 'termsAndRepaymentsSame',
    form
  )

  const loanValidation: RuleRender = ({ getFieldValue, setFieldsValue, setFields }) => ({
    validator: async (__: any) => {
      const field = __.field.split('.')
      const preField = field.slice(0, -1)
      const termYears = getFieldValue(preField.concat('termYears'))
      const termMonths = getFieldValue(preField.concat('termMonths'))
      if (termsAndRepaymentsSame) {
        if (prefixPath) {
          setFields([
            {
              name: preField.concat('repaymentProgramYears'),
              value: termYears
            },
            {
              name: preField.concat('repaymentProgramMonths'),
              value: termMonths
            }
          ])
        } else {
          setFieldsValue({
            repaymentProgramYears: termYears,
            repaymentProgramMonths: termMonths
          })
        }
      }
      const repaymentProgramYears = getFieldValue(preField.concat('repaymentProgramYears'))
      const repaymentProgramMonths = getFieldValue(preField.concat('repaymentProgramMonths'))

      const isValid = termYears * 12 + termMonths >= repaymentProgramYears * 12 + repaymentProgramMonths
      if (
        Number.isFinite(termYears) &&
        Number.isFinite(termMonths) &&
        Number.isFinite(repaymentProgramYears) &&
        Number.isFinite(repaymentProgramMonths)
      ) {
        setValidationPassed(isValid)
        if (!isValid) {
          return Promise.reject(new Error(''))
        }
      }
      return Promise.resolve()
    }
  })

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            label={t('loans:balanceSheetRow')}
            name="balanceSheetRowId"
            rules={[{ required: true, message: ' ' }]}
            hidden={!addNew}
          >
            <Select showSearch optionFilterProp="children">
              {(longTermLiabilityRowIds || []).map((rowId: any) => {
                return (
                  <Select.Option key={rowId} value={rowId}>
                    {t(`balanceSheet:${rowId}`)}
                  </Select.Option>
                )
              })}
            </Select>
          </FormItem>
        </Col>
        {addNew ? (
          <Col span={12}>
            <FormItem name="value" label={t('loans:remainingLoanValue')} rules={[{ required: true, message: '' }]}>
              <FormattedInputNumber block min={0} />
            </FormItem>
          </Col>
        ) : null}
      </Row>
      <Col span={24}>
        <Row gutter={16} align="bottom">
          <Col span={12}>
            <FormItem
              name="financialExpensesAccountCode"
              label={t('loans:financialExpensesAccountCode')}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={financialExpenseRowId} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="longTermAccountCode"
              label={t('loans:longTermAccountCode')}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={[balanceSheetRowId]} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="shortTermAccountCode"
              label={t('loans:shortTermAccountCode')}
              rules={[{ required: true, message: '' }]}
            >
              <AccountSelect balanceSheetRowIds={[liabilityMapping?.[balanceSheetRowId]]} />
            </FormItem>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <FormItem
          name="nextInstalmentDate"
          rules={[{ required: true, message: '' }]}
          label={isExisting ? t('loans:next-instalment-date') : t('loans:loan-drawdown-date')}
        >
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
        </FormItem>
      </Col>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            name="termYears"
            label={
              <>
                {t('loans:term-year')}
                {infoTooltip(t('loans:term/info'))}
              </>
            }
            initialValue={0}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            rules={[{ required: true, message: '' }, loanValidation]}
          >
            <FormattedInputNumber block min={0} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="termMonths"
            label={t('loans:term-month')}
            initialValue={0}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            rules={[{ required: true, message: '' }, loanValidation]}
            shouldUpdate
          >
            <FormattedInputNumber block min={0} max={12} />
          </FormItem>
        </Col>
      </Row>
      <Col span={12}>
        <FormItem
          name="termsAndRepaymentsSame"
          label={t('loans:termsAndRepaymentsSame')}
          initialValue
          valuePropName="checked"
          rules={[loanValidation]}
        >
          <Checkbox />
        </FormItem>
      </Col>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            name="repaymentProgramYears"
            label={
              <>
                {t('loans:repayment-program-years')}
                {infoTooltip(t('loans:repayment-program/info'))}
              </>
            }
            hidden={termsAndRepaymentsSame}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            rules={[{ required: true, message: '' }, loanValidation]}
          >
            <FormattedInputNumber block min={0} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="repaymentProgramMonths"
            label={t('loans:repayment-program-moths')}
            hidden={termsAndRepaymentsSame}
            validateStatus={!validationPassed ? 'error' : 'success'}
            help={!validationPassed ? t('loans:loanFieldTerms') : null}
            rules={[{ required: true, message: '' }, loanValidation]}
          >
            <FormattedInputNumber block min={0} max={12} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Form.Item noStyle shouldUpdate>
          <Col span={12}>
            <FormItem
              name="instalmentFreePeriod"
              label={
                <>
                  {t('loans:instalment-free-period')}
                  {infoTooltip(t('loans:instalment-free-period/info'))}
                </>
              }
              rules={[{ required: true, message: '' }]}
              initialValue={0}
            >
              <FormattedInputNumber block min={0} />
            </FormItem>
          </Col>
        </Form.Item>
        <Col span={12}>
          <FormItem
            name="loanRepaymentMethodId"
            label={
              <>
                {t('loans:loan-repayment-method')}
                {infoTooltip(t('loans:loan-repayment-method/info'))}
              </>
            }
            rules={[{ required: true, message: '' }]}
          >
            <Select showSearch optionFilterProp="children">
              {loanRepaymentMethods.map((loanRepaymentMethod: LoanRepaymentMethod) => {
                return (
                  <Select.Option key={loanRepaymentMethod.id} value={loanRepaymentMethod.id}>
                    {t(`loanRepaymentMethod:${loanRepaymentMethod.id}`)}
                  </Select.Option>
                )
              })}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem
            name="yearlyInstalments"
            label={t('loans:yearly-instalments')}
            initialValue={12}
            rules={[{ required: true, message: '' }]}
          >
            <FormattedInputNumber block min={1} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="interestRate"
            label={t('loans:interest-rate')}
            initialValue={0}
            rules={[{ required: true, message: '' }]}
          >
            <FormattedInputNumber block percentage addonAfter="%" min={0} max={100} step={1} />
          </FormItem>
        </Col>
      </Row>
      {isExisting && (
        <Col span={12}>
          <FormItem name="purposes" label={t('loans:purposes')}>
            <Select style={{ width: '100%' }} mode="tags" showSearch optionFilterProp="children">
              {purposes &&
                purposes.map(item => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
            </Select>
          </FormItem>
        </Col>
      )}
      <Col span={12}>
        <FormItem name="dimensionId" label={t('global:dimension')}>
          <DimensionSelect />
        </FormItem>
      </Col>
    </>
  )
}
