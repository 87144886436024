import { createSelector } from 'reselect'
import { groupCompanySelector } from '../groupCompany/selectors'
import { contextSelector } from '../selectors'
import { Company } from '../../../types/company/Company'

export const subsidiariesSelector = createSelector(contextSelector, context => context?.subsidiaries)

export const subsidiariesArgumentOptionsSelector = createSelector(
  groupCompanySelector,
  subsidiariesSelector,
  (group, subsidiaries) => {
    const mapSubsidiaries = (subsidiary: Company): { name: string; value: string }[] => {
      let base = {
        name: `${subsidiary.id} - ${subsidiary.name}`,
        value: `'${subsidiary.id}'`
      }
      if (subsidiary.groupId && subsidiary.group) {
        base = {
          name: `${subsidiary.group.id} - ${subsidiary.group.name}`,
          value: `'${subsidiary.group.id}'`
        }
      }
      const children = (subsidiary.subsidiaries || []).flatMap(mapSubsidiaries)
      return [base, ...children]
    }

    const processedSubsidiaries = subsidiaries.flatMap(mapSubsidiaries)

    if (group) {
      return [
        {
          name: `${group.id} - ${group.name}`,
          value: `'${group.id}'`
        },
        ...processedSubsidiaries
      ]
    }

    return processedSubsidiaries
  }
)
