import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import dayjs from 'dayjs'
import { store } from '../redux/store'
import { Country } from '../types/company/Company'
import { Language } from '../types/user/User'
import { changeLanguageAction } from '../redux/app/actions'

const {
  location: { hostname }
} = window

const domainParts = hostname.split('.')
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [subDomain, domain, topLevelDomain] = domainParts

const currencies: { [key in Country]: string } = {
  fi: '€',
  se: 'kr'
}

const getLng = () => {
  switch (topLevelDomain) {
    case 'se':
      return 'sv'
    case 'fi':
    default:
      return 'fi'
  }
}

export default () => {
  i18n
    .use({
      type: 'postProcessor',
      name: 'currencyProcessor',
      process(value: string) {
        const regex = /\[\[\[?((#[a-z]+ )?[a-z]+.[a-z]*)\]?\]\]/
        const match = value.match(regex)

        const state: any = store.getState() || {}
        // eslint-disable-next-line prefer-destructuring
        const country: Country = state?.context?.company?.country

        if (match?.includes('currency')) {
          return value.replace(match[0], currencies[country || 'fi'])
        }
        return value
      }
    })
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
      backend: {
        loadPath: `${process.env.REACT_APP_TRANSLATION_URL}/{{lng}}.json`,
        reloadInterval: 7200000,
        requestOptions: {
          cache: 'no-store'
        }
      },
      lng: getLng(),
      nsSeparator: '.',
      keySeparator: ':',
      fallbackLng: 'fi',
      postProcess: ['currencyProcessor'],
      interpolation: {
        escapeValue: false
      }
    })
  i18n.on('languageChanged', (lng: Language) => {
    store.dispatch(changeLanguageAction(lng))
    dayjs.locale(lng)
  })
}
