import { Form, Input, Select, Row, Col, Checkbox, FormInstance, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextAreaRef } from 'antd/es/input/TextArea'
import { useWatch } from 'antd/es/form/Form'
import TranslateFields from '../../../../../../components/Form/TranslateFields'
import { AggregationTypes, Formula } from '../../../../../../types/formula/Formula'
import FormulaAddFunction from './FormulaAddFunction'
import FormulaSource from './FormulaSource'

import { useAuthorizedData } from '../../../../../../utils/Authorizable/authorize'
import { useTextAreaCursor } from '../../../../../../utils/hooks'
import { internalKeyFigureTypes } from '../../../../../reporting/keyFigures/config'
import ReferenceRules from './ReferenceRules/ReferenceRules'
import { useFunctions } from '../functions'

type FormData = Omit<Formula, 'style'> & { style: any }

interface FormulaFormProps {
  formulaError: any
  initialValues?: Formula
  onFormInstanceReady: (instance: FormInstance<FormData>) => void
}

const FormulaForm: React.FC<FormulaFormProps> = ({ formulaError, initialValues, onFormInstanceReady }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<FormData>()
  const [textAreaRef, setTextAreaRef] = useState<TextAreaRef | null>(null)
  const cursor = useTextAreaCursor(textAreaRef)
  const functions = useAuthorizedData(useFunctions())
  const variableType = useWatch('type', form)

  const keyFigureTypes = internalKeyFigureTypes

  useEffect(() => {
    onFormInstanceReady(form)
  }, [])

  return (
    <div className="formula-editor">
      <Form form={form} layout="vertical" name="formula" initialValues={initialValues}>
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('global:name')}
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
        >
          <Input placeholder={t('global:name')} />
        </Form.Item>
        <TranslateFields />
        <Form.Item name="code" label={t('global:code')}>
          <Input placeholder={t('global:code')} />
        </Form.Item>
        <Form.Item initialValue={AggregationTypes.sum} name="aggregationType" label={t('formulaPage:aggregationType')}>
          <Select showSearch optionFilterProp="children" style={{ width: 300 }}>
            {Object.keys(AggregationTypes).map(aggregationType => (
              <Select.Option key={aggregationType} value={aggregationType}>
                {t(`formulaPage:${aggregationType}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle>
          <Row gutter={24}>
            <Col>
              <Form.Item
                name="type"
                label={t('global:type')}
                initialValue="absolute"
                rules={[
                  {
                    required: true,
                    message: t('global:required-field')
                  }
                ]}
              >
                <Select showSearch optionFilterProp="children" style={{ width: 300 }}>
                  {keyFigureTypes.map(keyFigureType => (
                    <Select.Option key={keyFigureType} value={keyFigureType}>
                      {t(`keyFigurePage:${keyFigureType}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ width: 200 }} name={['style', 'decimals']} label={t('global:decimals', 'Desimaalit')}>
                <Select>
                  <Select.Option value={0}>0</Select.Option>
                  <Select.Option value={1}>1</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={3}>3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name={['style', 'fontWeight']} valuePropName="checked" label={t('global:style')}>
          <Checkbox>{t('formulaPage:bold')}</Checkbox>
        </Form.Item>
        <FormulaAddFunction
          form={form}
          requestError={formulaError}
          cursor={cursor}
          functions={Object.values(useFunctions())
            .map(fn => ({
              name: fn.name,
              source: `${fn.name}(${fn.arguments?.map((arg: any) => arg.label).toLocaleString()})`
            }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
        <FormulaSource
          cursor={cursor}
          form={form}
          functions={functions}
          textAreaRef={textAreaRef}
          setTextAreaRef={setTextAreaRef}
        />
        <Typography.Text>{t('global:referenceRules')}</Typography.Text>
        <ReferenceRules variableType={variableType} />
      </Form>
    </div>
  )
}

export default FormulaForm
