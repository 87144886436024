import React from 'react'
import { Card, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import InvestmentSummary from './InvestmentSummary'
import { InvestmentsPageBalanceSheetRow } from '../../../redux/pages/investments/types'
import InvestmentTable from './InvestmentTable'

type InvestmentGroupProps = InvestmentsPageBalanceSheetRow

const InvestmentGroup: React.FC<InvestmentGroupProps> = ({ balanceSheetRowId, investments, summary, isExisting }) => {
  const { t } = useTranslation()

  if (!investments?.length) return null

  return (
    <Card>
      <Typography.Title level={3}>{t(`balanceSheet:${balanceSheetRowId}`)}</Typography.Title>
      <InvestmentSummary summary={summary} />
      <InvestmentTable investments={investments} isExisting={isExisting || false} />
    </Card>
  )
}

export default InvestmentGroup
