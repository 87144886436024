import _ from 'lodash'
import dayjs from 'dayjs'
import { ReportTableRow } from '../../../../components/Table/types'
import { Investment } from '../../../../types/investment/Investment'
import { Purpose } from '../../../../types/purpose/Purpose'

export const debtFundingSourceIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 16, 17, 18, 19, 20, 24, 25, 26, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110
]

export const investmentFormFormat = (values: Investment) => {
  const formattedData = {
    ...values,
    date: values?.date && dayjs(values.date),
    deprecationPlan: {
      ...values?.deprecationPlan,
      startDate: values?.deprecationPlan?.startDate && dayjs(values?.deprecationPlan?.startDate)
    },
    taxDeprecationPlan: {
      ...values?.taxDeprecationPlan,
      startDate: values?.taxDeprecationPlan?.startDate && dayjs(values?.taxDeprecationPlan?.startDate)
    },
    ...(values.fundings && {
      fundings: values.fundings.map((investmentFunding: any) => {
        let fundingFormattedData = {
          ...investmentFunding
        }
        if (investmentFunding.loan) {
          fundingFormattedData = {
            ...fundingFormattedData,
            loan: {
              ...investmentFunding.loan,
              date: investmentFunding?.loan?.date && dayjs(investmentFunding.loan.date),
              nextInstalmentDate:
                investmentFunding?.loan?.nextInstalmentDate && dayjs(investmentFunding.loan.nextInstalmentDate),
              purposes: investmentFunding.loan.purposes
                ? investmentFunding.loan.purposes.map((p: Purpose) => p.name)
                : [],
              termsAndRepaymentsSame: !!(
                investmentFunding.loan.termYears === investmentFunding.loan.repaymentProgramYears &&
                investmentFunding.loan.termMonths === investmentFunding.loan.repaymentProgramMonths
              )
            }
          }
        }
        return fundingFormattedData
      })
    })
  }

  return formattedData
}

export const investmentSaveFormat = (values: any) => {
  return {
    ...values,
    date: values?.date?.format('YYYY-MM-DD'),
    ...(values.deprecationPlan && {
      deprecationPlan: {
        ...values.deprecationPlan,
        startDate: values?.deprecationPlan?.startDate?.format('YYYY-MM-DD')
      }
    }),
    ...(values.taxDeprecationPlan && {
      taxDeprecationPlan: {
        ...values?.taxDeprecationPlan,
        startDate: values?.taxDeprecationPlan?.startDate?.format('YYYY-MM-DD')
      }
    }),
    ...(values.fundings && {
      fundings: values.fundings.map((investmentFunding: any) => {
        let fundingFormattedData = {
          ...investmentFunding,
          investmentFundingSource: undefined
        }
        if (investmentFunding.loan && debtFundingSourceIds.includes(fundingFormattedData.investmentFundingSourceId)) {
          fundingFormattedData = {
            ...fundingFormattedData,
            loan: {
              ...investmentFunding.loan,
              purposes: investmentFunding.loan.purposes
                ? investmentFunding.loan.purposes.map((p: string) => ({ name: p }))
                : []
            }
          }
        } else {
          fundingFormattedData = {
            ...fundingFormattedData,
            loan: undefined,
            loanId: undefined
          }
        }
        return fundingFormattedData
      })
    })
  }
}

export const getInitialValues = (investment?: Investment, reportTableRow?: ReportTableRow) => {
  let initValues: any = _.cloneDeep(investment)
  if (initValues) {
    initValues = {
      ...initValues,
      date: initValues.date ? dayjs.utc(initValues.date).local() : null,
      nextInstalmentDate: initValues.nextInstalmentDate ? dayjs.utc(initValues.nextInstalmentDate).local() : null,
      interestRate: initValues.interestRate ? Number(initValues.interestRate) * 100 : 0,
      vat: initValues.vat ? Math.round(Number(initValues.vat) * 100) : 0
    }
  }
  if (initValues && initValues.deprecationPlan) {
    initValues.deprecationPlan = {
      ...initValues.deprecationPlan,
      // deprecationMethodId: initValues.deprecationPlan.deprecationMethodId,
      startDate: initValues.deprecationPlan.startDate ? dayjs.utc(initValues.deprecationPlan.startDate).local() : null
    }
  }
  if (initValues && initValues.taxDeprecationPlan) {
    initValues.taxDeprecationPlan = {
      ...initValues.taxDeprecationPlan,
      startDate: initValues.taxDeprecationPlan.startDate
        ? dayjs.utc(initValues.taxDeprecationPlan.startDate).local()
        : null
    }
  }
  if (initValues && initValues.fundings) {
    initValues.fundings = initValues.fundings.map((investmentFunding: any) => {
      let fundingFormattedData = {
        ...investmentFunding
      }
      if (investmentFunding.loan) {
        fundingFormattedData = {
          ...fundingFormattedData,
          loan: {
            ...investmentFunding.loan,
            nextInstalmentDate: investmentFunding.loan.nextInstalmentDate
              ? dayjs.utc(investmentFunding.loan.nextInstalmentDate).local()
              : null,
            purposes: investmentFunding.loan.purposes
              ? investmentFunding.loan.purposes.map((p: Purpose) => p.name)
              : [],
            termsAndRepaymentsSame: !!(
              investmentFunding.loan.termYears === investmentFunding.loan.repaymentProgramYears &&
              investmentFunding.loan.termMonths === investmentFunding.loan.repaymentProgramMonths
            )
          }
        }
      }
      return fundingFormattedData
    })
  }
  if (!initValues && reportTableRow) {
    if (reportTableRow.id) {
      initValues = {
        balanceSheetRowId: reportTableRow.id,
        deprecationPlan: {
          deprecationMethodId: 1
        }
      }
    } else if (reportTableRow.code) {
      initValues = {
        accountCode: reportTableRow.code,
        deprecationPlan: {
          deprecationMethodId: 1
        }
      }
    }
  }

  return initValues
}
