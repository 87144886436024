import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Account } from '../types/Account'
import { createAccount } from '../api/accounts'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { accountQueryKeys } from './accountQueryKeys'

export const useCreateAccount = () => {
  const { companyId } = useContextParameters()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (account: Account) => {
      if (!companyId) {
        throw new Error('Company ID is required')
      }
      return createAccount(companyId, account)
    },
    onSuccess: createdItem => {
      queryClient.setQueryData(accountQueryKeys.list(companyId), (oldItems: Account[]) => {
        return [...oldItems, createdItem]
      })
    },
    onError: error => {
      console.error('Failed to create account:', error)
    }
  })
}
