import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { budgetingScenariosAndForecastsSelector } from '../../../redux/context/budgetingScenarios/selectors'
import MultiPurposeDashboard from '../multiPurposeDashboard/MultiPurposeDashboard'
import { useScenarioDashboardsPage } from '../../../features/dashboards'
import Loading from '../../../shared/components/Loading'

const ScenarioDashboard: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboards, isPending } = useScenarioDashboardsPage()
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)

  const activeDashboardData = useMemo(
    () => dashboards?.find(d => d.id === activeDashboard),
    [activeDashboard, dashboards]
  )

  if (isPending) return <Loading />

  return (
    <MultiPurposeDashboard
      pageTitle={`${t('menu:/dashboard')} - ${t('menu:/dashboard/scenarios')}`}
      dashboards={dashboards}
      settingsUrl="/settings/dashboards/scenarios/order"
      allRows={budgetingScenariosAndForecasts}
      selectedKeyfigureIds={activeDashboardData?.keyFigures || []}
      selectedInternalKeyfigureIds={activeDashboardData?.formulas || []}
      selectedRows={activeDashboardData?.budgetingScenarios}
    />
  )
}
export default ScenarioDashboard
