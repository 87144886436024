import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useState } from 'react'
import { App } from 'antd'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { autoDownload } from '../../../utils/helpers'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { fetchInvestmentExport } from '../service/investmentService'
import investmentQueryKeys from '../queries/investmentQueryKeys'

export const useInvestmentExport = (isExisting: boolean) => {
  const { message } = App.useApp()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario: { id: budgetingScenarioId } = {} } = useSelector(filtersSelector)
  const { date } = useSelector(investmentsPageSelector)
  const [isLoading, setIsLoading] = useState(false)

  const handleExport = async () => {
    setIsLoading(true)
    try {
      const data = await fetchInvestmentExport(
        investmentQueryKeys.summary({
          companyId,
          date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
          isExisting,
          budgetingScenarioId
        })[0]
      )
      autoDownload(`investments.xlsx`, data)
    } catch (err) {
      message.error('Failed to download file')
    } finally {
      setIsLoading(false)
    }
  }

  return { download: handleExport, isLoading }
}
