import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Account } from '../types/Account'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { deleteAccount } from '../api/accounts'
import { accountQueryKeys } from './accountQueryKeys'

export const useDeleteAccount = () => {
  const { companyId } = useContextParameters()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (code: Account['code']) => {
      if (!companyId) {
        throw new Error('Company ID is required')
      }
      return deleteAccount(companyId, code)
    },
    onSuccess: deletedItem => {
      queryClient.setQueryData(accountQueryKeys.list(companyId), (oldItems: Account[]) => {
        return oldItems.filter(item => item.code !== deletedItem.code)
      })
    },
    onError: error => {
      console.error('Failed to create account:', error)
    }
  })
}
