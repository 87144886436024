import { queryOptions, useQuery } from '@tanstack/react-query'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dashboardQueryKeys from './dashbordQueryKeys'
import { fetchCompaniesDashboards } from '../service/dashboardService'
import { MultiDashboard } from '..'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { AppDispatch } from '../../../redux/store'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'

export const useCompaniesDashboardsOptions = <TData = MultiDashboard[]>(select?: (data: MultiDashboard[]) => TData) => {
  return queryOptions({
    queryKey: dashboardQueryKeys.companies(),
    queryFn: fetchCompaniesDashboards,
    select
  })
}

export const useCompaniesDashboardsQuery = <TData = MultiDashboard[]>(select?: (data: MultiDashboard[]) => TData) => {
  const options = useCompaniesDashboardsOptions(select)
  return useQuery(options)
}

export const useCompaniesDashboardsPage = () => {
  const dispatch: AppDispatch = useDispatch()
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)

  const query = useCompaniesDashboardsQuery(dashboards => dashboards.sort((a, b) => a.order - b.order))

  React.useEffect(() => {
    if (query.data && !activeDashboard) {
      dispatch(setMultiPurposeDashboardActiveDashboard(query.data?.[0]?.id))
    }
  }, [query.data, activeDashboard])

  return query
}

export const useCompaniesDashboards = () => {
  return useCompaniesDashboardsQuery(dashboards => dashboards.sort((a, b) => a.order - b.order))
}

export default useCompaniesDashboards
