import React, { useState } from 'react'
import { Card, Typography, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Loan } from '../types/Loan'
import RepaymentScheduleModal from './LoanRepaymentSceduleModal'
import LoanSummaryStats from './LoanSummary'
import LoanTable from './LoanTable'
import LoanInterestModal from './LoanInterestModal'
import { LoanSummaryBalanceSheetRow } from '../types/LoanSummary'
import { Investment } from '../../../types/investment/Investment'

type LoanGroupProps = {
  balanceSheetRowId: number
  loansByBalansheetRow: LoanSummaryBalanceSheetRow
  isExisting: boolean
  toggleModal: (investment?: Investment) => void
}

const LoanGroup: React.FC<LoanGroupProps> = ({ balanceSheetRowId, loansByBalansheetRow, isExisting, toggleModal }) => {
  const { t } = useTranslation()
  const [loanForRepaymentScheduleModal, setLoanForRepaymentScheduleModal] = useState<Loan | null>(null)
  const [loanInterestRatesModal, setLoanInterestRatesModal] = useState<Loan | null>(null)

  const repaymentHandleOk = () => {
    setLoanForRepaymentScheduleModal(null)
  }

  const toggleRepaymentSchedule = (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => {
    e.stopPropagation()
    setLoanForRepaymentScheduleModal(loan)
  }

  const toggleInterestRate = (e: React.MouseEvent<HTMLElement, MouseEvent>, loan: Loan) => {
    e.stopPropagation()
    setLoanInterestRatesModal(loan)
  }

  const interestRatesHandleOk = () => {
    setLoanInterestRatesModal(null)
  }

  return (
    <Card>
      <RepaymentScheduleModal handleOk={repaymentHandleOk} loan={loanForRepaymentScheduleModal} />
      {loanInterestRatesModal ? (
        <LoanInterestModal handleOk={interestRatesHandleOk} loan={loanInterestRatesModal} />
      ) : null}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Typography.Title level={3}>{t(`balanceSheet:${balanceSheetRowId}`)}</Typography.Title>
        {isExisting && (
          <LoanSummaryStats
            isExisting={isExisting}
            summary={loansByBalansheetRow.summary}
            balanceSheetRowId={loansByBalansheetRow.balanceSheetRowId}
          />
        )}
        <LoanTable
          toggleModal={toggleModal}
          isExisting={isExisting}
          balanceSheetRowId={balanceSheetRowId}
          loans={loansByBalansheetRow.loans}
          toggleRepaymentSchedule={toggleRepaymentSchedule}
          toggleInterestRate={toggleInterestRate}
        />
      </Space>
    </Card>
  )
}

export default LoanGroup
