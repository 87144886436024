import { LoanFormData } from '../../../../../features/loan/types/LoanFormData'
import { DeprecationMethod } from '../../../../../types/deprecation/DeprecationMethod'

export enum DeprecationMethodId {
  StraightLineDeprecation = 1,
  ReducingBalanceMethod = 2
}

export interface InvestmentGraphFormData {
  investments: InvestmentFormData[]
  investmentFundings: InvestmentFundingFormData[]
}

export interface InvestmentFormData {
  id?: number
  balanceSheetRowId: number
  value: number
  date: Date
  vat: number
  purposes: string[]
  deleted?: boolean
}

export interface InvestmentFundingFormData {
  id?: number
  value: number
  investmentFundingSourceId: number
  loanId: number
  loan: LoanFormData
  purposes: string[]
  deleted?: boolean
}

export interface DeprecationPlanFormData {
  id?: number
  balanceSheetRowId: number
  deprecationMethod: DeprecationMethod
  value: number
  delete?: boolean
}
