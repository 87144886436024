import React, { useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsCustomEvents from 'highcharts-custom-events'
import { SeriesOptionsType } from 'highcharts'
import { getPlotOptions } from '../../../components/Chart/plotOptions'
import { ChartBasicType, ChartSpecialType, ChartType } from '../../../components/Chart/types'
import ChartTypeSelect from '../../reporting/keyFigures/components/keyFigureChart/ChartTypeSelect'

HighchartsExporting(Highcharts)
HighchartsCustomEvents(Highcharts as any)

interface CompetitorChartProps {
  categories: any
  series: SeriesOptionsType[]
}

const CompetitorChart: React.FC<CompetitorChartProps> = ({ categories, series }: CompetitorChartProps) => {
  const [graphType, setGraphType] = useState<ChartBasicType>(ChartBasicType.column)
  const [specialColumnType, setSpecialColumnType] = useState<ChartSpecialType | undefined>(undefined)

  const graphSeries: any[] = []
  for (const serie of series) {
    graphSeries.push({ ...serie, type: graphType })
  }

  const plotOptions = getPlotOptions(graphType, specialColumnType)

  const options = {
    chart: { animation: false },
    title: {
      text: ''
    },
    xAxis: {
      categories
    },
    yAxis: {
      title: {
        text: null
      }
    },
    plotOptions,
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<b>{point.key}</b><table>',
      pointFormat: `
        <tr><td><span style="color:{point.color}">◉</span> {series.name}: </td>
        <td style="text-align: right"><b>{point.y}</b></td></tr>
      `,
      footerFormat: '</table>'
    },
    series: graphSeries
  }

  const selectGraphType = (e: ChartType) => {
    if (e === ChartType.stackedColumn || e === ChartType.groupedColumn) {
      setGraphType(ChartBasicType.column)
      setSpecialColumnType(e)
    } else {
      setGraphType(e)
      setSpecialColumnType(undefined)
    }
  }

  return (
    <div className="chart-container">
      <ChartTypeSelect graphType={graphType} selectGraphType={selectGraphType} />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default CompetitorChart
