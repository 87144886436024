import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { isNumber } from 'lodash'

interface FormattedInputNumberProps extends InputNumberProps {
  percentage?: boolean
  block?: boolean
  value?: string | number | null
}

export const FormattedInputNumber = React.forwardRef(
  (
    { percentage = false, step, precision, min, max, block, value, onChange, ...restProps }: FormattedInputNumberProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const factor = percentage ? 100 : 1
    const [internalValue, setInternalValue] = useState<number | undefined>(
      value ? new BigNumber(Number(value)).times(factor).toNumber() : undefined
    )

    useEffect(() => {
      if (isNumber(value)) {
        const number = new BigNumber(Number(value)).times(factor).toNumber()
        setInternalValue(number)
      }
    }, [value, percentage])

    const handleChange = (inputValue: string | number | null) => {
      if (isNumber(inputValue)) {
        setInternalValue(inputValue)
        onChange?.(new BigNumber(inputValue).div(factor).toNumber())
      }
    }

    return (
      <InputNumber
        ref={ref}
        step={step}
        min={min}
        max={max}
        value={internalValue}
        decimalSeparator=","
        onChange={handleChange}
        precision={precision}
        style={block ? { width: '100%' } : undefined}
        {...restProps}
      />
    )
  }
)
