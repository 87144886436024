import { ChartBasicType, ChartSpecialType } from './types'

export const getPlotOptions = (
  graphType: ChartBasicType | undefined,
  specialColumnType: ChartSpecialType | undefined
) => {
  let plotOptions = {}

  if (graphType === 'line' || graphType === 'spline') {
    plotOptions = {
      series: {
        animation: false
      }
    }
  } else if (graphType === 'column') {
    if (specialColumnType === 'stackedColumn') {
      plotOptions = {
        series: {
          animation: false
        },
        column: {
          stacking: 'normal'
        }
      }
    } else if (specialColumnType === 'groupedColumn') {
      plotOptions = {
        series: {
          animation: false
        },
        column: {
          grouping: false,
          stacking: null
        }
      }
    } else {
      plotOptions = {
        series: {
          animation: false
        },
        column: {
          grouping: true,
          stacking: null
        }
      }
    }
  }

  return plotOptions
}
