import { red, green } from '@ant-design/colors'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Popover, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { multiPurposeDashboardPageSelector } from '../../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { Company } from '../../../../types/company/Company'
import { Dimension } from '../../../../types/dimension/Dimension'
import { formatValueByType } from '../../../../utils/helpers'
import { globalKeyFigureConfig } from '../config'
import { MultiPurposeDashboardContext, MultiPurposeDashboardContextType } from '../MultiPurposeDashboardProvider'
import { vatIdSlicer, flattenObject } from '../utils'
import { getCellDataAttributes } from '../../../../components/Table/utils'
import { useFixedNameRow } from '../../../../components/Table/hooks'

export type CompanyTableRow = Company & {
  dimensionId?: string
  company: Company
  children: Dimension[]
}

interface MultiCompanyDashboardTableProps {
  tableData?: any
}

const MultiPurposeDashboardTable = ({ tableData }: MultiCompanyDashboardTableProps) => {
  const { formulas } = useSelector(multiPurposeDashboardPageSelector)
  const tableElement = useRef<HTMLDivElement>(null)
  const fixedNameRow = useFixedNameRow()

  const { t } = useTranslation()
  const {
    loadingDimensionData,
    dashboard,
    loading,
    formSaveLoading,
    expandedRowKeys,
    handleTableExpand,
    setExpandedRowKeys
  } = useContext(MultiPurposeDashboardContext) as MultiPurposeDashboardContextType

  const getCompareComponent = (currentValue: any, compareValue: any) => {
    if (!currentValue?.value || !compareValue?.value) return null
    const compareIcon =
      currentValue.value < compareValue.value ? (
        <ArrowDownOutlined style={{ marginRight: 4, color: red.primary }} />
      ) : (
        <ArrowUpOutlined style={{ marginRight: 4, color: green.primary }} />
      )

    return (
      <Popover content={formatValueByType(compareValue.value, compareValue.type)} title={compareValue.label}>
        {compareIcon}
      </Popover>
    )
  }

  const generateColumns = () => {
    const columns: ColumnProps<CompanyTableRow>[] = [
      {
        title: t('menu:/dashboard/company'),
        dataIndex: 'name',
        width: 200,
        fixed: fixedNameRow,
        key: 'name'
      },
      {
        title: t('contractsPage:vatId'),
        dataIndex: 'id',
        width: 120,
        key: 'vatId',
        render: (text, record) => {
          return record.dimensionId ? null : vatIdSlicer(text, record)
        }
      }
    ]

    const sorter = (id: string) => (a: any, b: any) => {
      return (
        (a[id]?.currentValue?.value || Number.MIN_SAFE_INTEGER) -
        (b[id]?.currentValue?.value || Number.MIN_SAFE_INTEGER)
      )
    }

    const onCell = (id: string) => (record: any) => {
      const { currentValue } = record[id] || {}
      const { value } = currentValue || {}
      return {
        ...getCellDataAttributes(record, currentValue?.type, value)
      } as React.HTMLAttributes<any>
    }

    const render = (val: any) => {
      const { currentValue, historyValue } = val || {}
      if (!currentValue || !historyValue) return ''
      const compareComponent = getCompareComponent(currentValue, historyValue)
      return (
        <>
          {compareComponent}
          {currentValue.value && formatValueByType(currentValue.value, currentValue.type)}
        </>
      )
    }

    const keyfigureColumns = globalKeyFigureConfig
      .filter(cfg => (dashboard?.keyFigures || []).includes(cfg.id))
      .map(config => ({
        title: t(`globalKeyFigureStatement:${config.id}`),
        width: 150,
        dataIndex: config?.id.toString(),
        key: config?.id.toString(),
        align: 'right' as const,
        sorter: sorter(config?.id.toString()),
        onCell: onCell(config?.id.toString()),
        render
      }))

    const formulaColumns = flattenObject(formulas)
      .filter(f => (dashboard?.formulas || []).includes(f?.id))
      .map(keyfigure => ({
        title: keyfigure.name,
        width: 150,
        dataIndex: keyfigure?.id.toString(),
        key: keyfigure?.id.toString(),
        align: 'right' as const,
        sorter: sorter(keyfigure?.id.toString()),
        onCell: onCell(keyfigure?.id.toString()),
        render
      }))

    return columns.concat(keyfigureColumns).concat(formulaColumns)
  }

  return (
    <div ref={tableElement}>
      <Table
        tableLayout="fixed"
        loading={loading || loadingDimensionData || formSaveLoading}
        className="table-darken-levels report-table"
        rowKey={record => `${record.dimensionId ? 'dimension' : 'company'}-${record.id?.toString()}`}
        size="middle"
        columns={generateColumns()}
        dataSource={tableData}
        pagination={false}
        scroll={{ x: 'max-content' }}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: keys => setExpandedRowKeys(keys as string[]),
          expandIcon: ({ record, expanded, onExpand }) => {
            const { children, dimensionId } = record
            if (children && children.length === 0) return null
            if (dimensionId && !children) return null

            return expanded ? (
              <button
                className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
                aria-label="Collapse row"
                type="button"
                onClick={e => onExpand(record, e)}
              />
            ) : (
              <button
                className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
                aria-label="Collapse row"
                type="button"
                onClick={e => onExpand(record, e)}
              />
            )
          },
          onExpand: (exp, record) => {
            exp && handleTableExpand(record)
          }
        }}
      />
    </div>
  )
}

export default MultiPurposeDashboardTable
