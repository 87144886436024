import React, { useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Button, Input, Popconfirm, Select, Space, Table } from 'antd'
import { DeleteOutlined, EditOutlined, OrderedListOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { VariableType } from '../../../components/Table/types'
import { dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import { Investment, InvestmentSummaryInvestment } from '../../../types/investment/Investment'
import { formatValueByType, propertyFilterProps, propertySorterProps } from '../../../utils/helpers'
import { useColumnSearchProps } from '../../../components/Table/useColumSearchProps'
import InvestmentModal from './InvestmentModal'
import { useDeleteInvestment } from '../hooks/useDeleteInvestment'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { useFiscalYearToDateLabel } from '../hooks/hooks'
import { useColumnRangePicker } from '../../../components/Table/useColumnRangePicker'
import DepreciationModal from './InvestmenDeprecationModal'
import CustomHeaderCell from '../../../components/Table/CustomHeaderCell'
import EditableRowTable from '../../../components/Table/EditableRowTable'
import investmentQueryKeys from '../queries/investmentQueryKeys'
import { transformFieldData } from '../../../utils/form'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import DimensionSelect from '../../../components/Dimension/DimensionSelect'
import AccountSelect from '../../account/components/AccountSelect'
import { depreciableBalanceSheetRowIdsSelector } from '../../../redux/entities/statementRows/selectors'
import {
  acceleratedDeprecationDeltaRowIdsSelector,
  acceleratedDeprecationRowIdSelector,
  plannedDeprecationRowIdSelector
} from '../../../redux/config/financialStatement/selectors'
import { useUpdateInvestment } from '../hooks/useUpdateInvestment'

interface InvestmentTableProps {
  investments: InvestmentSummaryInvestment[]
  isExisting: boolean
}

const transform = {
  date: (val: string) => dayjs(val),
  startDate: (val: string) => dayjs(val)
}

const InvestmentTable: React.FC<InvestmentTableProps> = ({ investments, isExisting }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteInvestment } = useDeleteInvestment(isExisting)
  const queryClient = useQueryClient()
  const updateInvestment = useUpdateInvestment(isExisting)
  const [investment, setInvestment] = useState<InvestmentSummaryInvestment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [depreciationModalVisible, setDepreciationModalVisible] = useState<boolean>(false)
  const { date } = useSelector(investmentsPageSelector)
  const [showTaxDepreciation, setShowTaxDepreciation] = useState<boolean>(false)
  const dimensions = useSelector(dimensionListSelector)
  const fiscalYearToDateLabel = useFiscalYearToDateLabel()
  const depreciableBalanceSheetRowIds = useSelector(depreciableBalanceSheetRowIdsSelector)
  const plannedDeprecationRowId = useSelector(plannedDeprecationRowIdSelector)
  const acceleratedDeprecationRowId = useSelector(acceleratedDeprecationRowIdSelector)
  const acceleratedDeprecationDeltaRowIds = useSelector(acceleratedDeprecationDeltaRowIdsSelector)

  const investmentData = useMemo(
    () =>
      investments
        .slice()
        .sort((a, b) => a.id - b.id)
        .map(l => {
          return {
            ...transformFieldData(l, transform)
          }
        }),
    [investments]
  )

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const taxDeprecationFields = () => {
    if (!showTaxDepreciation) {
      return []
    }
    const taxDepreciationColumns: any = [
      {
        title: t('investmentsPage:taxDeprecationPlanStartDate'),
        dataIndex: ['taxDeprecationPlan', 'startDate'],
        key: 'taxDeprecationPlan-startDate',
        width: 80,
        align: 'right',
        editable: true,
        type: 'date',
        render: (value: Dayjs) => {
          if (!value) return null
          return dayjs(value).format('DD.MM.YYYY')
        }
      },
      {
        title: t('investmentsPage:taxDeprecationPlanDeprecationMethodId'),
        dataIndex: ['taxDeprecationPlan', 'deprecationMethodId'],
        key: 'taxDeprecationPlan-deprecationMethodId',
        width: 100,
        editable: true,
        type: 'custom',
        inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
          <Select allowClear ref={ref} {...props} showSearch optionFilterProp="children">
            <Select.Option value={1}>{t('depreciationMethod:1')}</Select.Option>
            <Select.Option value={2}>{t('depreciationMethod:2')}</Select.Option>
          </Select>
        )),
        render: (val: number) => val && t(`depreciationMethod:${val}`)
      },
      {
        title: t('investmentsPage:percentageOrYear'),
        dataIndex: ['taxDeprecationPlan', 'value'],
        key: 'taxDeprecationPlan-value',
        width: 80,
        editable: true,
        type: 'custom',
        inputField: React.forwardRef((props: any, ref: React.Ref<any>) => {
          return (
            <FormattedInputNumber
              block
              percentage={props.record?.taxDeprecationPlan?.deprecationMethodId === 2}
              ref={ref}
              {...props}
            />
          )
        }),
        render: (val: number, record: Investment) => {
          if (record?.taxDeprecationPlan?.deprecationMethodId === 2)
            return formatValueByType(val, VariableType.percentage, { maximumFractionDigits: 4 })
          return formatValueByType(val, VariableType.absolute, { maximumFractionDigits: 4 })
        }
      },
      {
        title: t('investmentsPage:taxDeprecationPlanPlAccountCode'),
        dataIndex: ['taxDeprecationPlan', 'plAccountCode'],
        key: 'taxDeprecationPlan-plAccountCode',
        width: 80,
        editable: true,
        align: 'right' as const,
        type: 'custom',
        inputField: React.forwardRef((props: any, ref: React.Ref<any>) => {
          return <AccountSelect ref={ref} {...props} allowClear />
        }),
        inputFieldProps: { balanceSheetRowIds: acceleratedDeprecationDeltaRowIds }
      },
      {
        title: t('investmentsPage:taxDeprecationPlanBsAccountCode'),
        dataIndex: ['taxDeprecationPlan', 'bsAccountCode'],
        key: 'taxDeprecationPlan-bsAccountCode',
        width: 80,
        editable: true,
        align: 'right' as const,
        type: 'custom',
        inputField: React.forwardRef((props: any, ref: React.Ref<any>) => {
          return <AccountSelect ref={ref} {...props} allowClear />
        }),
        inputFieldProps: { balanceSheetRowIds: [acceleratedDeprecationRowId] }
      },
      {
        title: t('investmentsPage:depreciationSelectedDate', { date: dayjs(date).format('MM/YYYY') }),
        dataIndex: ['summary', 'taxDeprecation'],
        key: 'summary-taxDeprecation',
        width: 60,
        render: (value: number) => formatValueByType(value, VariableType.absolute)
      },
      {
        title: t('investmentsPage:depreciationSelectedDate', { date: fiscalYearToDateLabel }),
        dataIndex: ['summary', 'taxFiscalYearDeprecation'],
        key: 'summary-taxFiscalYearDeprecation',
        width: 60,
        render: (value: number) => formatValueByType(value, VariableType.absolute)
      },
      {
        title: t('investmentsPage:residualDate', { date: dayjs(date).format('MM/YYYY') }),
        dataIndex: ['summary', 'taxResidual'],
        key: 'summary-taxResidual',
        width: 80,
        render: (value: number) => formatValueByType(value, VariableType.absolute)
      }
    ]
    return taxDepreciationColumns
  }

  const columns: any = [
    {
      title: t('investmentsPage:nameOfAsset'),
      dataIndex: 'name',
      key: 'name',
      align: 'right' as const,
      editable: true,
      fixed: 'left' as const,
      width: 115,
      sorter: (a: Investment, b: Investment) => a?.name?.localeCompare(b?.name || '') || 0,
      ...useColumnSearchProps('name'),
      inputField: Input
    },
    {
      title: t('investmentsPage:purchasePrice'),
      dataIndex: 'value',
      align: 'right',
      editable: true,
      type: 'number',
      fixed: 'left',
      width: 110,
      sorter: { compare: (a: Investment, b: Investment) => (a?.value || 0) - (b?.value || 0) },
      ...useColumnSearchProps('value'),
      render: (val: number) => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:acquisitioDate'),
      dataIndex: 'date',
      key: 'date',
      width: 110,
      align: 'right',
      editable: true,
      type: 'date',
      sorter: {
        compare: (a: Investment, b: Investment) => dayjs(a.date).diff(b.date)
      },
      ...useColumnRangePicker(['date']),
      render: (value: Dayjs) => value.format('DD.MM.YYYY')
    },
    {
      title: t('investmentsPage:accountCodeInAccounting'),
      dataIndex: 'accountCode',
      key: 'accountCode',
      width: 125,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      sorter: propertySorterProps(['accountCode']),
      ...propertyFilterProps(investments, ['accountCode']),
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: depreciableBalanceSheetRowIds }
    },
    {
      title: t('investmentsPage:deprecationPlanStartDate'),
      dataIndex: ['deprecationPlan', 'startDate'],
      key: 'deprecationPlan-startDate',
      editable: true,
      type: 'date',
      width: 115,
      align: 'right',
      sorter: propertySorterProps(['deprecationPlan', 'startDate']),
      ...useColumnRangePicker(['deprecationPlan', 'startDate']),
      render: (value: Dayjs) => value?.format('DD.MM.YYYY')
    },
    {
      title: t('investmentsPage:deprecationPlanDeprecationMethodId'),
      dataIndex: ['deprecationPlan', 'deprecationMethodId'],
      key: 'deprecationPlan-deprecationMethodId',
      width: 125,
      align: 'right',
      editable: true,
      type: 'custom',
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
        <Select ref={ref} {...props} showSearch optionFilterProp="children">
          <Select.Option value={1}>{t('depreciationMethod:1')}</Select.Option>
          <Select.Option value={2}>{t('depreciationMethod:2')}</Select.Option>
        </Select>
      )),
      render: (val: number) => t(`depreciationMethod:${val}`),
      sorter: propertySorterProps(['deprecationPlan', 'deprecationMethodId']),
      filters: _.uniqBy(
        investments.map(inv => ({
          text: t(`depreciationMethod:${inv.deprecationPlan?.deprecationMethodId}`),
          value: inv.deprecationPlan?.deprecationMethodId
        })),
        'text'
      ),
      onFilter: (value: number, record: Investment) => record.deprecationPlan?.deprecationMethodId === value
    },
    {
      title: t('investmentsPage:percentageOrYear'),
      dataIndex: ['deprecationPlan', 'value'],
      key: 'deprecationPlan-value',
      width: 60,
      align: 'right',
      editable: true,
      type: 'custom',
      inputField: React.forwardRef((props: any, ref: React.Ref<any>) => {
        return (
          <FormattedInputNumber
            block
            percentage={props.record?.deprecationPlan?.deprecationMethodId === 2}
            ref={ref}
            {...props}
          />
        )
      }),
      render: (val: number, record: Investment) => {
        if (record?.deprecationPlan?.deprecationMethodId === 2)
          return formatValueByType(val, VariableType.percentage, { maximumFractionDigits: 4 })
        return formatValueByType(val, VariableType.absolute, { maximumFractionDigits: 4 })
      },
      sorter: propertySorterProps(['deprecationPlan', 'value']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'value'])
    },
    {
      title: t('investmentsPage:deprecationPlanPlAccountCode'),
      dataIndex: ['deprecationPlan', 'plAccountCode'],
      key: 'deprecationPlan-plAccountCode',
      width: 145,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      sorter: propertySorterProps(['deprecationPlan', 'plAccountCode']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'plAccountCode']),
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: plannedDeprecationRowId }
    },
    {
      title: t('investmentsPage:deprecationPlanBsAccountCode'),
      dataIndex: ['deprecationPlan', 'bsAccountCode'],
      key: 'deprecationPlan-bsAccountCode',
      width: 145,
      editable: true,
      align: 'right' as const,
      type: 'custom',
      sorter: propertySorterProps(['deprecationPlan', 'bsAccountCode']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'bsAccountCode']),
      inputField: AccountSelect,
      inputFieldProps: { balanceSheetRowIds: depreciableBalanceSheetRowIds }
    },
    {
      title: t('investmentsPage:dimensionId'),
      dataIndex: ['deprecationPlan', 'dimensionId'],
      key: 'deprecationPlan-dimensionId',
      width: 110,
      align: 'right',
      editable: true,
      type: 'custom',
      inputField: DimensionSelect,
      render: (val: string) => {
        const di = dimensions?.find(d => d.dimensionId === val)
        return di?.name
      },
      sorter: propertySorterProps(['deprecationPlan', 'dimensionId']),
      filters: _.uniqBy(
        investments.map(inv => ({
          text: dimensions?.find(d => d.dimensionId === inv.deprecationPlan?.dimensionId)?.name,
          value: inv.deprecationPlan?.dimensionId
        })),
        'text'
      ),
      onFilter: (value: string, record: Investment) => record.deprecationPlan?.dimensionId === value
    },
    {
      title: t('investmentsPage:balanceSheetRow'),
      dataIndex: 'balanceSheetRowId',
      key: 'balanceSheetRowId',
      width: 105,
      align: 'right',
      render: (val: number) => t(`balanceSheet:${val}`)
    },
    {
      title: t('investmentsPage:depreciationSelectedDate', { date: dayjs(date).format('MM/YYYY') }),
      dataIndex: ['summary', 'deprecation'],
      key: 'summary-deprecation',
      width: 60,
      align: 'right',
      render: (val: number) => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:depreciationSelectedDate', { date: fiscalYearToDateLabel }),
      dataIndex: ['summary', 'fiscalYearDeprecation'],
      key: 'summary-fiscalYearDeprecation',
      width: 60,
      align: 'right',
      render: (val: number) => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:residualDate', { date: dayjs(date).format('MM/YYYY') }),
      dataIndex: ['summary', 'budgetResidual'],
      key: 'summary-budgetResidual',
      width: 80,
      align: 'right',
      render: (value: number) => formatValueByType(value, VariableType.absolute)
    },
    ...taxDeprecationFields(),
    {
      title: t('investmentsPage:depreciations'),
      dataIndex: 'depreciations',
      key: 'depreciations',
      width: 65,
      align: 'left',
      fixed: 'right',
      render: (value: number, record: InvestmentSummaryInvestment) => (
        <Button
          block
          size="small"
          style={{ marginRight: '8px' }}
          icon={<OrderedListOutlined />}
          onClick={() => {
            setInvestment(record)
            setDepreciationModalVisible(true)
          }}
        />
      )
    },
    {
      title: t('global:actions'),
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      align: 'left',
      width: 80,
      render: (value: number, record: InvestmentSummaryInvestment) => (
        <Space>
          <Button
            size="small"
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setInvestment(record)
              setModalVisible(true)
            }}
          />
          <Popconfirm
            placement="topRight"
            title={t('global:delete-confirm')}
            onConfirm={() => record && deleteInvestment(record)}
          >
            <Button danger size="small" type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const handleRowDelete = useMemo(() => {
    return async (deleteInvestmentData: Investment) => {
      await deleteInvestment(deleteInvestmentData, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: investmentQueryKeys.summaries()
          })
        }
      })
    }
  }, [])

  const deprecationHandler = (deprecationPlanData: Investment, dataIndex: 'deprecationPlan' | 'taxDeprecationPlan') => {
    let currentDeprecationPlanValue = deprecationPlanData?.[dataIndex]?.value || 0
    const currentDeprecationPlan = deprecationPlanData?.[dataIndex]?.deprecationMethodId
    const previousDeprecationPlan = investments.find(i => i.id === deprecationPlanData.id)?.[dataIndex]
      ?.deprecationMethodId

    if (previousDeprecationPlan === 1 && currentDeprecationPlan === 2) {
      currentDeprecationPlanValue /= 100
    }
    if (previousDeprecationPlan === 2 && currentDeprecationPlan === 1) {
      currentDeprecationPlanValue *= 100
    }
    if (!previousDeprecationPlan && currentDeprecationPlan === 2) {
      currentDeprecationPlanValue /= 100
    }
    return currentDeprecationPlanValue
  }

  const handleSubmit = (saveInvestmentData: Investment) => {
    const saveData = saveInvestmentData
    let { taxDeprecationPlan } = saveData

    // If tax depreciation is not shown, get the existing plan from investments
    if (!showTaxDepreciation) {
      taxDeprecationPlan = investments.find(i => i.id === saveData.id)?.taxDeprecationPlan
    }

    // Check if the tax depreciation plan data is not empty
    if (taxDeprecationPlan && JSON.stringify(taxDeprecationPlan) !== '{}' && taxDeprecationPlan.startDate) {
      const updatedTaxDeprecationValue = deprecationHandler({ ...saveData, taxDeprecationPlan }, 'taxDeprecationPlan')

      taxDeprecationPlan = {
        ...taxDeprecationPlan,
        startDate: dayjs(taxDeprecationPlan.startDate).format('YYYY-MM-DD'),
        value: updatedTaxDeprecationValue
      }
    } else {
      // Remove taxDeprecationPlan if it is empty
      delete saveData.taxDeprecationPlan
    }

    // Calculate the current deprecation plan value
    const updatedDeprecationValue = deprecationHandler(saveData, 'deprecationPlan')

    // Prepare the final values for submission
    const formattedValues = {
      ...saveData,
      date: dayjs(saveData.date).format('YYYY-MM-DD'),
      deprecationPlan: {
        ...saveData.deprecationPlan,
        startDate: dayjs(saveData.deprecationPlan.startDate).format('YYYY-MM-DD'),
        value: updatedDeprecationValue
      },
      ...(taxDeprecationPlan &&
        JSON.stringify(taxDeprecationPlan) !== '{}' &&
        taxDeprecationPlan.startDate && {
          taxDeprecationPlan
        })
    }

    // Submit the updated investment data
    updateInvestment.mutate(formattedValues)
  }

  return (
    <>
      {investment && (
        <DepreciationModal
          investmentId={investment.id}
          visible={depreciationModalVisible}
          handleOk={() => setDepreciationModalVisible(false)}
        />
      )}
      {modalVisible && investment && (
        <InvestmentModal
          onlyDepreciable
          visible={modalVisible}
          sourceDisabled={false}
          isExisting={isExisting}
          investment={investment}
          onFinish={toggleModal}
        />
      )}
      <Button style={{ float: 'right' }} onClick={() => setShowTaxDepreciation(!showTaxDepreciation)}>
        {showTaxDepreciation ? t('investmentsPage:hideTaxDepreciation') : t('investmentsPage:showTaxDepreciation')}
      </Button>
      {isExisting ? (
        <EditableRowTable<Investment>
          scroll={{ x: 1900 + 150 }}
          size="small"
          rowKey="id"
          bordered
          pagination={false}
          dataSource={investmentData}
          columns={columns.filter((c: any) => {
            return c.dataIndex !== 'actions'
          })}
          handleSave={handleSubmit}
          handleRowDelete={handleRowDelete}
          components={{
            header: {
              cell: CustomHeaderCell
            }
          }}
        />
      ) : (
        <Table<InvestmentSummaryInvestment>
          scroll={{ x: 1900, y: 700 }}
          size="small"
          rowKey="id"
          bordered
          pagination={false}
          dataSource={investmentData}
          columns={columns}
          components={{
            header: {
              cell: CustomHeaderCell
            }
          }}
        />
      )}
    </>
  )
}

export default InvestmentTable
