import { Select, Col, Row } from 'antd'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { FormattedInputNumber } from '../../../../../../components/Misc/FormattedInputNumber'
import { useFetchAccounts } from '../../../../../../features/account/queries/useFetchAccounts'
import { companyAccountsByStatementRowIdSelector } from '../../../../../../features/account/selectors/selectors'
import { Account } from '../../../../../../features/account/types/Account'

interface SocialSecurityValue {
  account?: string
  factor?: number | null
  sourceAccounts?: string[]
}

interface SocialSecurityInputProps {
  value?: SocialSecurityValue
  onChange?: (value: SocialSecurityValue) => void
  accountBalanceSheetRow: number
  sourceAccountBalanceSheetRow: number
  factor?: {
    minValue: number
  }
}

const { Option } = Select

const SocialSecurityInput: React.FC<SocialSecurityInputProps> = ({
  accountBalanceSheetRow,
  sourceAccountBalanceSheetRow,
  value = {},
  factor: { minValue = 0 } = {},
  onChange
}) => {
  const { t } = useTranslation()
  const { data: accountsByStatementRowId } = useFetchAccounts(companyAccountsByStatementRowIdSelector)

  const [account, setAccount] = useState<string>()
  const [factor, setFactor] = useState<number | null>(0)
  const [sourceAccounts, setSourceAccounts] = useState<string[]>()

  const triggerChange = (changedValue: { account?: string; factor?: number | null; sourceAccounts?: string[] }) => {
    onChange?.({ account, factor, sourceAccounts, ...value, ...changedValue })
  }

  const onAccountsChange = (newAccount: string) => {
    if (!('account' in value)) {
      setAccount(newAccount)
    }
    triggerChange({ account: newAccount })
  }

  const onFactorChange = (newFactor: number | null) => {
    if (!('factor' in value)) {
      setFactor(newFactor)
    }
    triggerChange({ factor: newFactor })
  }

  const onSourceAccountsChange = (newSourceAccounts: string[]) => {
    if (!('sourceAccounts' in value)) {
      setSourceAccounts(newSourceAccounts)
    }
    triggerChange({ sourceAccounts: newSourceAccounts })
  }

  const getFieldSpan = (field: string) => {
    if (isMobile && window.innerWidth < 420) return 24
    return field === 'factor' ? 4 : 10
  }

  return (
    <Row justify="space-between" gutter={[24, 8]}>
      <Col span={getFieldSpan('account')}>
        <Select
          value={value.account || account}
          showSearch
          optionFilterProp="children"
          onChange={onAccountsChange}
          placeholder={t('budgetingInputPage:target-account')}
          allowClear
        >
          {(accountsByStatementRowId?.[accountBalanceSheetRow] || []).map(({ code, name }: Account) => {
            return (
              <Option key={code} value={code}>
                {`${code} - ${name}`}
              </Option>
            )
          })}
        </Select>
      </Col>
      <Col span={getFieldSpan('factor')}>
        <FormattedInputNumber
          percentage
          value={value.factor || factor}
          onChange={val => onFactorChange(val as number)}
          style={{ textAlign: 'right', width: '100%' }}
          min={minValue}
          max={100}
          addonAfter="%"
          step={1}
          required={false}
        />
      </Col>
      <Col span={getFieldSpan('sourceAccounts')}>
        <Select
          value={value.sourceAccounts || sourceAccounts}
          onChange={onSourceAccountsChange}
          showSearch
          optionFilterProp="children"
          mode="multiple"
          placeholder={t('budgetingInputPage:source-accounts')}
          allowClear
        >
          {(accountsByStatementRowId?.[sourceAccountBalanceSheetRow] || []).map(({ code, name }: Account) => {
            return (
              <Option key={code} value={code}>
                {`${code} - ${name}`}
              </Option>
            )
          })}
        </Select>
      </Col>
    </Row>
  )
}

export default SocialSecurityInput
