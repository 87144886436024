import _ from 'lodash'
import { createSelector } from 'reselect'
import { AccountMapRule } from '../../../types/accountMapRule/AccountMapRule'
import { Store } from '../../types'

const contextAccountMapRulesSelector = (store: Store): AccountMapRule[] => store.context.accountMapRules || []

export const contextAccountMapRulesLoadingSelector = (store: Store): boolean => store.loading.contextAccountMapRules

export const contextAccountMapRulesGroupedByStatementRowIdSelector = createSelector(
  [contextAccountMapRulesSelector],
  contextAccountMapRules => {
    return _.groupBy(contextAccountMapRules, 'statementRowId')
  }
)
