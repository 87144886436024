import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Dropdown, MenuProps, Space, Spin } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DownloadOutlined, FileExcelOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import LoanGroup from '../../../features/loan/components/LoanGroup'
import LoanModal from '../../../features/loan/components/LoanModal'
import { setInvestmentPageDateAction } from '../../../redux/pages/investments/actions'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { notLocked } from '../../settings/company/budgeting/budgetingScenarios/utils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import { AppDispatch } from '../../../redux/store'
import { useLoanSummary } from '../../../features/loan/queries/useLoanSummary'
import InvestmentModal from '../../../features/investment/components/InvestmentModal'
import { useLoanExport } from '../../../features/loan/queries/useLoanExport'
import { Loading } from '../../../components/Misc/Loading'
import LoanSummaryStats from '../../../features/loan/components/LoanSummary'
import { useLoanTemplate } from '../../../features/loan/queries/useLoanTemplate'
import LoanImport from '../../../features/loan/components/LoanImport'
import { Investment } from '../../../types/investment/Investment'

interface InvestmentPageProps {
  isExisting: boolean
}

const LoanPage: React.FC<InvestmentPageProps> = ({ isExisting }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [investment, setInvestment] = useState<Investment>()
  const { data: loanReport, isLoading, isFetching } = useLoanSummary(isExisting)
  const { download: downloadReport, isLoading: isDownLoadingReport } = useLoanExport(isExisting)
  const { download: downloadTemplate, isLoading: isDownLoadingTemplate } = useLoanTemplate()

  const toggleModal = (data?: Investment) => {
    setInvestment(data)
    setModalVisible(!modalVisible)
  }

  const tabList = [
    {
      key: 'existing',
      label: t('menu:/budgeting/loans/existing')
    },
    {
      key: 'new',
      label: t('menu:/budgeting/loans/new')
    }
  ]

  const items: MenuProps['items'] = [
    {
      key: 'export',
      label: t('loans:export'),
      icon: <FileExcelOutlined />,
      onClick: downloadReport
    }
  ]

  if (isExisting) {
    items.unshift(
      {
        key: 'template',
        label: t('loans:template'),
        icon: <DownloadOutlined />,
        onClick: downloadTemplate
      },
      {
        key: 'import',
        label: <LoanImport />,
        icon: <UploadOutlined />
      }
    )
  }

  if (isLoading) return <Loading />

  return (
    <LoadingWrapper loading={isFetching}>
      {isExisting && <LoanModal visible={modalVisible} onFinish={toggleModal} />}
      {!isExisting && (
        <InvestmentModal
          investment={investment}
          sourceDisabled={false}
          isExisting={isExisting}
          visible={modalVisible}
          onFinish={toggleModal}
          formType="investment"
        />
      )}

      <PageHeaderWrapper
        fixedHeader
        tabActiveKey={isExisting ? 'existing' : 'new'}
        tabList={tabList}
        onTabChange={(key: string) => {
          navigate(`/budgeting/loans/${key}`)
        }}
        title={t('loans:loans')}
        subTitle={t(`menu:${pathname}`)}
        ghost={false}
        extra={[
          <DatePicker
            value={date ? dayjs(date) : dayjs()}
            format="MM/YYYY"
            picker="month"
            key="month"
            onChange={val => dispatch(setInvestmentPageDateAction(val?.toString()))}
          />,
          <Dropdown.Button
            icon={isDownLoadingReport || isDownLoadingTemplate ? <Spin /> : <MoreOutlined rotate={90} />}
            key="add-new"
            disabled={isExisting && !notLocked(budgetingScenario)}
            menu={{ items }}
            onClick={() => toggleModal()}
          >
            <PlusOutlined />
            {t('global:add-new')}
          </Dropdown.Button>
        ]}
        content={<LoanSummaryStats isExisting={isExisting} summary={loanReport?.summary} />}
      >
        <Space style={{ width: '100%' }} size="large" direction="vertical">
          {loanReport?.balanceSheetRows?.map(balanceSheetRow => {
            return (
              balanceSheetRow.loans.length > 0 && (
                <LoanGroup
                  toggleModal={toggleModal}
                  balanceSheetRowId={balanceSheetRow.balanceSheetRowId}
                  key={balanceSheetRow.balanceSheetRowId}
                  loansByBalansheetRow={balanceSheetRow}
                  isExisting={isExisting}
                />
              )
            )
          })}
        </Space>
      </PageHeaderWrapper>
    </LoadingWrapper>
  )
}

export default LoanPage
