import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { updateScenarioDashbordsOrder } from '../service/dashboardService'
import { useScenarioDashboardsOptions } from './useScenarioDashboards'
import { MultiDashboard } from '../types/MultiDashboard'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { Orderable } from '../../../types/orderable/Orderable'

export default function useUpdateScenarioDashboardsOrder() {
  const { companyId } = useContextParameters()
  const queryClient = useQueryClient()
  const options = useScenarioDashboardsOptions()
  const { notification } = App.useApp()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (order: Orderable[]) => updateScenarioDashbordsOrder({ data: order, companyId }),
    mutationKey: ['scenarios'],
    retry: 2,
    onSuccess: updatedOrder => {
      notification.success({ message: t('message:UPDATE_CUSTOM_REPORT_ORDER_SUCCESS') })

      queryClient.setQueryData(options.queryKey, oldItems => {
        return oldItems
          ?.map(item => {
            const { order } = updatedOrder.find(({ id }) => item.id === id) || {}
            return { ...item, order: order ?? item.order }
          })
          .filter(Boolean) as MultiDashboard[]
      })
    }
  })
}
