import React from 'react'
import { Outlet } from 'react-router-dom'
import i18next from 'i18next'
import Highcharts from 'highcharts'
import { useHighchartTheme } from './config/highcharts/Theme'
import 'normalize.css'
import './App.sass'

const App: React.FC = () => {
  Highcharts.setOptions(useHighchartTheme())
  i18next.loadLanguages(['en', 'sv', 'fi'])

  return <Outlet />
}

export default App
