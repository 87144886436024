import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReportTableRow } from '../../../../../components/Table/types'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { budgetGroupSettingsSourceSelector } from '../../../../../redux/context/groupSettings/selectors'
import { Investment as InvestmentType } from '../../../../../types/investment/Investment'
import { GroupSettingDataSource } from '../../../../settings/company/group/types'
import InvestmentModal from '../../../../../features/investment/components/InvestmentModal'
import { useDeleteInvestment } from '../../../../../features/investment/hooks/useDeleteInvestment'
import { getInvestment } from '../../../../../features/investment/service/investmentService'
import { contextRequest } from '../../../../../redux/context/actions'
import { getFinancialStatementRequest } from '../../../../../redux/context/reports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'

interface InvestmentProps {
  reportTableRow: ReportTableRow
}

const InvestmentNameCellRender: React.FC<InvestmentProps> = (props: InvestmentProps) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const [investment, setInvestment] = useState<InvestmentType>()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { mutateAsync: deleteInvestment } = useDeleteInvestment(false)
  const { budgetingScenario } = useSelector(filtersSelector)
  const { reportTableRow } = props
  const groupBudgetSourceSetting = useSelector(budgetGroupSettingsSourceSelector)

  const handleInvestmentReq = async (investmentId: number) => {
    const investmentData = await getInvestment(companyId, investmentId, budgetingScenario?.id)
    setInvestment(investmentData)
    setModalVisible(true)
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
    dispatch(contextRequest(getFinancialStatementRequest))
  }

  const handleDelete = async () => {
    if (reportTableRow.id) {
      await deleteInvestment({ id: reportTableRow.id } as InvestmentType)
      dispatch(contextRequest(getFinancialStatementRequest))
    }
  }

  return (
    <>
      {modalVisible && (
        <InvestmentModal
          sourceDisabled
          visible={modalVisible}
          isExisting={false}
          onFinish={toggleModal}
          investment={investment}
        />
      )}
      <span>{reportTableRow.name}</span>
      <span style={{ float: 'right' }}>
        <Button
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          icon={<EditOutlined />}
          type="link"
          size="small"
          onClick={() => handleInvestmentReq(reportTableRow.id)}
        />
        <Divider type="vertical" />
        <Popconfirm
          disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
          title={t('global:delete-confirm')}
          onConfirm={() => handleDelete()}
          okText={t('global:yes')}
          cancelText={t('global:no')}
        >
          <Button
            disabled={groupBudgetSourceSetting === GroupSettingDataSource.subsidiaries}
            icon={<DeleteOutlined />}
            type="link"
            size="small"
          />
        </Popconfirm>
      </span>
    </>
  )
}

export default React.memo(InvestmentNameCellRender)
