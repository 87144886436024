/* eslint-disable react/destructuring-assignment */
import React, { useContext, useMemo } from 'react'
import { HolderOutlined } from '@ant-design/icons'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button, Table } from 'antd'
import type { TableColumnsType, TableProps } from 'antd'
import { Orderable } from '../../types/orderable/Orderable'

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext)
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

const dragColumn = { key: 'sort', align: 'center' as const, width: 60, render: () => <DragHandle /> }
const defaultColumns: TableColumnsType = [{ title: 'Name', dataIndex: 'name' }]

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row: React.FC<RowProps> = props => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  }

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  )

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}

interface OrderTableProps<T extends Orderable> extends Omit<TableProps, 'onChange' | 'rowKey'> {
  data?: T[]
  columns?: TableColumnsType<T>
  onChange?: (newOrder: { id: number | string; order: number }[]) => void
}

const OrderTable = <T extends Orderable>({ data, columns, onChange, ...tableProps }: OrderTableProps<T>) => {
  const [dataSource, setDataSource] = React.useState<T[]>(data || [])
  const tableColumns = useMemo(() => [dragColumn, ...(columns || defaultColumns)], [columns])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource(prevState => {
        const activeIndex = prevState.findIndex(record => record.id.toString() === active?.id)
        const overIndex = prevState.findIndex(record => record.id.toString() === over?.id)
        const newOrder = arrayMove(prevState, activeIndex, overIndex)

        onChange?.(newOrder.map(({ id }, index) => ({ id, order: index })))
        return newOrder
      })
    }
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource.map(i => i.id.toString())} strategy={verticalListSortingStrategy}>
        <Table<T>
          rowKey={({ id }) => `${id}`}
          components={{ body: { row: Row } }}
          columns={tableColumns}
          dataSource={dataSource}
          pagination={false}
          {...tableProps}
        />
      </SortableContext>
    </DndContext>
  )
}

export default OrderTable
