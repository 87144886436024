import { queryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { LoanSummaryReport } from '../types/LoanSummary'
import loanQueryKeys from './loanQueryKeys'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { fetchLoanSummary } from '../service/loanService'

export const useLoanSummaryOptions = <TData = LoanSummaryReport>(
  isExisting: boolean,
  select?: (data: LoanSummaryReport) => TData
) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)

  return queryOptions({
    queryKey: loanQueryKeys.summary({
      isExisting,
      companyId,
      date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
      budgetingScenarioId: budgetingScenario?.id
    }),
    queryFn: fetchLoanSummary,
    select
  })
}
