import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { fiscalYearsSelector } from '../../../redux/context/fiscalYears/selectors'
import { periodGroupLabel } from '../../../redux/context/fiscalYears/utils'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { customPeriodGroup } from '../../../utils/periodGroups/utils'

export const useFiscalYearToDateLabel = () => {
  const { date } = useSelector(investmentsPageSelector)
  const fiscalYears = useSelector(fiscalYearsSelector)
  return periodGroupLabel(
    customPeriodGroup(
      dayjs(
        fiscalYears.find(f => f.year === dayjs(date).year())?.startDate ||
          dayjs(fiscalYears.find(f => f.year === dayjs().year())?.startDate).set('year', dayjs(date).year())
      ),
      dayjs(date)
    )
  )
}
