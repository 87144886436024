import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useLoggedin } from '../../redux/session/currentUser/hooks'

const ProtectedRoute: React.FC = () => {
  const location = useLocation()
  const isLoggedIn = useLoggedin()

  if (!isLoggedIn) {
    return <Navigate to="/user/login" state={{ from: location.pathname }} />
  }

  return <Outlet />
}

export default ProtectedRoute
