import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { getExchangeRatesRequest } from '../../../../../redux/context/exchangeRates/actions'
import { getSubsidiariesRequest } from '../../../../../redux/context/subsidiaries/actions'

export const reportNames = {
  bs: 'BALANCE_SHEET',
  pl: 'INCOME_STATEMENT'
}

const ExchangeRate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const groupId = useSelector(contextCompanyIdSelector)!

  useEffect(() => {
    dispatch(getExchangeRatesRequest(groupId))
    dispatch(getSubsidiariesRequest(groupId))
    if (location.pathname.endsWith('edit')) {
      navigate(-1)
    }
  }, [groupId])

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default ExchangeRate
