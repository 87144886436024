import { Form, Select, Space } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormulaInput from '../../../../../components/inputs/FormulaInput'
import KeyFigureInput from '../../../../../components/inputs/KeyFigureInput'

export enum VariableSourceType {
  keyFigureId = 'keyFigureId',
  formulaId = 'formulaId'
}

const CustomReportVariableFormMultiple: React.FC = () => {
  const { t } = useTranslation()
  const [varibleSourceType, setVaribleSourceType] = useState<VariableSourceType>(VariableSourceType.keyFigureId)

  const variableSourceInputMap = {
    [VariableSourceType.keyFigureId]: (
      <>
        <Form.Item
          key={VariableSourceType.keyFigureId}
          name="keyFigureId"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:keyFigure')}
        >
          <KeyFigureInput mode="multiple" />
        </Form.Item>
      </>
    ),
    [VariableSourceType.formulaId]: (
      <>
        <Form.Item
          key={VariableSourceType.formulaId}
          name="formulaId"
          rules={[{ required: true, message: t('global:required-field') }]}
          label={t('global:formula')}
        >
          <FormulaInput mode="multiple" />
        </Form.Item>
      </>
    )
  }

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 32 }}>
      <Form.Item key="type" label={t('global:type')} shouldUpdate>
        <Select
          onChange={e => {
            return setVaribleSourceType(e)
          }}
          value={varibleSourceType}
          options={Object.values(VariableSourceType).map(srcType => ({
            label: t(`customReportPage:${srcType}`),
            value: srcType
          }))}
        />
      </Form.Item>
      {variableSourceInputMap[varibleSourceType]}
    </Space>
  )
}

export default CustomReportVariableFormMultiple
