import React from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'
import EditableCellTable, {
  EditableCellTableDataIndex,
  EditableColumnProps
} from '../../../components/Table/EditableCellTable'
import { VariableType } from '../../../components/Table/types'
import { formatValueByType } from '../../../utils/helpers'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { useCreateInvestmentDepreciation } from '../hooks/useCreateInvestmentDepreciation'
import { useDepreciations } from '../hooks/useDepreciation'
import { useDeleteInvestmentDepreciation } from '../hooks/useDeleteInvestmentDepreciation'
import { useUpdateInvestmentDepreciation } from '../hooks/useUpdateInvestmentDepreciation'

interface DepreciationModalProps {
  investmentId: number
  handleOk: () => void
  visible: boolean
}
export interface DepreciationItem {
  year: number
  month: number
  value: number
  deprecationPlan: {
    id: number
    deprecation: number
    residual: number
    correction: {
      id: number
      value: number
    }
  }
  taxDeprecationPlan: {
    id: number
    deprecation: number
    residual: number
    correction: {
      id: number
      value: number
    }
  }
  acceleratedDeprecations: {
    delta: number
    deprecation: number
  }
}

export interface InvestmentCorrection {
  year: number
  month: number
  value: number
}

const DepreciationModal: React.FC<DepreciationModalProps> = ({
  investmentId,
  visible,
  handleOk
}: DepreciationModalProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { data: depreciationData, isLoading, isFetching } = useDepreciations(investmentId)
  const createInvestmentDepreciation = useCreateInvestmentDepreciation()
  const deleteInvestmentDepreciation = useDeleteInvestmentDepreciation()
  const updateInvestmentDepreciation = useUpdateInvestmentDepreciation()

  const handleOkCancelAction = () => {
    handleOk()
    queryClient.invalidateQueries({
      queryKey: [{ scope: 'investment' }]
    })
  }

  const columns: EditableColumnProps<DepreciationItem>[] = [
    {
      title: t('investmentsPage:depreciationDate'),
      dataIndex: 'date',
      align: 'right',
      width: '25%',
      render: (text: string, record: DepreciationItem) => {
        const { year, month } = record
        return dayjs({ year, month: month - 1 })
          .endOf('month')
          .format('L')
      }
    },
    {
      title: t('investmentsPage:accounting'),
      children: [
        {
          title: t('investmentsPage:deprecation'),
          dataIndex: ['deprecationPlan', 'deprecation'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:correction'),
          dataIndex: ['deprecationPlan', 'correction', 'value'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
          editable: true,
          inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
            <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
          ))
        },
        {
          title: t('investmentsPage:taxDeprecation'),
          dataIndex: ['deprecationPlan', 'residual'],
          align: 'right',
          width: '25%',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    },
    {
      title: t('investmentsPage:taxation'),
      children: [
        {
          title: t('investmentsPage:deprecation'),
          dataIndex: ['taxDeprecationPlan', 'deprecation'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:correction'),
          dataIndex: ['taxDeprecationPlan', 'correction', 'value'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
          editable: true,
          inputField: React.forwardRef((props: any, ref: React.Ref<any>) => (
            <FormattedInputNumber style={{ width: '100%' }} size="small" ref={ref} {...props} />
          ))
        },
        {
          title: t('investmentsPage:taxDeprecation'),
          dataIndex: ['taxDeprecationPlan', 'residual'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    },
    {
      title: t('investmentsPage:acceleratedDeprecation'),
      children: [
        {
          title: t('investmentsPage:change'),
          dataIndex: ['acceleratedDeprecations', 'delta'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        },
        {
          title: t('investmentsPage:acceleratedDeprecation'),
          dataIndex: ['acceleratedDeprecations', 'deprecation'],
          align: 'right',
          render: (value: any) =>
            formatValueByType(value, VariableType.absolute, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
        }
      ]
    }
  ]

  const onUpdate = async (
    formFields: DepreciationItem,
    previousRecord: DepreciationItem,
    dataIndex: EditableCellTableDataIndex
  ) => {
    type PlanKey = 'deprecationPlan' | 'taxDeprecationPlan'
    const planType = (dataIndex as PlanKey[])[0]
    const { id: correctionId } = previousRecord[planType]?.correction ?? 0
    const { id: deprecationPlanId } = previousRecord[planType] ?? 0
    const value = formFields[planType]?.correction?.value ?? 0

    // If value null or undefined DO NOTHING
    if (value == null) return

    const data: Partial<InvestmentCorrection> = {
      year: formFields.year,
      month: formFields.month,
      value
    }

    const onSuccess = () =>
      queryClient.invalidateQueries({
        queryKey: [{ scope: 'investment', entity: 'depreciations' }]
      })

    if (correctionId) {
      if (value === 0) {
        deleteInvestmentDepreciation.mutate(
          { deprecationPlanId, correctionId },
          {
            onSuccess
          }
        )
      } else {
        updateInvestmentDepreciation.mutate(
          { data, deprecationPlanId, correctionId },
          {
            onSuccess
          }
        )
      }
    } else {
      createInvestmentDepreciation.mutate(
        { data, deprecationPlanId },
        {
          onSuccess
        }
      )
    }
  }

  return (
    <Modal
      style={{ maxWidth: '1200px' }}
      title={t('investmentsPage:depreciations')}
      open={visible}
      onOk={handleOkCancelAction}
      onCancel={handleOkCancelAction}
      maskClosable={false}
      footer={
        <Button type="primary" onClick={handleOkCancelAction}>
          {t('contractsPage:close')}
        </Button>
      }
      width="unset"
    >
      <EditableCellTable<DepreciationItem>
        size="small"
        rowKey={(record: any) => `${record.year.toString()}-${record.month.toString()}`}
        dataSource={depreciationData}
        columns={depreciationData?.[0]?.taxDeprecationPlan ? columns : columns.slice(0, 2)}
        onUpdate={onUpdate}
        pagination={false}
        loading={isFetching || isLoading}
      />
    </Modal>
  )
}

export default DepreciationModal
