import i18next from 'i18next'
import { RGBColor } from 'react-color'
import _, { isArray } from 'lodash'
import { VariableType } from '../components/Table/types'
import { Country } from '../types/company/Company'
import { Entries } from './Authorizable/authorize'
import { LanguageField, TranslationFields } from '../components/Form/TranslateFields'
import { Language } from '../types/user/User'
import { Route } from '../routes/Route'

export function filterRoute(route: Route, predicate: (route: Route) => boolean): Route {
  const filterRoutes = (routes: Route[]) => {
    return (routes || []).reduce((filtered: Route[], subRoute: Route) => {
      // Recursively filter children (if any)
      const filteredSubRoutes = subRoute.routes ? filterRoutes(subRoute.routes) : ([] as Route[])

      // Check if the subRoute matches the predicate OR has any filtered sub-routes
      if (predicate(subRoute)) {
        filtered.push({
          ...subRoute,
          routes: filteredSubRoutes.length > 0 ? filteredSubRoutes : undefined // Only include sub-routes if there are any
        })
      } else {
        filtered.push(...filteredSubRoutes)
      }

      return filtered
    }, [])
  }

  return { ...route, routes: route?.routes ? filterRoutes(route?.routes) : undefined }
}

export function filterpop<T>(arr: T[], fn: (val: T) => boolean) {
  const filteredValues: T[] = []
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    if (fn(arr[i])) {
      filteredValues.push(arr.splice(i, 1)[0])
    }
  }
  return filteredValues
}

export const getHumanReadableArrayText = (arr: string[]) => {
  return arr.reduce((str: string, name: string, i: number, array: string[]) => {
    // eslint-disable-next-line no-nested-ternary
    return i < array.length - 2
      ? `${str}${name}, `
      : i < arr.length - 1
      ? `${str}${name} ${i18next.t('global:and')} `
      : `${str}${name}`
  }, '')
}

export const formatValueByType = (
  value?: string | number,
  type?: VariableType,
  options?: Intl.NumberFormatOptions
): string => {
  if (!value) return ''

  const variableOptionsMap = {
    [VariableType.absolute]: { style: 'decimal', maximumFractionDigits: 0 } as const,
    [VariableType.percentage]: { style: 'percent', maximumFractionDigits: 1 } as const,
    [VariableType.ratio]: { style: 'decimal', maximumFractionDigits: 1 } as const,
    default: { style: 'decimal', maximumFractionDigits: 0 } as const
  }

  const variableOptions = variableOptionsMap[type ?? 'default']

  const numberValue = typeof value === 'string' ? parseFloat(value) : value

  return numberValue?.toLocaleString(i18next.language, { ...variableOptions, ...options }).replace('−', '-')
}

export const formatValueToNumberByType = (
  value?: string | number,
  type?: VariableType,
  decimalPlaces?: number
): number | null => {
  if (!value) return null

  const defaultDecimalPlaces = type === VariableType.percentage || type === VariableType.ratio ? 1 : 0
  const decimals = decimalPlaces !== undefined ? decimalPlaces : defaultDecimalPlaces

  const multiplier = 10 ** decimals
  const numericValue = +value

  switch (type) {
    case VariableType.absolute:
      return Math.round(numericValue * multiplier) / multiplier
    case VariableType.percentage:
      return Math.round(numericValue * 100 * multiplier) / multiplier
    case VariableType.ratio:
      return Math.round(numericValue * multiplier) / multiplier
    default:
      return Math.round(numericValue * multiplier) / multiplier
  }
}

export const recursiveFind = <T extends { id: string | number; children?: T[] }>(
  data?: T[],
  searchValue?: number | string,
  searchProperty?: keyof T
): T | null => {
  if (!data || !searchValue) return null
  let result = null
  function iter(a: T) {
    if (a[searchProperty ?? 'id'] === searchValue) {
      result = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  }
  data.some(iter)
  return result
}

export const objectMap = <T extends {}>(obj: T, fn: (v: T[keyof T], k: keyof T, i: number) => any) =>
  Object.fromEntries((Object.entries(obj) as Entries<T>).map(([k, v], i) => [k, fn(v, k, i)]))

export const autoDownload = (fileName: string, data: BlobPart) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export const testVatId = (vatId?: string): boolean => {
  if (!vatId) return false
  const vatIdTests = {
    [Country.fi]: /([0-9]{7}-[0-9]{1})|([0-9]{8})|(FI[0-9]{8})/g,
    [Country.se]: /([0-9]{6}-[0-9]{4})|([0-9]{10})|(SE[0-9]{10})/g
  }

  const matches = Object.entries(vatIdTests).reduce((acc: { [key: string]: any[] | null }, [country, regExp]) => {
    const test = new RegExp(regExp)
    acc[country] = test.exec(vatId)
    return acc
  }, {})

  const valid = Object.values(matches).some(val => !!val)

  return valid
}

export const parseRGBA = (rgbaString?: string) => {
  // Remove any leading or trailing spaces

  // Use a regular expression to extract the RGBA values
  const rgbaRegex = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+)\s*)?\)$/
  const matches = rgbaString?.trim().match(rgbaRegex)

  if (matches) {
    const red = parseInt(matches[1], 10)
    const green = parseInt(matches[2], 10)
    const blue = parseInt(matches[3], 10)
    let alpha = 1 // Default alpha value is 1 (opaque)

    if (matches[4]) {
      alpha = parseFloat(matches[4])
    }

    return {
      red,
      green,
      blue,
      alpha
    }
  }
  // Return null or throw an error for invalid input
  return null
}

export const parseRgbString = (rgbString?: string): RGBColor => {
  if (typeof rgbString === 'string') {
    const [r, g, b, a] = (rgbString || '')
      ?.match(/\(([^)]+)\)/)?.[1]
      ?.split(',')
      .map(val => +val) || [255, 255, 255, 1]
    return { r, g, b, a }
  }
  const [r, g, b, a] = [255, 255, 255, 1]
  return { r, g, b, a }
}

export const propertySorterProps =
  <T>(key: keyof T | string[]) =>
  (a: T, b: T) => {
    if (isArray(key)) {
      const aData = key.reduce((value: any, k: string) => value[k], a)
      const bData = key.reduce((value: any, k: string) => value[k], b)
      return `${aData}`.localeCompare(`${bData}`)
    }
    return `${a[key]}`.localeCompare(`${b[key]}`)
  }

export const propertyFilterProps = <T>(data: T[], propertyPath: Array<string | keyof T>) => {
  const getPropertyValue = (obj: T, path: Array<string | keyof T>): any => {
    return path.reduce((acc: any, key: any) => {
      if (acc && typeof acc === 'object' && key in acc) {
        return acc[key as keyof typeof acc]
      }
      return null
    }, obj)
  }

  const uniqueValues = [...new Set(data.map(item => getPropertyValue(item, propertyPath)))]

  return {
    filters: uniqueValues.map(value => ({
      text: value,
      value
    })),
    onFilter: (value: boolean | React.Key, record: T) => getPropertyValue(record, propertyPath) === value
  }
}
// export const propertyFilterProps = <T>(data: T[], property: keyof T) => ({
//   filters: [...new Set(data.map(c => c[property]))].map(value => ({
//     text: value,
//     value
//   })),
//   onFilter: (value: string, record: T) => record[property] === value
// })

export const translateRecord = <T extends TranslationFields & { name: string }>(record: T, language: Language) => {
  const translateField = _.camelCase(`name-${language}`) as LanguageField
  return {
    ...record,
    name: record?.[translateField] || record.name
  }
}

/**
 * Create a range of numbers.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 * @param {number} start The first number in the range.
 * @param {number} stop The last number in the range.
 * @param {number} step The step between each number in the range.
 * @returns {number[]} A range of numbers.
 */
export function range(start: number, stop: number, step = 1) {
  return Array.from({ length: (stop - start) / step + 1 }, (__, i) => start + i * step)
}

/**
 * Splits an array into smaller chunks of a specified size.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to split into chunks.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {T[][]} - A new array where each element is a chunk of the original array.
 *
 * @example
 * // Example usage:
 * const data = [1, 2, 3, 4, 5, 6, 7];
 * const chunks = chunkArray(data, 3);
 * console.log(chunks); // [[1, 2, 3], [4, 5, 6], [7]]
 */
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}
