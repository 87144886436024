/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Space, Tree } from 'antd'
import { useTranslation } from 'react-i18next'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { CategoryNodeRender } from './CategoryNodeRender'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface CategorySelectionProps {
  categories: CustomReportCategory[]
}

interface GData {
  key: string
  title: {
    path: string
    category: CustomReportCategory
    hideActions?: boolean
    multiple?: boolean
  }
  children?: GData[]
}

const CategorySelection: React.FC<CategorySelectionProps> = ({ categories }) => {
  const { t } = useTranslation()
  const { addCompany, addRootFunction, getPathContext, onDrop } = useContext(CategoryContext) as CategoryContextType
  const [gData, setGData] = useState<GData[]>()

  const generateTreeDataNodes = (cats: CustomReportCategory[], parent?: CustomReportCategory, path?: string) => {
    const treeNodes: GData[] = []

    cats.forEach((cat, index) => {
      const currentPath = `${path || 0}-${index}`
      treeNodes.push({
        key: cat.id as string,
        title: {
          path: currentPath,
          category: cat,
          hideActions: parent?.type === 'function',
          multiple: !(parent?.type === 'function' && parent.value === 'division')
        },
        children: cat.children && generateTreeDataNodes(cat.children, cat, currentPath)
      })
    })

    return treeNodes
  }

  useEffect(() => {
    setGData(generateTreeDataNodes(categories))

    return () => {
      setGData([])
    }
  }, [categories])

  return (
    <div>
      <Space style={{ marginBottom: 24 }}>
        <Button style={{ marginLeft: 8 }} icon={<PlusOutlined />} onClick={addCompany}>
          {`${t('contractsPage:company')}`}
        </Button>
        <Button style={{ marginLeft: 8 }} icon={<PlusOutlined />} onClick={addRootFunction}>
          {`${t('customReportCategoryType:function')}`}
        </Button>
      </Space>
      {/* #Amiskikka, että defaultExpandAll toimii */}
      {gData ? (
        <Tree
          defaultExpandAll
          titleRender={nodeData => {
            return <CategoryNodeRender {...(nodeData as unknown as GData).title} />
          }}
          draggable={{
            nodeDraggable: node => {
              const ctx = getPathContext(node.key as string)
              // Tässä katositaan ettei voi raahailla funktion alla olevia nodeja
              if (
                (ctx.function && ctx.periodGroup) ||
                (ctx.function && ctx.keyFigureId) ||
                (ctx.function && ctx.dimensionId)
              ) {
                return false
              }
              return true
            }
          }}
          blockNode
          allowDrop={({ dragNode, dropNode, dropPosition }) => {
            // the drop position of abstract-drop-node, inside 0, top -1, bottom 1
            const dragNodePath = (dragNode.key as string).split('-')
            const dropNodePath = (dropNode.key as string).split('-')
            const dragNodeParentPath = dragNodePath.slice(0, -1)
            const dropNodeParentPath = dropNodePath.slice(0, -1)

            // Sallitaan pudottaminen oman parentin sisälle, näin saa listan ekaksi
            if (dropNodePath.toString() === dragNodeParentPath.toString()) {
              if (dropPosition === 0) return true
            }

            // Sallitaaan saman parentin sisällä ylä ja alapuolelle pudottaminen
            if (dropNodeParentPath.toString() === dragNodeParentPath.toString()) {
              if (dropPosition !== 0) return true
            }

            return false
          }}
          onDrop={onDrop}
          treeData={gData as any}
          showIcon={false}
          showLine
          selectable={false}
        />
      ) : null}
    </div>
  )
}

export default CategorySelection
