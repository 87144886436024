import React, { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import BudgetDeleteModal from './DeleteConfirmModal'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { notificationAction } from '../../../../../redux/middleware/actions'
import {
  setFinancialStatementsBudgetInEditAction,
  resetFinancialStatementsBudgets,
  toggleFinancialStatementsBudgetInEditAction,
  toggleFinancialStatementsDriverInEditAction,
  toggleAllFinancialStatementsBudgetInEditAction
} from '../../../../../redux/pages/budgeting/financialStatements/actions'
import { FiscalYear } from '../../../../../types/fiscalYear/FiscalYear'
import { PeriodGroup } from '../../../../../types/periodGroup/PeriodGroup'
import { dispatchFinancialStatementRequests } from '../../service'
import { reloadDimensionsData, saveBudgets } from './service'
import { AppDispatch } from '../../../../../redux/store'
import { useBackend } from '../../../../../services/backend'
import { ReportTableRow, VariableType } from '../../../../../components/Table/types'
import { useTableColumns } from '../../../../../redux/context/periodGroups/hooks'
import { dimensionListSelector } from '../../../../../redux/context/dimensions/selectors'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { statementRowSelector } from '../../../../../redux/entities/statementRows/selectors'
import { financialStatementsPageSelector } from '../../../../../redux/pages/budgeting/financialStatements/selectors'
import { isBudgetDriverMethod } from '../../../../../components/Budgeting/BudgetingMethodBudgetDriver'
import BudgetMethodModal from './BudgetMethodModal'
import BudgetDistributionModal from './BudgetDistributionModal'
import { Budget, BudgetingMethod } from './types'
import { ReportRowType } from '../../../../../redux/context/reports/types'
import { DimensionQueryOperator } from '../../../../../types/dimension/Dimension'
import { contextRequest } from '../../../../../redux/context/actions'
import {
  getFinancialStatementRequest,
  getKeyPerformanceIndicatorStatmentRequest
} from '../../../../../redux/context/reports/actions'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { useAccounts } from '../../../../../features/account/queries/useFetchAccounts'

type ModalType = 'editBudget' | 'deleteBudget' | 'distributeBudget'
type BudgetActionType = 'edit' | 'delete' | 'save' | 'cancel' | 'editRow'

export type BudgetingContextType = {
  visible: ModalType | false
  setVisible: (visible: ModalType | false) => void
  handleBudgetDelete: (row: ReportTableRow) => void
  setReportTableRow: (row: ReportTableRow) => void
  handleRowAction: (actionType: BudgetActionType, Row: ReportTableRow) => void
  handleSaveAll: () => void
}

export const BudgetingContext = React.createContext<BudgetingContextType | null>(null)

interface BudgetingProviderProps {
  children?: ReactNode
}

const BudgetingProvider: React.FC<BudgetingProviderProps> = ({ children }) => {
  const [visible, setVisible] = useState<ModalType | false>(false)
  const [reportTableRow, setReportTableRow] = useState<ReportTableRow>()
  const budgetRequest = useBackend('/api/companies/{companyId}/budgeting/budget-deltas')
  const budgetDriversRequest = useBackend('/api/companies/{companyId}/settings/key-figures/formulas/{formulaId}/data')
  const columns = useTableColumns()
  const { budgetsInEdit, driversInEdit } = useSelector(financialStatementsPageSelector)
  const { periodGroup, budgetingScenario, periodGroups: periodGroupsData } = useSelector(filtersSelector)
  const { data: allAccounts } = useAccounts()
  const statementRows = useSelector(statementRowSelector)
  const rowDimensionDataRequest = useBackend('/api/companies/{companyId}/reporting/financial-statement')
  const dimensionList = useSelector(dimensionListSelector)

  const [budgetList, setBudgetList] = useState<Budget[]>()

  const form = useFormInstance()

  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()

  const handleModalCancel = () => {
    setReportTableRow(undefined)
    setVisible(false)
  }

  const deleteBudgets = (
    periodGroups: Partial<FiscalYear>[] | Partial<PeriodGroup>[],
    row: ReportTableRow,
    driver?: boolean
  ) => {
    if (companyId && row) {
      const deleteReq = driver ? budgetDriversRequest : budgetRequest
      const { incomeStatementRowId, accountCode, dimensionId } = row

      dispatch({ type: 'FINANCIAL_STATEMENT_REQUEST' })
      deleteReq
        .delete({
          urlParams: {
            companyId,
            ...(driver && { formulaId: row.id })
          },
          body: {
            params: {
              accountCode,
              incomeStatementRowId,
              periodGroups,
              dimensions: JSON.stringify(
                [dimensionId]?.map(id => ({
                  k: id,
                  o: DimensionQueryOperator.has,
                  v: id
                }))
              )
            }
          }
        })
        .then(() => {
          dispatch(
            setFinancialStatementsBudgetInEditAction({
              [row.key.toString()]: false
            })
          )
          dispatch(
            notificationAction({
              type: 'success',
              message: 'DELETE_BUDGET_SUCCESS'
            })
          )
          dispatchFinancialStatementRequests()

          reloadDimensionsData(
            dispatch,
            rowDimensionDataRequest,
            budgetingScenario,
            periodGroupsData,
            allAccounts,
            statementRows,
            companyId,
            dimensionList,
            { [row.key]: true },
            driversInEdit
          )
        })
        .catch((err: Error) => {
          dispatch(
            notificationAction({
              type: 'error',
              message: 'DELETE_BUDGET_ERROR',
              description: err.message
            })
          )
        })
    }
  }

  const handleBudgetSave = async (rowBudgets: Budget[][], row?: ReportTableRow) => {
    if (!companyId) return
    try {
      await saveBudgets(rowBudgets, budgetDriversRequest, budgetRequest, companyId)
      // yksi
      if (row) {
        const { key } = row || {}

        key &&
          dispatch(
            setFinancialStatementsBudgetInEditAction({
              [key]: false
            })
          )
        // monta
      } else {
        dispatch(toggleAllFinancialStatementsBudgetInEditAction())
      }

      dispatch(
        notificationAction({
          type: 'success',
          message: 'SAVE_BUDGET_SUCCESS'
        })
      )

      reloadDimensionsData(
        dispatch,
        rowDimensionDataRequest,
        budgetingScenario,
        periodGroupsData,
        allAccounts,
        statementRows,
        companyId,
        dimensionList,
        budgetsInEdit,
        driversInEdit
      )

      dispatch(contextRequest(getFinancialStatementRequest))
      dispatch(contextRequest(getKeyPerformanceIndicatorStatmentRequest))
    } catch (error) {
      dispatch(
        notificationAction({
          type: 'error',
          message: 'SAVE_BUDGET_ERROR'
        })
      )
    }
  }

  const handleRowBudgetDelete = async (row: ReportTableRow) => {
    if (!companyId) return
    if (periodGroup?.id && row) {
      const deletePeriodGroups = [
        {
          id: periodGroup?.id,
          startDate: periodGroup?.startDate,
          endDate: periodGroup?.endDate
        }
      ] as PeriodGroup[]

      deleteBudgets(deletePeriodGroups, row, isBudgetDriverMethod(row))
    } else {
      setVisible('deleteBudget')
    }
  }

  type FormCellValues = {
    value: number
    accountCode?: number
    incomeStatementRowId?: number
    variableType?: VariableType
    formulaId?: number
    dimensionId?: string
  }

  type FormColumnValues = {
    [columnLabel: string]: FormCellValues
  }

  type FormRowValues = {
    [code: string]: FormColumnValues
  }

  type BudgetingFormValues = {
    budgetDriver: FormRowValues
    budget: FormRowValues
  }

  const transformRowValues = (rowValues: FormColumnValues) => {
    const rb: Budget[] = Object.entries(rowValues)
      .filter(column => column[1].value !== undefined)
      .map(([columnLabel, cellValues]) => {
        const { year, month, periodGroup: pg, dataType } = columns.find(c => c.label === columnLabel) || {}
        return {
          year,
          month,
          periodGroup: pg,
          dataType,
          ...cellValues
        }
      })

    return rb
  }

  const handleRowBudgetSave = async (row: ReportTableRow) => {
    const values: BudgetingFormValues = form.getFieldsValue()
    const type = isBudgetDriverMethod(row) ? 'budgetDriver' : 'budget'

    const rowValues = values[type][row.key.split('-budget-menu')[0]]
    const rowBudgets = transformRowValues(rowValues)

    setBudgetList(rowBudgets)

    if (periodGroup?.id) {
      handleBudgetSave([rowBudgets], row)
    } else {
      setVisible('distributeBudget')
    }
  }

  const handleSaveAll = async () => {
    const values: BudgetingFormValues = form.getFieldsValue()
    const rows = Object.values(values).flatMap(Object.values)
    const budgetRows = rows.map(transformRowValues)

    setBudgetList(budgetRows.flat())

    if (periodGroup?.id) {
      handleBudgetSave(budgetRows)
    } else {
      setReportTableRow(undefined)
      setVisible('distributeBudget')
    }
  }

  const handleRowEdit = (row: ReportTableRow) => {
    if (row?.budgetingMethod?.method === BudgetingMethod.driver && row.rowType === ReportRowType.budgetableRow) {
      dispatch(toggleFinancialStatementsDriverInEditAction(row.key))
    } else {
      dispatch(toggleFinancialStatementsBudgetInEditAction(row.key))
    }
  }

  const handleRowCancel = (row: ReportTableRow) => {
    if (row?.budgetingMethod?.method === BudgetingMethod.driver && row.rowType === ReportRowType.budgetableRow) {
      dispatch(toggleFinancialStatementsDriverInEditAction(row.key))
    } else {
      dispatch(resetFinancialStatementsBudgets(row.key))
      dispatch(toggleFinancialStatementsBudgetInEditAction(row.key))
    }
  }

  const reloadDimensionRows = () => {
    reloadDimensionsData(
      dispatch,
      rowDimensionDataRequest,
      budgetingScenario,
      periodGroupsData,
      allAccounts,
      statementRows,
      companyId,
      dimensionList,
      budgetsInEdit,
      driversInEdit
    )
  }

  const handleRowAction = (action: BudgetActionType, row: ReportTableRow) => {
    setReportTableRow(row)
    switch (action) {
      case 'editRow':
        setVisible('editBudget')
        break
      case 'edit':
        handleRowEdit(row)
        break
      case 'delete':
        handleRowBudgetDelete(row)
        break
      case 'save':
        handleRowBudgetSave(row)
        break
      case 'cancel':
        handleRowCancel(row)
        break

      default:
        break
    }
  }

  return (
    <BudgetingContext.Provider
      value={{
        visible,
        setVisible,
        handleBudgetDelete: handleRowBudgetDelete,
        setReportTableRow,
        handleRowAction,
        handleSaveAll
      }}
    >
      <BudgetDistributionModal
        rowBudgets={budgetList}
        isModalVisible={visible === 'distributeBudget'}
        reportTableRow={reportTableRow}
        handleCancel={handleModalCancel}
        saveBudgets={handleBudgetSave}
      />
      <BudgetMethodModal
        visible={visible === 'editBudget'}
        reportTableRow={reportTableRow}
        reloadDimensionRows={reloadDimensionRows}
      />

      <BudgetDeleteModal
        deleteModalVisible={visible === 'deleteBudget'}
        columns={columns}
        reportTableRow={reportTableRow}
        handleCancel={handleModalCancel}
        deleteBudgets={deleteBudgets}
      />
      <LoadingWrapper loading={budgetRequest.loading}>{children}</LoadingWrapper>
    </BudgetingContext.Provider>
  )
}

export default BudgetingProvider
