import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { deleteLoan } from '../service/loanService'

export const useDeleteLoan = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: (loanId: number) => deleteLoan(companyId, loanId, budgetingScenario?.id)
  })
}
