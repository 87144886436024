import { createSelector } from 'reselect'
import { CompanyForm, Country } from '../../../types/company/Company'
import { contextSelector } from '../selectors'

export const contextCompanySelector = createSelector(contextSelector, context => context?.company)
export const contextCompanyIdSelector = createSelector(contextCompanySelector, company => company && company.id)
export const contextCompanyCountrySelector = createSelector(contextCompanySelector, company =>
  company ? company.country : Country.fi
)
export const contextCompanyFormSelector = createSelector(contextCompanySelector, company =>
  company ? company.form : CompanyForm.limited
)
