import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import loanQueryKeys from './loanQueryKeys'
import { fetchLoanInstalments } from '../service/loanService'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { filtersSelector } from '../../../redux/context/filters/selectors'

export const useLoanInstalments = (loanId: number) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useQuery({
    queryKey: loanQueryKeys.instalments({ companyId, loanId, budgetingScenarioId: budgetingScenario?.id }),
    queryFn: fetchLoanInstalments
  })
}
