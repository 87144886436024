import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Button, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

type DataIndex = string | string[]

export const useColumnRangePicker = (dataIndex: DataIndex) => {
  const { t } = useTranslation()
  const [selectedDateRange, setSelectedDateRange] = useState<any>(null)

  const handleDateChange = (dates: any) => {
    setSelectedDateRange(dates)
  }

  const handleFilter = (value: any, record: any) => {
    if (!selectedDateRange || !selectedDateRange[0] || !selectedDateRange[1]) {
      return true
    }

    const [startDate, endDate] = selectedDateRange
    const getPropertyValue = (obj: any, path: string | string[]) => {
      if (Array.isArray(path)) {
        return path.reduce((acc, key) => (acc && typeof acc === 'object' ? acc[key] : null), obj)
      }
      return obj[path]
    }

    const recordDate = dayjs(getPropertyValue(record, dataIndex))
    return recordDate.isBetween(startDate, endDate, 'day', '[]')
  }

  return {
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8, width: 200 }}>
        <RangePicker
          onChange={e => {
            handleDateChange(e)
          }}
          value={selectedDateRange}
          popupStyle={{ zIndex: 9999999999 }}
          style={{ marginBottom: 8, display: 'flex' }}
        />
        <Button
          type="primary"
          onClick={() => {
            setSelectedKeys(selectedDateRange ? [selectedDateRange] : [])
            return confirm()
          }}
          style={{ width: '100%' }}
        >
          OK
        </Button>
        <Button
          onClick={() => {
            setSelectedDateRange(null)
            return clearFilters && clearFilters()
          }}
          style={{ width: '100%', marginTop: 8 }}
        >
          {t('global:cancel')}
        </Button>
      </div>
    ),
    onFilter: handleFilter,
    render: (value: any) => dayjs(value).format('DD.MM.YYYY')
  }
}
