import Highcharts from 'highcharts'
import { useSelector } from 'react-redux'
import { companySettingsChartColorSelector } from '../../redux/context/companySettings/selectors'
import { initialColors } from '../../pages/settings/company/general/Appearance/GraphColorSettings'

export const useHighchartTheme = () => {
  const chartColors = useSelector(companySettingsChartColorSelector)
  return {
    colors: chartColors || initialColors,
    plotOptions: {
      column: {
        maxPointWidth: 70
      }
    },
    xAxis: {
      labels: {
        useHTML: true,
        style: {
          fontSize: '0.7rem'
        }
      }
    },
    chart: {
      style: {
        fontFamily: 'Manrope'
      },
      backgroundColor: undefined,
      events: {
        afterPrint() {
          // eslint-disable-next-line func-names
          Highcharts.charts.forEach(function (chart) {
            if (chart !== undefined) {
              chart.setSize(null, null, false)
              chart.redraw()
            }
          })
        }
      }
    },
    credits: {
      enabled: false,
      text: ''
    },
    lang: {
      decimalPoint: ',',
      thousandsSep: ' '
    }
  } satisfies Highcharts.Options
}
