import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { Investment } from '../../../types/investment/Investment'
import { deleteInvestment } from '../service/investmentService'
import { useInvestmentOptions } from './useInvestment'

export const useDeleteInvestment = (isExisting: boolean) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const options = useInvestmentOptions(isExisting)
  const { message } = App.useApp()

  return useMutation({
    mutationFn: (investment: Investment) => deleteInvestment(companyId, investment),
    mutationKey: ['investment'],
    onSuccess: () => {
      message.success(t('messages:deleteSuccess', 'Success'))
      return queryClient.invalidateQueries({ queryKey: options.queryKey })
    }
  })
}
