import { LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Result, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { useBackend } from '../../../../../services/backend'
import { TalenomAuthentication } from '../types'
import { notificationAction } from '../../../../../redux/middleware/actions'
import { useDocsURL } from '../../../../../utils/docs'
import Loading from '../../../../../shared/components/Loading'
import DataUpdate from '../dataUpdate'
import { AppDispatch } from '../../../../../redux/store'

const Talenom: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const companyId = useSelector(contextCompanyIdSelector)
  const getCredentials = useBackend(`/talenom/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)
  const setCredentials = useBackend(`/talenom/authentication/{companyId}`, process.env.REACT_APP_INTEGRATION_URL)

  const dispatch: AppDispatch = useDispatch()

  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [authorized, setAuthorized] = useState<boolean>(false)

  const getCredentialsHandler = () => {
    getCredentials
      .get({
        urlParams: { companyId }
      })
      .then((credentials: TalenomAuthentication) => {
        if (credentials.username && credentials.password) {
          setAuthorized(true)
        } else {
          setUsername('')
          setPassword('')
          setAuthorized(false)
        }
      })
      .catch(() => {
        setUsername('')
        setPassword('')
      })
    return () => {
      setAuthorized(false)
    }
  }

  useEffect(getCredentialsHandler, [companyId])

  const submit = () => {
    setCredentials
      .put({
        urlParams: { companyId },
        body: {
          data: {
            username,
            password
          }
        }
      })
      .then((credentials: TalenomAuthentication) => {
        if (credentials.username && credentials.password) {
          setAuthorized(true)
        }
        dispatch(
          notificationAction({
            type: 'success',
            message: 'AUTHENTICATION_SUCCESS',
            description: 'IMPORT_SUCCESS_EMAIL'
          })
        )
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'AUTHENTICATION_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  const authButton = () => {
    return (
      <>
        <Button type="primary" onClick={() => setAuthorized(false)}>
          {t('integrationsPage:authenticate')}
        </Button>
        <DataUpdate page="talenom" />
      </>
    )
  }

  if (getCredentials.loading) {
    return <Loading />
  }

  if (authorized) {
    return (
      <>
        <Result status="success" title={t('integrationsPage:authenticated')} extra={authButton()} />
      </>
    )
  }

  return (
    <Form layout="vertical">
      <a href={`${docsURL}manual/businesses/settings/company#talenom`} target="_blank" rel="noopener noreferrer">
        {t('header:manual')}
      </a>
      <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:username')}>
        <Input
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
          suffix={getCredentials.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} label={t('integrationsPage:password')}>
        <Input
          value={password}
          type="password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          suffix={getCredentials.loading && <LoadingOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => submit()} loading={setCredentials.loading}>
          {t('integrationsPage:authenticate')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Talenom
