import React, { useEffect } from 'react'
import { Button, DatePicker, Form, Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'
import { Loan } from '../types/Loan'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { transformFieldData } from '../../../utils/form'
import { useUpdateLoan } from '../queries/useUpdateLoan'
import loanQueryKeys from '../queries/loanQueryKeys'

interface LoanInterestProps {
  loan: Loan
  handleOk: () => void
}

const LoanInterestModal: React.FC<LoanInterestProps> = ({ loan, handleOk }: LoanInterestProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const updateLoan = useUpdateLoan()

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(
      {
        interestRates: loan?.interestRates
          .sort((a, b) => (dayjs(a?.date).valueOf() || 0) - (dayjs(b?.date).valueOf() || 0))
          .map(i => ({
            ...i,
            date: dayjs(i.date)
          }))
      } || []
    )
  }, [loan])

  const handleSubmit = async () => {
    try {
      if (!loan) return
      const formValues = await form.validateFields()
      const formattedValues = transformFieldData(formValues, {
        date: (val: string) => dayjs(val).format('YYYY-MM-DD')
      })

      // TODO: trvitseeko eteen käteen muunnella dataa, voiko loan tablessa Purposen purposena
      const values = {
        ...loan,
        purposes:
          loan.purposes?.map(p => {
            return { name: p }
          }) || [],
        ...formattedValues
      }

      await updateLoan.mutateAsync(values, {
        onSuccess: () =>
          queryClient.invalidateQueries({
            queryKey: loanQueryKeys.summaries()
          })
      })

      handleOk()
    } catch (info) {
      console.error('Validate Failed:', info)
    }
  }

  return (
    <Modal
      destroyOnClose
      open={!!loan}
      width={700}
      title={t('loans:changing-interest')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      maskClosable={false}
      confirmLoading={updateLoan.isPending}
      onCancel={() => {
        form.resetFields()
        handleOk()
      }}
      onOk={handleSubmit}
    >
      <Form form={form} name="interest_rates" style={{ maxWidth: 700 }} autoComplete="off">
        <Form.List name="interestRates">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    label={t('contractsPage:date')}
                    name={[field.name, 'date']}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <DatePicker format="DD.MM.YYYY" />
                  </Form.Item>

                  <Form.Item
                    label={t('loans:interest-rate')}
                    name={[field.name, 'interestRate']}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <FormattedInputNumber step={1} precision={4} max={100} percentage addonAfter="%" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t('global:add')} ${t('loans:interestRateChange')}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default LoanInterestModal
