import { Dayjs } from 'dayjs'
import { InvestmentSummaryInvestment } from '../../../types/investment/Investment'

export const SET_INVESTMENT_PAGE_DATE = 'SET_INVESTMENT_PAGE_DATE'

export interface InvestmentsPageSummary {
  actualResidual?: number
  budgetResidual: number
  difference?: number
  taxResidual: number
}

export interface InvestmentsPageBalanceSheetRow {
  balanceSheetRowId: number
  investments: InvestmentSummaryInvestment[]
  summary: InvestmentsPageSummary
  isExisting?: boolean
  type: 'existing' | 'new'
}

export interface SetInvestmentPageDateAction {
  type: typeof SET_INVESTMENT_PAGE_DATE
  payload: Dayjs
}

export interface InvestmentsPageReport {
  balanceSheetRows: InvestmentsPageBalanceSheetRow[]
  summary: InvestmentsPageSummary
}

export interface InvestmentsPageReport {
  balanceSheetRows: InvestmentsPageBalanceSheetRow[]
  summary: InvestmentsPageSummary
}

// Page data
export interface InvestmentsPage {
  date: string
}

export type InvestmentPageActionTypes = SetInvestmentPageDateAction
