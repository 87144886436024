import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'

interface InvestmentSummaryParams {
  companyId: string
  isExisting: boolean
  date: string | null
  budgetingScenarioId: BudgetingScenario['id'] | undefined
}

type InvestmentDetailParams = {
  companyId: string
  investmentId: number
}

const investmentQueryKeys = {
  all: [{ scope: 'investment' }] as const,
  summaries: () => [{ ...investmentQueryKeys.all[0], entity: 'summary' }] as const,
  summary: (params: InvestmentSummaryParams) => [{ ...investmentQueryKeys.summaries()[0], ...params }] as const,
  detail: (params: InvestmentDetailParams) => [{ ...investmentQueryKeys.all[0], entity: 'detail', ...params }] as const,
  depreciations: (params: InvestmentDetailParams) =>
    [{ ...investmentQueryKeys.detail(params)[0], entity: 'depreciations' }] as const
}

export default investmentQueryKeys
