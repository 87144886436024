import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import Tour from 'reactour'
import { useSelector, useDispatch } from 'react-redux'
import {
  currentUserRoleSelector,
  currentUserTourCompletedSelector,
  currentUserSelector,
  currentUserTermsConditionsSelector
} from '../../redux/session/currentUser/selectors'
import { CompanySteps } from './intro/CompanyIntro'
import { Accountantsteps } from './intro/AccountantIntro'
import { tourCompletedRequest } from '../../redux/session/currentUser/actions'
import { useLoggedin, usePermission } from '../../redux/session/currentUser/hooks'
import { CompanyLiteSteps } from './intro/CompanyLiteIntro'
import { AppDispatch } from '../../redux/store'

const UserTour: React.FC = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [steps, setSteps] = useState(Accountantsteps)
  const role = useSelector(currentUserRoleSelector)
  const isLoggedIn = useLoggedin()
  const tourCompleted = useSelector(currentUserTourCompletedSelector)
  const currentUser = useSelector(currentUserSelector)
  const termsConditionsAgreed = useSelector(currentUserTermsConditionsSelector)
  const writeBudgetsPermission = usePermission('write-budgets')
  const dispatch: AppDispatch = useDispatch()

  const closeTour = () => {
    setIsOpen(false)
  }

  const tourHandler = () => {
    if (isLoggedIn && !tourCompleted && termsConditionsAgreed) {
      if (role.label.includes('accountant')) {
        setIsOpen(true)
        setSteps(Accountantsteps)
      } else if (role.label.includes('company')) {
        setIsOpen(true)
        if (writeBudgetsPermission) setSteps(CompanySteps)
        else setSteps(CompanyLiteSteps)
      }
    }
  }

  useEffect(tourHandler, [currentUser])

  const userTourCompleted = () => {
    dispatch(tourCompletedRequest())
  }

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={closeTour}
      lastStepNextButton={<Button onClick={userTourCompleted}>{t('tour:ready')}</Button>}
    />
  )
}

export default UserTour
