import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { getPerformanceIndicatorsRequest } from '../../../../../redux/context/performanceIndicators/actions'
import { keyPerformanceIndicatorsLoadingSelector } from '../../../../../redux/context/performanceIndicators/selectors'
import KeyPerformanceIndicatorsForm from './components/KeyPerformanceIndicatorsForm'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { contextRequest } from '../../../../../redux/context/actions'
import { AppDispatch } from '../../../../../redux/store'

const KeyPerformanceIndicatorsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)

  const loading = useSelector(keyPerformanceIndicatorsLoadingSelector)

  useEffect(() => {
    companyId && dispatch(contextRequest(getPerformanceIndicatorsRequest))
  }, [companyId, dispatch])

  return (
    <LoadingWrapper loading={loading}>
      <KeyPerformanceIndicatorsForm />
    </LoadingWrapper>
  )
}

export default KeyPerformanceIndicatorsPage
