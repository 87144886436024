import React, { ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contextRequest } from '../../../../../redux/context/actions'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import {
  createCompanyDashboardItemRequest,
  updateCompanyDashboardItemRequest
} from '../../../../../redux/context/dashboard/company/actions'
import { companyDashboardPageSelector } from '../../../../../redux/pages/dashboard/companyDashboard/selectors'
import { CompanyDashboardItem } from '../../../../../types/dashboard/company/types'
import { useAuthorizedData } from '../../../../../utils/Authorizable/authorize'
import { dashboardItemPreferences } from '../config'
import { DashboardItemPreferenceSelection, MenuGroup } from '../types'
import { AppDispatch } from '../../../../../redux/store'

export type CompanyDashboardContextType = {
  visible: boolean
  step: 'select' | 'edit'
  companyDashboardItem?: CompanyDashboardItem
  // formFields: any
  activeSelectionTab: string
  companyDashboardItemPreferenceSelection: DashboardItemPreferenceSelection | null
  setActiveSelectionTab: (value: string) => void
  handleCancel: () => void
  handleReturn: () => void
  handleOpen: (item?: CompanyDashboardItem) => void
  handleSubmit: (item: CompanyDashboardItem) => void
  handleItemSelection: (item: DashboardItemPreferenceSelection) => void
}

export const CompanyDashboardItemContext = React.createContext<CompanyDashboardContextType | null>(null)

interface CompanyDashboardItemProviderProps {
  children?: ReactNode
}

const CompanyDashboardItemProvider: React.FC<CompanyDashboardItemProviderProps> = ({ children }) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const dispatch: AppDispatch = useDispatch()

  const [visible, setVisible] = useState(false)
  const [step, setstep] = useState<'select' | 'edit'>('select')
  const itemPreferences = useAuthorizedData(dashboardItemPreferences)
  const { activeDashboard: activeDashboardId } = useSelector(companyDashboardPageSelector)!
  const [activeSelectionTab, setActiveSelectionTab] = useState<string>(MenuGroup.FINANCIAL_STATEMENTS.toString())
  const [companyDashboardItem, setCompanyDashboardItem] = useState<CompanyDashboardItem>()
  const [companyDashboardItemPreferenceSelection, setCompanyDashboardItemPreferenceSelection] =
    useState<DashboardItemPreferenceSelection | null>(null)

  const allItemPreferences = useMemo(
    () =>
      itemPreferences.reduce((allItems, current) => {
        const itemsFromCurrent = current.params.representation.map(
          rep =>
            ({
              ...current,
              params: {
                ...current.params,
                representation: rep
              }
            } as DashboardItemPreferenceSelection)
        )
        return [...allItems, ...itemsFromCurrent]
      }, [] as DashboardItemPreferenceSelection[]),
    []
  )

  const handleCancel = () => {
    setVisible(false)
  }

  const handleItemSelection = (item: DashboardItemPreferenceSelection) => {
    // jos itemissä request, niin lataa tarvittavat datat storeen formia varten
    if (item.request && companyId) {
      dispatch(item.request(companyId))
    }
    if (item.contextRequest) {
      dispatch(contextRequest(item.contextRequest))
    }
    // ota tarvittavat tiedot conffista
    const { type, params } = item

    setCompanyDashboardItemPreferenceSelection(item)
    setCompanyDashboardItem({
      ...companyDashboardItem,
      type,
      params: { ...params, selectedRows: [] }
    } as CompanyDashboardItem)
    setstep('edit')
  }

  const handleReturn = () => {
    setstep('select')
  }

  const handleOpen: CompanyDashboardContextType['handleOpen'] = item => {
    const openingStep = item ? 'edit' : 'select'
    setstep(openingStep)
    if (item) {
      // Find items preference from cofig needed to render right form
      const itemPreference = allItemPreferences.find(
        ip =>
          ip.type === item?.type &&
          ip.params.representation.type === item.params.representation.type &&
          ip.params.type === item.params.type &&
          ip.params.record === item.params.record &&
          ip.params.selectedGraph === item.params.selectedGraph
      )

      if (!itemPreference) throw new Error('Item not found')

      setCompanyDashboardItemPreferenceSelection(itemPreference)
    }
    setCompanyDashboardItem(item)
    setVisible(true)
  }

  const handleUpdate = (item: CompanyDashboardItem) => {
    activeDashboardId && dispatch(updateCompanyDashboardItemRequest(companyId, item, activeDashboardId))
  }

  const handleCreate = (item: CompanyDashboardItem) => {
    activeDashboardId && dispatch(createCompanyDashboardItemRequest(companyId, item, activeDashboardId))
  }

  const handleSubmit = (item: CompanyDashboardItem) => {
    if (item.id) {
      handleUpdate(item)
    } else {
      handleCreate(item)
    }
    setVisible(false)
  }

  return (
    <CompanyDashboardItemContext.Provider
      value={{
        step,
        visible,
        activeSelectionTab,
        companyDashboardItem,
        companyDashboardItemPreferenceSelection,
        setActiveSelectionTab,
        handleItemSelection,
        handleSubmit,
        handleCancel,
        handleReturn,
        handleOpen
      }}
    >
      {children}
    </CompanyDashboardItemContext.Provider>
  )
}

export default CompanyDashboardItemProvider
