import dayjs from 'dayjs'
import { InvestmentsPage, InvestmentPageActionTypes, SET_INVESTMENT_PAGE_DATE } from './types'

const initialState: InvestmentsPage = {
  date: dayjs().toString()
}

const investmentsPageReducer = (state = initialState, action: InvestmentPageActionTypes) => {
  switch (action.type) {
    case SET_INVESTMENT_PAGE_DATE:
      return { ...state, date: action.payload }
    default:
      return state
  }
}

export default investmentsPageReducer
