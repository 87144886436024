import React, { ReactNode, useState } from 'react'
import { Popover, Divider, Button } from 'antd'
import { CloseOutlined, CommentOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Column } from '../../../../../redux/context/periodGroups/hooks'
import { ReportTableRow } from '../../../../../components/Table/types'
import BudgetCommentForm from './BudgetCommentForm'
import BudgetCommentList from './BudgetCommentList'
import { BudgetComment } from './BudgetCommentItem'
import { contextRequest } from '../../../../../redux/context/actions'
import {
  createBudgetCommentRequest,
  createDimensionBudgetCommentRequest,
  deleteBudgetCommentRequest,
  deleteDimensionBudgetCommentRequest,
  updateBudgetCommentRequest,
  updateDimensionBudgetCommentRequest
} from '../../../../../redux/context/budgetComment/actions'
import { getBudgetCellComments } from '../../../../../redux/context/budgetComment/selectors'
import { ReportRowType } from '../../../../../redux/context/reports/types'
import { AppDispatch } from '../../../../../redux/store'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { DimensionQueryOperator } from '../../../../../types/dimension/Dimension'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'

interface BudgetCommentPopoverProps {
  row: ReportTableRow
  column: Column
  icon?: boolean
  children?: ReactNode
}

const BudgetCommentPopover: React.FC<BudgetCommentPopoverProps> = ({ children, row, column, icon = true }) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const comments = useSelector(getBudgetCellComments(row, column))
  const { dimensions } = useSelector(filtersSelector)
  const companyId = useSelector(contextCompanyIdSelector)!

  const hide = () => {
    setOpen(false)
  }

  const getDimensionQuery = (id?: string) => {
    if (!id) return undefined
    return JSON.stringify([
      {
        k: id,
        o: DimensionQueryOperator.has,
        v: id
      }
    ])
  }

  const handleSave = (value: BudgetComment) => {
    const { month, year, periodGroup: { id: fiscalYearId } = {} } = column
    const { incomeStatementRowId, dimensionId, accountCode } = row

    const newComment = {
      accountCode,
      month,
      year,
      fiscalYearId,
      incomeStatementRowId: accountCode ? undefined : incomeStatementRowId,
      ...value
    }

    if ((dimensionId && !dimensions) || dimensionId !== dimensions?.[0]) {
      dispatch(
        createDimensionBudgetCommentRequest(newComment, {
          companyId,
          dimensions: getDimensionQuery(dimensionId)
        })
      )
    } else {
      dispatch(contextRequest(createBudgetCommentRequest, newComment))
    }
  }

  const handleUpdate = (value: BudgetComment) => {
    if ((row.dimensionId && !dimensions) || row.dimensionId !== dimensions?.[0]) {
      dispatch(
        updateDimensionBudgetCommentRequest(value, {
          companyId,
          dimensions: getDimensionQuery(value.dimensionId)
        })
      )
    } else {
      dispatch(contextRequest(updateBudgetCommentRequest, value))
    }
  }

  const handleDelete = (value: BudgetComment) => {
    if ((row.dimensionId && !dimensions) || row.dimensionId !== dimensions?.[0]) {
      dispatch(
        deleteDimensionBudgetCommentRequest(value, {
          companyId,
          dimensions: getDimensionQuery(value.dimensionId)
        })
      )
    } else {
      dispatch(contextRequest(deleteBudgetCommentRequest, value))
    }
  }

  const clickContent = [
    <BudgetCommentList
      key="list"
      handleSave={handleUpdate}
      handleDelete={handleDelete}
      comments={comments}
      disableActions={row.rowType === ReportRowType.dimension}
    />,
    row.rowType !== ReportRowType.dimension && <Divider key="div" />,
    row.rowType !== ReportRowType.dimension && <BudgetCommentForm key="form" handleSubmit={handleSave} />
  ]

  return (
    <Popover
      placement="left"
      content={
        <div>
          <div>{clickContent}</div>
        </div>
      }
      onOpenChange={() => {
        if (!open) setOpen(true)
      }}
      title={
        <div>
          {t('global:comment')}
          <div style={{ float: 'right' }}>
            <Button size="small" type="text" icon={<CloseOutlined />} onClick={hide} />
          </div>
        </div>
      }
      trigger="click"
      open={open}
    >
      {icon && comments.length > 0 && <CommentOutlined onClick={() => setOpen(true)} style={{ marginRight: 8 }} />}

      {children}
    </Popover>
  )
}

export default BudgetCommentPopover
