import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { deleteInvestmentDepreciation } from '../service/investmentService'

export const useDeleteInvestmentDepreciation = () => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return useMutation({
    mutationFn: ({ deprecationPlanId, correctionId }: { deprecationPlanId: number; correctionId: number }) =>
      deleteInvestmentDepreciation(companyId, deprecationPlanId, correctionId)
  })
}
