import { Dictionary } from 'highcharts'
import _ from 'lodash'
import { Account } from '../types/Account'
import { Language } from '../../../types/user/User'
import { propertySorterProps, translateRecord } from '../../../utils/helpers'

export const companyAccountsRawByIdSelector = (data: Account[], code?: number): Account | undefined =>
  data?.find(account => account.code === code)

export const companyAccountsByStatementRowIdSelector = (accounts: Account[]): Dictionary<Account[]> =>
  _.groupBy(accounts, 'statementRowId')

export const companyAccountsByCodeSelector = (accounts: Account[]): Dictionary<Account> => _.keyBy(accounts, 'code')

export const companyAccountsSelector = (accounts: Account[], language: Language) =>
  accounts
    ?.slice()
    .sort(propertySorterProps('code'))
    .map(account => translateRecord(account, language))

export const companyAccountsArgumentOptionsSelector = (
  accounts: Account[]
): {
  name: String
  value: Number
}[] => {
  return accounts
    ?.slice()
    ?.sort((a, b) => a.code - b.code)
    .map(account => ({
      name: `${account.code} - ${account.name}`,
      value: account.code
    }))
}

export const combinedSelector = (accounts: Account[], params?: { lng?: Language; code?: number }) => {
  if (!params?.lng) return []
  const filteredAccounts = companyAccountsSelector(accounts, params.lng)
  return companyAccountsArgumentOptionsSelector(filteredAccounts)
}

export const accountsSelector = (accounts: Account[], params?: { lng?: Language; code?: number }) => {
  if (!params?.lng) return []
  return companyAccountsSelector(accounts, params.lng)
}

export const accountByCodeSelector = (accounts: Account[], params?: { code?: number; lng?: Language }) => {
  if (!params?.code) return undefined
  return companyAccountsRawByIdSelector(accounts, params.code)
}
