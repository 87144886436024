import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Authorized from '../../../components/Authorized/Authorized'
import ToolBar from '../../../components/Misc/ToolBar'
import { notificationAction } from '../../../redux/middleware/actions'
import { isGroupCompany } from '../../../routes/utils'
import { useUpdateAccount } from '../queries/useUpdateAccount'
import { useCreateAccount } from '../queries/useCreateAccount'
import { useDeleteAccount } from '../queries/useDeleteAccount'
import { Account } from '../types/Account'
import AccountFormModal from './AccountFormModal'
import AccountTable from './AccountTable'

const Accounts = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [initialValues, setInitialValues] = useState<Account | undefined>()
  const { mutateAsync: createAccount } = useCreateAccount()
  const { mutateAsync: updateAccount } = useUpdateAccount()
  const { mutateAsync: deleteAccount } = useDeleteAccount()

  const onCreate = async (values: Account) => {
    await createAccount(values)
    setOpen(false)
  }

  const onUpdate = async (values: Account) => {
    await updateAccount(values)
    setOpen(false)
  }

  const onDelete = async (values: Account) => {
    try {
      await deleteAccount(values.code)
      setOpen(false)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'DELETE_ACCOUNT_ERROR' }))
    }
  }

  const handleCreate = (item?: Account) => {
    setInitialValues(item)
    setOpen(true)
  }

  const handleEdit = (account: Account) => {
    setInitialValues(account)
    setOpen(true)
  }

  return (
    <>
      <Authorized authority={{ permission: 'write-accounts' }}>
        <AccountFormModal
          initialValues={initialValues}
          open={open}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={() => setOpen(false)}
        />

        <ToolBar visible={!isGroupCompany()}>
          <Button size="small" icon={<PlusOutlined />} onClick={() => handleCreate()}>
            {t('global:add-new')}
          </Button>
        </ToolBar>
      </Authorized>
      <AccountTable onEdit={handleEdit} onDelete={onDelete} />
    </>
  )
}

export default Accounts
