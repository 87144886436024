import { useSelector } from 'react-redux'
import { Permission } from '../../../types/user/Permission'
import { Role } from '../../../types/user/Role'
import { Store } from '../../types'
import { currentUserPermissionSelector, currentUserRoleSelector } from './selectors'

// tieto loginista on täällä, koska login on valmis vasta, kun käyttäjä on ladattu. ei silloin, kun pelkkä token on ladattu.
export const useLoggedin = () => {
  return useSelector((store: Store) => {
    const userLoaded = !!store?.session?.currentUser?.id
    const contractLoaded = !!store?.session?.contract?.id
    return userLoaded && contractLoaded
  })
}

export const usePermission = (label: string) => {
  const role: Role = useSelector(currentUserRoleSelector)
  const permissions = useSelector(currentUserPermissionSelector)
  if (role.label === 'superuser') return true
  if (permissions.map((permission: Permission) => permission.label).includes(label)) return true
  return false
}
