import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { updateLoanInstalmentsCorrection } from '../service/loanService'
import { LoanInstalmentCorrection } from '../types/LoanInstallmentCorrection'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'

export const useUpdateLoanInstalmentsCorrection = (loanId: number) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: ({ data, correctionId }: { data: Partial<LoanInstalmentCorrection>; correctionId: number }) =>
      updateLoanInstalmentsCorrection(data, companyId, loanId, correctionId, budgetingScenario?.id)
  })
}
