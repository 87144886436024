const loanEndpoints = {
  template: () => '/templates/loan-import' as const,
  summary: (companyId: string) => `/companies/${companyId}/budgeting/loans/summary` as const,
  import: (companyId: string) => `/companies/${companyId}/budgeting/loans/import` as const,
  export: (companyId: string) => `/companies/${companyId}/budgeting/loans/summary/export` as const,
  list: (companyId: string) => `/companies/${companyId}/budgeting/loans/existing` as const,
  detail: (companyId: string, loanId: number) => `/companies/${companyId}/budgeting/loans/${loanId}` as const,
  instalments: (companyId: string, loanId: number) =>
    `/companies/${companyId}/budgeting/loans/${loanId}/instalments` as const,
  corrections: (companyId: string, loanId: number) =>
    `/companies/${companyId}/budgeting/loans/${loanId}/corrections/` as const,
  correction: (companyId: string, loanId: number, correctionId: number) =>
    `/companies/${companyId}/budgeting/loans/${loanId}/corrections/${correctionId}` as const
}

export default loanEndpoints
