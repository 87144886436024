/* eslint-disable no-nested-ternary */
import i18next from 'i18next'
import dayjs from 'dayjs'
import React, { useLayoutEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { renderPeriod } from '../../../../../components/Table/utils'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { getCategoryVariableType } from '../utils'
import { CustomTree } from './CustomTree'
import { appTheme } from '../../../../../config/appTheme'
import { CustomReportCategory, CustomReportCategoryRow } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportVariable, CustomReportVariableRow } from '../../../../../redux/context/customReports/typesVariable'
import { evaluateReferenceValueRule } from '../../../keyFigures/utils'
import { VariableType } from '../../../../../components/Table/types'

export const getDataSourceCategories = (
  categoryTree: CustomTree,
  rows: CustomReportVariable[],
  displayEmptyRows: boolean
) => {
  const dataArray: CustomReportCategoryRow[] = categoryTree.contexRows.reduce(
    (cols, { company, dimension, func, periodGroup, leaf }) => {
      const balanceNode = func || leaf

      const dataValues = rows.reduce((acc: any, r) => {
        acc[(r.id || 0).toString()] = r?.balances?.find(balance => balance.groupId === balanceNode?.id)?.value
        return acc
      }, {})

      const filterRow = !displayEmptyRows && Object.values(dataValues).every(val => val === undefined)
      if (!filterRow) {
        cols.push({
          id: balanceNode?.id || -1,
          company,
          dimension,
          function: func,
          periodGroup,
          leaf: balanceNode,
          variableType: getCategoryVariableType(balanceNode),
          dataType: periodGroup?.dataType || ReportDataType.actuals,
          values: dataValues
        } as CustomReportCategoryRow)
      }
      return cols
    },
    [] as CustomReportCategoryRow[]
  )
  return dataArray
}

export const getDataSourceVariables = (
  categoryTree: CustomTree,
  variables: CustomReportVariable[],
  displayEmptyRows?: boolean
) => {
  const dataArray: CustomReportVariableRow[] = variables.reduce((vars, { children, params, ...variable }) => {
    const dataValues = categoryTree.contexRows.reduce((acc, { leaf, func }) => {
      const balanceNode = func || leaf

      acc[`${balanceNode?.id || 0}`.toString()] = variable?.balances?.find(
        balance => balance.groupId === balanceNode?.id
      )?.value
      return acc
    }, {} as { [key: string]: number | undefined })

    const filterRow =
      (params?.showEmptyRows === false || params?.showEmptyRows === undefined) &&
      Object.values(dataValues).every(val => val === undefined)

    if (!filterRow || displayEmptyRows) {
      vars.push({
        ...variable,
        id: variable.id!,
        values: dataValues,
        ...(!variable.account && {
          children:
            params?.expandable !== false
              ? children
                ? getDataSourceVariables(categoryTree, children, displayEmptyRows)
                : []
              : undefined // Set children as undefined if not expandable
        })
      })
    }
    return vars
  }, [] as CustomReportVariableRow[])
  return dataArray
}

export const renderTitle = (column: CustomReportVariable, variables: CustomReportVariable[]) => {
  let str = column.name || i18next.t(column.translation)
  if (column.params?.dimension) str = str.concat(` - ${column.params?.dimension?.name}`)
  if (column.params?.dataType) str = str.concat(` - ${i18next.t(`global:${column.params?.dataType}`)}`)
  if (column.period && renderPeriod(column, variables || [])) {
    str = str.concat(` (${i18next.t(`formulaPeriod:${column.period}`)})`)
  }
  return str.replaceAll('/', '/​')
}

export function extractDate(dateTimeString?: string) {
  if (!dateTimeString) return null
  // Define the regex pattern to match the date portion
  const pattern = /(\d{4}-\d{2}-\d{2})/

  // Use the match method to extract the date portion
  const match = dateTimeString.match(pattern)

  // If a match is found, extract the date part
  if (match) {
    return match[0] // Return the matched date portion
  }

  return null // Return null if no match is found (optional)
}

export const normalizeValue = (
  object: { startDate?: string; endDate?: string },
  value?: number
): number | undefined => {
  const { startDate, endDate } = object

  if (!startDate || !endDate || !value) {
    return value
  }

  const start = dayjs(extractDate(startDate))
  const end = dayjs(extractDate(endDate))

  if (!start.isValid() || !end.isValid()) {
    throw new Error('Invalid start or end date')
  }

  const monthsDifference = end.diff(start, 'month', true)

  if (monthsDifference < 0) {
    throw new Error('End date must be after start date')
  }

  return value / monthsDifference
}

export const getColor = (category: CustomReportCategory, record?: CustomReportVariableRow, value?: number) => {
  const { budgetingScenario: { forecastCursor } = {}, startDate } = category

  let bgColor

  if (forecastCursor) {
    bgColor = dayjs(forecastCursor) > dayjs(startDate) ? appTheme.forecastActualColor : appTheme.forecastColor
  }

  if (category.dataType === ReportDataType.budget) {
    bgColor = appTheme.budgetColor
  }

  if (category.color) {
    bgColor = category.color
  }

  const ignoredFunctions = ['diff', 'growth', 'commonSize', 'division', 'div']
  if (record?.style?.referenceValueRules && !ignoredFunctions.includes(category.value as string)) {
    if (value) {
      const foundRule = record.style?.referenceValueRules.find(rule =>
        record?.type === VariableType.absolute
          ? evaluateReferenceValueRule(rule, normalizeValue(category, value))
          : evaluateReferenceValueRule(rule, value)
      )
      if (foundRule) {
        bgColor = foundRule.color
      }
    }
  }

  return bgColor
}

export const useTableHeight = (ref: React.RefObject<any>, dataSource: any) => {
  // Keep the Table the height of the parent.
  const [tableHeight, setTableHeight] = useState<number>()
  const resizeTable = useDebouncedCallback(
    () => {
      const node = ref.current
      if (!node) {
        return
      }
      const { height } = node.getBoundingClientRect()
      const { height: headerHeight = 23 } = node?.querySelector('.ant-table-header')?.getBoundingClientRect() || {}
      // height of the content minus the header and footer
      setTableHeight(height - headerHeight)
    },
    100,
    {
      trailing: true,
      maxWait: 100
    }
  )

  useLayoutEffect(() => {
    resizeTable()
    window.addEventListener('resize', resizeTable)

    return () => {
      window.removeEventListener('resize', resizeTable)
    }
  }, [resizeTable, ref.current, dataSource])

  return tableHeight
}

export const getTitleByLanguage = (row: CustomReportVariableRow) => {
  if (i18next.language === 'fi' && row.nameFi) return row.nameFi
  if (i18next.language === 'en' && row.nameEn) return row.nameEn
  if (i18next.language === 'sv' && row.nameSv) return row.nameSv
  return row.name || i18next.t(row.translation)
}

export const showArrow = (value: number | undefined, category: CustomReportCategory, record: CustomReportVariable) => {
  if (
    value &&
    +value !== 0 &&
    (category.value === 'growth' || category.value === 'diff') &&
    record?.style?.thresholdValueSetting
  ) {
    return true
  }
  return false
}
