import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { createLoanInstalmentsCorrection } from '../service/loanService'
import { LoanInstalmentCorrection } from '../types/LoanInstallmentCorrection'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'

export const useCreateLoanInstalmentsCorrection = (loanId: number) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: (data: Partial<LoanInstalmentCorrection>) =>
      createLoanInstalmentsCorrection(data, companyId, loanId, budgetingScenario?.id)
  })
}
