import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { usePersonnelOptions } from './usePersonnel'
import { updatePersonnel } from '../service/personnelService'

export default function useUpdatePersonnel() {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = usePersonnelOptions()

  return useMutation({
    mutationFn: (personnel: Partial<KeyFigureFact>) => updatePersonnel(companyId, personnel),
    mutationKey: ['personnel'],
    retry: 2,
    onSuccess: updatedItem => {
      queryClient.setQueryData(options.queryKey, oldItems => {
        return oldItems?.map(item => (item.year === updatedItem.year ? updatedItem : item))
      })
    }
  })
}
