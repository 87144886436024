import { Button, Form, Input, Table, TableColumnsType, Typography } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React, { ReactNode } from 'react'
import { useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { DeleteOutlined } from '@ant-design/icons'
import { red } from '@ant-design/colors'
import { useTranslation } from 'react-i18next'
import { TransactionFormFields, VoucherFormFields } from './VoucherForm'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { calculateTotalTrasactionsValueCents } from './utils'
import { formatValueByType } from '../../../../utils/helpers'
import AccountSelect from '../../../../features/account/components/AccountSelect'

const { Text } = Typography

interface TransActionTableFormProps {
  add: () => void
  remove: (key: number) => void
  errors?: ReactNode[]
}

const TransactionTableForm: React.FC<TransActionTableFormProps> = ({ add, remove, errors }) => {
  const { t } = useTranslation()
  const form = useFormInstance<VoucherFormFields>()
  const transactions = useWatch('transactions', form)

  const columns: TableColumnsType<TransactionFormFields> = [
    {
      title: t('global:account'),
      dataIndex: 'id',
      hidden: true,
      render: (text, record, index) => (
        <FormItem noStyle name={[index, 'id']} hidden>
          <Input />
        </FormItem>
      )
    },
    {
      title: t('global:account'),
      dataIndex: 'accountCode',
      key: 'accountCode',
      width: 300,
      render: (text, record, index) => (
        <FormItem noStyle name={[index, 'accountCode']} rules={[{ required: true }]}>
          <AccountSelect style={{ width: '100%' }} />
        </FormItem>
      )
    },
    {
      title: t('global:description'),
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) => (
        <FormItem noStyle name={[index, 'description']} rules={[{ required: true }]}>
          <Input />
        </FormItem>
      )
    },
    {
      title: t('vouchersPage:debit'),
      dataIndex: 'debit',
      key: 'debit',
      width: 150,
      render: (text, record, index) => (
        <FormItem
          noStyle
          name={[index, 'debit']}
          rules={[
            {
              required: !form.getFieldValue(['transactions', index, 'credit'])
            }
          ]}
        >
          <FormattedInputNumber precision={2} min={0} style={{ width: '100%' }} />
        </FormItem>
      )
    },
    {
      title: t('vouchersPage:credit'),
      dataIndex: 'credit',
      key: 'credit',
      width: 150,
      render: (text, record, index) => {
        return (
          <FormItem
            noStyle
            name={[index, 'credit']}
            rules={[
              {
                required: !form.getFieldValue(['transactions', index, 'debit'])
              }
            ]}
          >
            <FormattedInputNumber precision={2} min={0} style={{ width: '100%' }} />
          </FormItem>
        )
      }
    },
    {
      title: t('global:actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (text, record, index) => (
        <Button danger type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
      )
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={transactions}
      pagination={false}
      locale={{ emptyText: 'Ei vientejä' }}
      summary={pageData => {
        const { totalCredit, totalDebit, difference } = calculateTotalTrasactionsValueCents(pageData)
        const format = (val: number) =>
          formatValueByType(val / 100, undefined, {
            maximumFractionDigits: 2
          })
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={7}>
                <Button type="dashed" block onClick={() => add()}>
                  {t('global:add')}
                </Button>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} index={0}>
                {t('customReportPage:sum')}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" index={1}>
                <Text>{format(totalDebit)}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" colSpan={1} index={2}>
                <Text>{format(totalCredit)}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} index={0}>
                {t('global:difference')}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right" colSpan={1} index={1}>
                <Text>{format(difference) || 0}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            {(errors?.length || 0) > 0 ? (
              <Table.Summary.Row style={{ backgroundColor: red[1] }}>
                <Table.Summary.Cell colSpan={5} index={0}>
                  <Form.ErrorList errors={errors} />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            ) : null}
          </>
        )
      }}
    />
  )
}

export default TransactionTableForm
