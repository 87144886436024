import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Account } from '../types/Account'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { updateAccount } from '../api/accounts'
import { accountQueryKeys } from './accountQueryKeys'

export const useUpdateAccount = () => {
  const { companyId } = useContextParameters()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (account: Account) => {
      if (!companyId) {
        throw new Error('Company ID is required')
      }
      return updateAccount(companyId, account)
    },
    onSuccess: updatedItem => {
      queryClient.setQueryData(accountQueryKeys.list(companyId), (oldItems: Account[]) => {
        return oldItems.map(item => (item.code === updatedItem.code ? updatedItem : item))
      })
    },
    onError: error => {
      console.error('Failed to create account:', error)
    }
  })
}
