import React, { Key, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Input, Popconfirm, Tooltip, TreeProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { DataNode } from 'antd/es/tree'
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import DirectoryTree from 'antd/es/tree/DirectoryTree'
import { customReportTreeSelector } from '../../../../../redux/context/customReports/selectors'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { contextRequest } from '../../../../../redux/context/actions'
import {
  deleteCustomReportGroupRequest,
  getCustomReportGroupsRequest,
  getCustomReportsRequest,
  updateCustomReportGroupRequest
} from '../../../../../redux/context/customReports/actions'
import { AppDispatch } from '../../../../../redux/store'
import { generateTreeDataNodes } from './utils'
import { addGroupHandler, saveStructureData } from './service'
import { notificationAction } from '../../../../../redux/middleware/actions'

export interface CustomReportData {
  id: number
  groupId?: number
  order?: number
  key?: string
  title: string
  name?: string
  companyId?: string
  children?: CustomReportData[]
  isLeaf?: boolean
  group?: boolean
}

export interface CustomReportOrderData {
  id: number
  order: number
  type: 'CustomReport' | 'CustomReportGroup'
  groupId: number | null
}

const CustomReportOrder: React.FC = () => {
  const { t } = useTranslation()
  const customReports = useSelector(customReportTreeSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const dispatch: AppDispatch = useDispatch()
  const structureRequest = useBackend('/api/companies/{companyId}/reporting/custom/reports/groups/structure')
  const addCustomReportGroupRequest = useBackend('/api/companies/{companyId}/reporting/custom/reports/groups')
  const [gData, setGData] = useState(customReports as CustomReportData[])
  const [groupName, setGroupName] = useState<string>('')
  const [changedRows, setChangedRows] = useState<number[]>([])

  const recursiveFindAndAddChildren = async (
    data: CustomReportData[],
    path: number | string | bigint
  ): Promise<CustomReportData[] | undefined> => {
    if (!data || !path) return undefined

    const newData =
      data.map(async item => {
        if (item.key === path) {
          const addedGroup =
            companyId &&
            (await addGroupHandler(
              {
                companyId,
                groupId: item.id,
                isLeaf: undefined,
                name: groupName
              },
              addCustomReportGroupRequest,
              companyId,
              dispatch
            ))
          return {
            ...item,
            children: [
              ...((item.children && item.children.length > 0 && item.children) || []),
              { ...addedGroup, group: true }
            ]
          }
        }
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: await recursiveFindAndAddChildren(item.children, path)
          }
        }
        return item
      }) || []
    return newData && Promise.all(newData)
  }

  const addGroup = async (path?: Key) => {
    if (!path) {
      const addedGroup =
        companyId &&
        (await addGroupHandler(
          {
            companyId,
            name: groupName
          },
          addCustomReportGroupRequest,
          companyId,
          dispatch
        ))
      customReports && setGData(generateTreeDataNodes([...gData, { ...addedGroup, group: true }]))
    } else {
      const newData = await recursiveFindAndAddChildren(gData, path)
      newData && setGData(generateTreeDataNodes(newData as CustomReportData[]))
    }
    setGroupName('')
  }

  useEffect(() => {
    companyId && dispatch(contextRequest(getCustomReportsRequest))
    companyId && dispatch(contextRequest(getCustomReportGroupsRequest))
  }, [companyId])

  useEffect(() => {
    customReports && setGData(generateTreeDataNodes(customReports as CustomReportData[]))
    return () => {
      setGData([])
    }
  }, [customReports])

  const onDrop: TreeProps['onDrop'] = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: CustomReportData[],
      key: React.Key,
      callback: (node: CustomReportData, i: number, data: CustomReportData[]) => void
    ) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
      return true
    }
    const data = [...gData]

    let dragObj: CustomReportData
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else if (
      ((info.node as any).props.children || []).length > 0 &&
      (info.node as any).props.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        item.children?.unshift(dragObj)
      })
    } else {
      let ar: CustomReportData[] = []
      let i: number
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }
    const changedRow: any = info.dragNode
    setChangedRows([...changedRows, changedRow.id])

    setGData(data)
    companyId && saveStructureData(data, [...changedRows, changedRow.id], companyId, dispatch, structureRequest)
  }

  const addNewGroup = (path?: Key) => {
    return (
      <Tooltip title={t('customReportPage:addReportGroup')}>
        <Popconfirm
          title={t('customReportPage:reportGroupName')}
          description={<Input style={{ width: 300 }} value={groupName} onChange={e => setGroupName(e.target.value)} />}
          onConfirm={() => addGroup(path)}
          onCancel={() => setGroupName('')}
          okText={t('global:save')}
          cancelText={t('global:cancel')}
        >
          {path ? (
            <PlusCircleOutlined style={{ fontSize: 17 }} />
          ) : (
            <Button style={{ marginLeft: 8 }} type="primary">
              {t('customReportPage:addReportGroupToRoot')}
            </Button>
          )}
        </Popconfirm>
      </Tooltip>
    )
  }

  const editGroup = (id: number, title: string) => {
    let editGroupName = title
    return (
      <>
        <Divider type="vertical" />
        <Tooltip title={t('customReportPage:editReportGroupName')}>
          <Popconfirm
            title={t('customReportPage:reportGroupName')}
            description={
              <Input
                style={{ width: 300 }}
                defaultValue={editGroupName}
                onChange={e => {
                  editGroupName = e.target.value
                }}
              />
            }
            onConfirm={() => {
              dispatch(
                notificationAction({
                  type: 'success',
                  message: 'EDIT_CUSTOM_REPORT_GROUP_SUCCESS'
                })
              )
              companyId &&
                dispatch(
                  updateCustomReportGroupRequest(companyId, id, {
                    name: editGroupName
                  })
                )
            }}
            okText={t('global:edit')}
            cancelText={t('global:cancel')}
            onCancel={() => {
              editGroupName = title
            }}
          >
            <EditOutlined style={{ fontSize: 17, color: 'black' }} />
          </Popconfirm>
        </Tooltip>
      </>
    )
  }

  const deleteGroup = (id: number, children?: CustomReportData['children']) => {
    return (
      <>
        <Divider type="vertical" />
        <Tooltip title={t('customReportPage:deleteReportGroup')}>
          <Popconfirm
            title={t('global:delete-confirm')}
            onConfirm={() => {
              dispatch(
                notificationAction({
                  type: 'success',
                  message: 'DELETE_CUSTOM_REPORT_GROUP_SUCCESS'
                })
              )
              companyId && dispatch(deleteCustomReportGroupRequest(companyId, id))
            }}
            okText={t('global:yes')}
            cancelText={t('global:no')}
            disabled={children && children?.length > 0}
          >
            <DeleteOutlined
              style={{
                fontSize: 17,
                color: children && children?.length > 0 ? 'grey' : 'red'
              }}
            />
          </Popconfirm>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <DirectoryTree
        className="draggable-tree"
        draggable
        blockNode
        onDrop={onDrop}
        selectable={false}
        expandAction={false}
        showLine
        allowDrop={({ dropNode, dropPosition }) => {
          if (dropNode.isLeaf && dropPosition === 0) {
            return false
          }
          return true
        }}
        treeData={gData as DataNode[]}
        titleRender={cat => (
          <span style={{ color: !cat.isLeaf ? '#0225a1' : undefined, height: 90, fontSize: 15 }}>
            {`${cat.title}  `}
            <span style={{ float: 'right', marginRight: 50 }}>
              {!cat.isLeaf && [
                addNewGroup(cat.key),
                editGroup((cat as CustomReportData).id, (cat as CustomReportData).title),
                deleteGroup((cat as CustomReportData).id, (cat as CustomReportData).children)
              ]}
            </span>
          </span>
        )}
      />
      <div style={{ marginTop: 20, float: 'right', marginRight: 50 }}>{addNewGroup()}</div>
    </>
  )
}

export default CustomReportOrder
