import React from 'react'
import { Button, Popconfirm, Space, Table, TableColumnsType } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import Authorized from '../../../components/Authorized/Authorized'
import { useColumnSearchProps } from '../../../components/Table/useColumSearchProps'
import { TaskApplication } from '../../../pages/superuser/types'
import { isGroupCompany } from '../../../routes/utils'
import { propertySorterProps } from '../../../utils/helpers'
import { useFetchAccounts } from '../queries/useFetchAccounts'
import { Account } from '../types/Account'
import ConditionalRender from '../../../components/Misc/ConditionalRender'

interface AccountTableProps {
  onEdit: (account: Account) => void
  onDelete: (account: Account) => void
}

const AccountTable: React.FC<AccountTableProps> = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()
  const { data: accounts, isLoading: accountsLoading } = useFetchAccounts<Account[]>()

  const columns: TableColumnsType<Account> = [
    {
      title: t('vouchersPage:accountCode'),
      dataIndex: 'code',
      width: '20%',
      sorter: propertySorterProps('code'),
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      ...useColumnSearchProps('code')
    },
    {
      title: t('global:name'),
      dataIndex: 'name',
      width: '40%',
      sorter: propertySorterProps('name'),
      sortDirections: ['descend', 'ascend'],
      ...useColumnSearchProps('name')
    },
    {
      title: t('global:accountGroup'),
      dataIndex: 'statementRowId',
      width: '40%',
      sorter: (a, b) => {
        const aName = t(`balanceSheet:${a.statementRowId}`, t(`incomeStatement:${a.statementRowId}`, ''))
        const bName = t(`balanceSheet:${b.statementRowId}`, t(`incomeStatement:${b.statementRowId}`, ''))
        return aName.localeCompare(bName)
      },
      ...useColumnSearchProps('statementRowId', value => t(`balanceSheet:${value}`, t(`incomeStatement:${value}`, '')))
    },
    {
      key: 'actions',
      title: t('global:actions'),
      width: '40%',
      hidden: isGroupCompany(),
      render: (account: Account) => (
        <Authorized authority={{ permission: 'write-accounts' }}>
          <Space>
            <Button size="small" type="text" icon={<EditOutlined onClick={() => onEdit(account)} />} />
            <ConditionalRender render={account.source === TaskApplication.FINADECK}>
              <Popconfirm
                title={t('global:delete-confirm')}
                onConfirm={() => onDelete(account)}
                okText={t('global:yes')}
                cancelText={t('global:no')}
              >
                <Button size="small" type="text" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </ConditionalRender>
          </Space>
        </Authorized>
      )
    }
  ]

  return (
    <Table
      loading={accountsLoading}
      dataSource={accounts}
      columns={columns}
      rowKey="code"
      pagination={{
        pageSize: 50
      }}
    />
  )
}

export default AccountTable
