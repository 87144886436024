import { DatePicker, Button, TreeSelect, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BuildOutlined, FileOutlined, FolderOutlined } from '@ant-design/icons'
import QuickFilters from './QuickFilters'
import {
  calculatedReportSelector,
  customReportDateSelector,
  customReportTreeSelector,
  customReportsSelector
} from '../../../../redux/context/customReports/selectors'
import { setCustomReportDate } from '../../../../redux/context/customReports/actions'
import { CustomReportData } from './settings/CustomReportOrder'
import { LayoutsContext, LayoutsContextType } from './layout/LayoutContext'
import { CustomReport } from '../../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { BackendRequest } from '../../../../services/backend'
import { fetchReportData } from '../service'
import { firstReport } from './utils'

interface PageHeaderExtraProps {
  getReportDataRequest: BackendRequest
  handleReportChange: (report?: CustomReport) => void
  itemsLoading?: number[]
}

interface TreeNode {
  value: string | number // Represents the unique identifier of the node.
  title: string // The display title for the node.
  children?: TreeNode[] // Array of child nodes, optional.
  disabled?: boolean // Whether the node is disabled.
  disableCheckbox?: boolean // Whether the checkbox for the node is disabled.
  selectable?: boolean // Whether the node is selectable.
  checkable?: boolean // Whether the node is checkable.
}

const generateTreeData = (customReportNodes: CustomReportData[]): TreeNode[] => {
  return customReportNodes.map(customReportNode => {
    const { group, id, title, children } = customReportNode

    return {
      title,
      value: id,
      icon: group ? <FolderOutlined /> : <FileOutlined />,
      selectable: !group,
      style: { color: !group ? 'rgba(0, 0, 0, 0.98)' : 'rgba(0, 0, 0, 0.78)' },
      children: children && children.length > 0 ? generateTreeData(children) : undefined
    }
  })
}

const PageHeaderExtra: React.FC<PageHeaderExtraProps> = ({
  itemsLoading,
  getReportDataRequest,
  handleReportChange
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const calculatedReport = useSelector(calculatedReportSelector)
  const customReportsTree = useSelector(customReportTreeSelector)
  const date = useSelector(customReportDateSelector)
  const navigate = useNavigate()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { setLayoutEdit } = useContext(LayoutsContext) as LayoutsContextType
  const customReports = useSelector(customReportsSelector)
  const [selectDisabled, setSelectDisabled] = useState(false)
  const treeData = useMemo(() => generateTreeData(customReportsTree), [customReportsTree])

  const setDate = (newDate: string) => {
    dispatch(setCustomReportDate(newDate))
  }

  const onReportChange = (id: number) => {
    setSelectDisabled(true)
    const report = customReports.find(r => r.id === Number(id))
    handleReportChange(report)
    setTimeout(() => {
      setSelectDisabled(false)
    }, 3000)
  }

  useEffect(() => {
    if (
      !calculatedReport?.id &&
      customReports.length > 0 &&
      customReportsTree.length > 0 &&
      !getReportDataRequest.loading &&
      !getReportDataRequest.error?.current
    ) {
      setSelectDisabled(true)
      const fetchReport = firstReport(customReportsTree) || customReports?.[0]
      fetchReportData(dispatch, getReportDataRequest, companyId, fetchReport)
      setTimeout(() => {
        setSelectDisabled(false)
      }, 3000)
    }
  }, [customReportsTree])

  return (
    <Space>
      <QuickFilters />
      <TreeSelect
        value={calculatedReport?.id}
        showSearch
        treeData={treeData}
        treeIcon
        disabled={selectDisabled}
        loading={itemsLoading && itemsLoading?.length > 0}
        filterTreeNode={(inputval, node) => {
          return node && (node.title as string)?.toLowerCase().includes(inputval?.toLowerCase())
        }}
        onChange={onReportChange}
        style={{ width: 300 }}
        treeLine
      />
      <Button icon={<BuildOutlined />} onClick={() => setLayoutEdit(true)} />
      <Button onClick={() => navigate('/settings/custom-report/order')}>{t('header:settings')}</Button>
      <DatePicker
        style={{ width: 100, height: '100%' }}
        key="datePicker"
        showWeek
        allowClear={false}
        defaultValue={dayjs(date)}
        onChange={newDate => newDate && setDate(newDate.toString())}
      />
    </Space>
  )
}

export default PageHeaderExtra
