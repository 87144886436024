import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDimensionDashboard } from '../service/dashboardService'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { AppDispatch } from '../../../redux/store'
import { compactDimensionDashboarSelector, useDimensionDefaultDashboardsOptions } from './useDimensionDashboards'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { getPrevOrNextId } from '../utils'
import useDeleteQueryItem from '../../../services/api/hooks/useDeleteQueryItem'

export const useDeleteDimensionCompactDashboard = () => {
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const deleteQueryItem = useDeleteQueryItem()

  const queryClient = useQueryClient()
  const options = useDimensionDefaultDashboardsOptions()

  return useMutation({
    mutationFn: (id: number) => deleteDimensionDashboard({ companyId, id }),
    onSuccess: newItem => {
      deleteQueryItem(newItem, options)
      const data = compactDimensionDashboarSelector(queryClient.getQueryData(options.queryKey))
      dispatch(setMultiPurposeDashboardActiveDashboard(getPrevOrNextId(newItem, data)))
    }
  })
}
