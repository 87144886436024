import { useState } from 'react'
import { App } from 'antd'
import { autoDownload } from '../../../utils/helpers'
import { fetchLoanTemplate } from '../service/loanService'

export const useLoanTemplate = () => {
  const { message } = App.useApp()
  const [isLoading, setIsLoading] = useState(false)

  const handleExport = async () => {
    setIsLoading(true)
    try {
      const data = await fetchLoanTemplate()
      autoDownload(`loan-template.xlsx`, data)
    } catch (err) {
      message.error('Failed to download file')
    } finally {
      setIsLoading(false)
    }
  }

  return { download: handleExport, isLoading }
}
