import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import MultiPurposeDashboard from './MultiPurposeDashboard'
import { Loading } from '../../../components/Misc/Loading'
import { useDimensionDefaultDashboardsPage } from '../../../features/dashboards'

const MultiPurposeDashboardDefault: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboards, isPending } = useDimensionDefaultDashboardsPage()
  const dimensionList = useSelector(dimensionListSelector)
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)

  const activeDashboardData = useMemo(
    () => dashboards?.find(d => d.id === activeDashboard),
    [activeDashboard, dashboards]
  )

  if (isPending) return <Loading />

  return (
    <MultiPurposeDashboard
      pageTitle={`${t('menu:/dashboard')} - ${t('menu:/dashboard/dimensions')} ${t(`dashboardPage:default`)}`}
      settingsUrl="/settings/dashboards/dimensionsDefault/order"
      allRows={dimensionList}
      dashboards={dashboards}
      selectedKeyfigureIds={activeDashboardData?.keyFigures || []}
      selectedInternalKeyfigureIds={activeDashboardData?.formulas || []}
      selectedRows={activeDashboardData?.dimensions}
    />
  )
}

export default MultiPurposeDashboardDefault
