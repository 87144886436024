import { DashStyleValue } from 'highcharts'
import { VariableType } from '../../../components/Table/types'
import { ChartYAxis } from '../../../pages/reporting/custom/components/chart/types'
import { Dimension } from '../../../types/dimension/Dimension'
import { Period } from '../../../types/fiscalYear/FiscalYear'
import { ReportDataType, Balance } from '../reports/types'
import { CustomReportCategory } from './typesCategory'
import { ReferenceValueRule } from '../../../pages/reporting/keyFigures/components/referenceValueTable/types'

export interface VariableParams {
  dimension?: Partial<Dimension>
  dataType?: ReportDataType.actuals | ReportDataType.budget | ReportDataType.forecast
  budgetingScenario?: { id: string }
  month?: number
  offset?: number
  expandable?: boolean
  showEmptyRows?: boolean
}

export interface CustomReportVariableExplainer {
  variables: CustomReportVariable[]
  commonSizeVariables: CustomReportVariable[]
  categories: CustomReportCategory[]
}

export enum ThresholdValueSetting {
  biggerBetter = 'biggerBetter',
  smallerBetter = 'smallerBetter',
  unset = 'unset'
}

export interface CustomReportVariableStyle {
  color?: string
  dashStyle?: DashStyleValue
  zIndex?: number
  decimals?: number
  fontWeight?: 'bold'
  fontStyle?: 'italic' | 'normal'
  yAxis?: ChartYAxis
  thresholdValueSetting: ThresholdValueSetting
  referenceValueRules?: ReferenceValueRule[]
  indentLevel?: number
}

export interface CustomReportVariable {
  id?: number
  sectionId: number
  type?: VariableType
  keyFigureId?: number
  formulaId?: number
  source?: string
  name: string
  translation: string
  account?: boolean
  parentId?: number
  children?: CustomReportVariable[]
  order?: number
  bold?: boolean
  style?: CustomReportVariableStyle
  balances?: Balance[]
  params?: VariableParams
  explanation?: any
  period?: Period
  dataType?: ReportDataType.actuals | ReportDataType.budget | ReportDataType.forecast
  nameFi?: string
  nameEn?: string
  nameSv?: string
}

export type CustomReportVariableRow = Omit<CustomReportVariable, 'children'> & {
  id: number
  children?: CustomReportVariableRow[]
  variableType?: VariableType
  values: {
    [key: string]: number | undefined
  }
}

export type VariableFormValues = Pick<
  CustomReportVariable,
  | 'name'
  | 'type'
  | 'keyFigureId'
  | 'formulaId'
  | 'order'
  | 'source'
  | 'style'
  | 'params'
  | 'id'
  | 'nameFi'
  | 'nameEn'
  | 'nameSv'
>
