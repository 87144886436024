import { Form, notification } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React, { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addSessionContractChildren, updateSessionContractChildren } from '../../../redux/session/contract/actions'
import { addCurrentUserCompany } from '../../../redux/session/currentUser/actions'
import { currentUserIdSelector } from '../../../redux/session/currentUser/selectors'
import { useBackend } from '../../../services/backend'
import { Company } from '../../../types/company/Company'
import { Contract, ContractFormStep } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import { AppDispatch } from '../../../redux/store'

export enum CompanyType {
  normal = 'normal',
  imaginary = 'imaginary',
  group = 'group',
  elimination = 'elimination'
}

export type ContractFormContextType = {
  form: FormInstance
  company: Company | null
  companyType: CompanyType
  showCompanyAdminForm: boolean
  currentStep: ContractFormStep
  loading: boolean
  error: any
  setCompanyType: (value: CompanyType) => void
  setStep: (value: ContractFormStep) => void
  handleCompanyChange: (comp: Company | null) => void
  toggleCompanyAdminForm: () => void
  resetForm: () => void
  handleCreateContract: () => void
  handleUpdateContract: () => void
}

export const ContractFormContext = React.createContext<ContractFormContextType | null>(null)

interface ContractFormProviderProps {
  initialStep?: ContractFormStep
  children?: ReactNode
}

const ContractFormProvider: React.FC<ContractFormProviderProps> = ({ children, initialStep = 0 }) => {
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()

  const [companyType, setCompanyType] = useState(CompanyType.normal)
  const [currentStep, setCurrentStep] = useState<ContractFormStep>(initialStep)
  const [company, setCompany] = useState<Company | null>(null)
  const createContractRequest = useBackend('api/contracts/company')
  const updateContractRequest = useBackend('api/contracts/{contractId}')
  const [showCompanyAdminForm, setShowCompanyAdminForm] = useState(false)
  const currentUserId = useSelector(currentUserIdSelector)

  const toggleCompanyAdminForm = () => {
    form.resetFields(['masterUser'])
    setShowCompanyAdminForm(!showCompanyAdminForm)
  }

  const handleCompanyChange = (comp: Company | null) => {
    setCompany(comp)
  }

  const setStep = (step: ContractFormStep) => {
    if (step > 0 || currentStep !== ContractFormStep.Order) {
      form.validateFields().then(() => {
        setCurrentStep(currentStep + step)
      })
    } else {
      setCurrentStep(currentStep + step)
    }
  }

  const resetForm = () => {
    form.resetFields()
    setCurrentStep(initialStep)
    setCompany(null)
  }

  const handleCreateContract = async () => {
    try {
      await form.validateFields()
      const allFormFieldValues = await form.getFieldsValue(true)
      const values = {
        ...allFormFieldValues,
        users: allFormFieldValues.users.map((id: User['id']) => ({ id }))
      }
      if (!showCompanyAdminForm) {
        delete values.masterUser
      }
      if (values?.contract?.company?.id) {
        values.contract.company.id = values.contract.company.id.trim()
      }

      const contract = await createContractRequest.post({ body: { data: values } })
      const { company: newCompany } = contract

      dispatch(addSessionContractChildren(contract))
      if (allFormFieldValues.users.some((id: User['id']) => id === currentUserId)) {
        dispatch(addCurrentUserCompany({ ...newCompany, contract }))
      }
    } catch (e) {
      notification.error({
        message: (e as any).message
        // description: e.message
      })
    } finally {
      setStep(1)
    }
  }

  const handleUpdateContract = async () => {
    try {
      await form.validateFields()
      const { contract: newContract }: { contract: Contract } = form.getFieldsValue(true)
      const { id, product, additionalInformation, signed } = newContract

      const contract = await updateContractRequest.put({
        urlParams: { contractId: id },
        body: { data: { product, additionalInformation, signed } }
      })

      dispatch(updateSessionContractChildren(contract))
    } catch (e) {
      notification.error({
        message: (e as any).message
        // description: e.message
      })
    } finally {
      setStep(ContractFormStep.Product)
    }
  }

  return (
    <ContractFormContext.Provider
      value={{
        form,
        company,
        companyType,
        showCompanyAdminForm,
        currentStep,
        loading: createContractRequest.loading || updateContractRequest.loading,
        error: createContractRequest.error.current || updateContractRequest.error.current,
        setCompanyType,
        setStep,
        handleCompanyChange,
        toggleCompanyAdminForm,
        resetForm,
        handleCreateContract,
        handleUpdateContract
      }}
    >
      {children}
    </ContractFormContext.Provider>
  )
}

export default ContractFormProvider
