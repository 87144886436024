import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'

type LoanSummaryParams = {
  companyId: string
  isExisting: boolean
  date: string | null
  budgetingScenarioId: BudgetingScenario['id'] | undefined
}

type LoanDetailParams = {
  companyId: string
  loanId: number
  budgetingScenarioId?: number
}

const loanQueryKeys = {
  all: [{ scope: 'loan' }] as const,
  summaries: () => [{ ...loanQueryKeys.all[0], entity: 'summary' }] as const,
  summary: (params: LoanSummaryParams) => [{ ...loanQueryKeys.summaries()[0], ...params }] as const,
  detail: (params: LoanDetailParams) => [{ ...loanQueryKeys.all[0], entity: 'detail', ...params }] as const,
  instalments: (params: LoanDetailParams) => [{ ...loanQueryKeys.detail(params)[0], entity: 'instalments' }] as const,
  corrections: (params: LoanDetailParams) => [{ ...loanQueryKeys.detail(params)[0], entity: 'corrections' }] as const,
  correction: (params: LoanDetailParams & { correctionId: number }) =>
    [{ ...loanQueryKeys.detail(params)[0], correctionId: params.correctionId, entity: 'correction' }] as const
}

export default loanQueryKeys
