import { useQueryClient, useMutation } from '@tanstack/react-query'
import { MultiDashboard } from '../types/MultiDashboard'
import { useCompaniesDashboardsOptions } from './useCompaniesDasboards'
import { updateCompaniesDashboard } from '../service/dashboardService'

export const useUpdateCompaniesDashboard = () => {
  const queryClient = useQueryClient()
  const options = useCompaniesDashboardsOptions()

  return useMutation({
    mutationFn: ({ data, id }: { data: Partial<MultiDashboard>; id: number }) => updateCompaniesDashboard(data, id),
    onSuccess: newItem => {
      queryClient.setQueryData(options.queryKey, (oldItems = []) => {
        return oldItems.map(item => (item.id === newItem.id ? { ...item, ...newItem } : item))
      })
    }
  })
}
