import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { updateLoan } from '../service/loanService'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { Loan } from '../types/Loan'

export const useUpdateLoan = () => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const { budgetingScenario } = useSelector(filtersSelector)

  return useMutation({
    mutationFn: (data: Loan) => updateLoan(data, companyId, data.id, budgetingScenario?.id)
  })
}
