import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Account } from '../types/Account'
import { Language } from '../../../types/user/User'
import { useContextParameters } from '../../../services/api/hooks/useContextParameters'
import { getAccounts } from '../api/accounts'
import { accountQueryKeys } from './accountQueryKeys'
import { accountByCodeSelector, combinedSelector, accountsSelector } from '../selectors/selectors'

type SelectorFunction<TData, Params> = (data: Account[], params: Params) => TData

interface FetchAccountsProps {
  code?: number
  lng?: Language
}

export const useFetchAccounts = <TData, Params extends FetchAccountsProps = FetchAccountsProps>(
  selectFunction?: SelectorFunction<TData, Params>,
  params?: Params
): UseQueryResult<TData> => {
  const { companyId, lng } = useContextParameters()
  const finalParams = {
    code: params?.code,
    lng
  } as Params

  return useQuery({
    queryKey: accountQueryKeys.list(companyId),
    queryFn: async () => {
      if (!companyId) {
        throw new Error('Company ID is required.')
      }
      return getAccounts(companyId)
    },
    enabled: !!companyId,
    gcTime: 1000 * 60,
    staleTime: 1000 * 60,
    select: data => (selectFunction ? selectFunction(data, finalParams) : (data as TData))
  })
}

export const useAccount = (code?: number) => {
  return useFetchAccounts(accountByCodeSelector, { code })
}

export const useAccounts = () => {
  return useFetchAccounts(accountsSelector)
}

export const useAccountsArgumentOptions = () => {
  return useFetchAccounts(combinedSelector)
}
