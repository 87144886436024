import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import './_KeyFigureChart.sass'
import { ChartType } from '../../../../../components/Chart/types'

interface ChartTypeSelectProps {
  graphType: ChartType
  selectGraphType: (e: ChartType) => void
}

const ChartTypeSelect: React.FC<ChartTypeSelectProps> = ({ graphType, selectGraphType }: ChartTypeSelectProps) => {
  const { t } = useTranslation()
  return (
    <div className="tool-bar" style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
      <Select style={{ width: 200, marginBottom: 5 }} defaultValue={graphType} onChange={e => selectGraphType(e)}>
        {Object.values(ChartType).map(type => (
          <Select.Option key={type} value={type}>
            {t(`global:${type}`)}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default ChartTypeSelect
