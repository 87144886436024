import { FormInstance, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountForm from './AccountForm'
import { useAccount } from '../queries/useFetchAccounts'
import { Account } from '../types/Account'

interface AccountFormModalProps {
  open: boolean
  onCreate: (values: Account) => void
  onUpdate: (values: Account) => void
  onCancel: () => void
  initialValues?: Account
}

const AccountFormModal: React.FC<AccountFormModalProps> = ({ open, onCreate, onUpdate, onCancel, initialValues }) => {
  const [formInstance, setFormInstance] = useState<FormInstance>()
  const { data: account } = useAccount(initialValues?.code)
  const { t } = useTranslation()

  return (
    <Modal
      open={!!open}
      title={!initialValues ? t('actions:createNew', { what: t('global:account') }) : t('global:edit')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      okButtonProps={{ autoFocus: true }}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields()
          if (initialValues) {
            onUpdate(values)
          } else {
            onCreate(values)
          }
        } catch (error) {
          console.log('Failed:', error)
        }
      }}
    >
      <AccountForm
        initialValues={account}
        onFormInstanceReady={instance => {
          setFormInstance(instance)
        }}
      />
    </Modal>
  )
}

export default AccountFormModal
