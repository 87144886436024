import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Collapse, CollapseProps, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import ReportTable from '../../../components/Table/ReportTable'
import { ReportTableRow } from '../../../components/Table/types'
import { loadingSelector } from '../../../redux/loading/selectors'
import { formatValueByType } from '../../../utils/helpers'
import KeyFigureChart from '../../reporting/keyFigures/components/keyFigureChart/KeyFigureChart'
import { ReportRow } from '../../../redux/context/reports/types'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { Dimension } from '../../../types/dimension/Dimension'
import { flattenObject, getKeyfigureRows } from './utils'
import { Formula } from '../../../types/formula/Formula'
import { getCompanysKeyFigureIds } from '../../../utils/keyFigures'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import { ChartType } from '../../../components/Chart/types'

interface MultiPurposeDashboardDefaultSectionsProps {
  showCharts: boolean
  keyfigureStatements: { [key: string]: ReportRow[] }
  selectedKeyfigureIds: number[]
  selectedInternalKeyfigureIds: number[]
  allRows: Dimension[] | BudgetingScenario[]
  selectedRows?: string[]
  dashboardType: 'scenarios' | 'dimensions'
}

const MultiPurposeDashboardDefaultSections: React.FC<MultiPurposeDashboardDefaultSectionsProps> = ({
  showCharts,
  keyfigureStatements,
  selectedKeyfigureIds,
  selectedInternalKeyfigureIds,
  allRows,
  selectedRows,
  dashboardType
}) => {
  const loading = useSelector(loadingSelector)
  const company = useSelector(contextCompanySelector)
  const { t } = useTranslation()
  const renderCell = (text: any, reportTableRow: ReportTableRow) => {
    if (text) return formatValueByType(text, reportTableRow.variableType || reportTableRow.type)
    return undefined
  }

  const getReportData = (
    ids: number[],
    type: 'keyFigure' | 'internalKeyFigure',
    statements: { [key: string]: ReportRow[] }
  ) => {
    return getKeyfigureRows(ids, type, statements, allRows, selectedRows || [], dashboardType)
  }

  const keyFigureCharts = useMemo(
    () =>
      selectedKeyfigureIds?.map((selectedKeyfigureId: number) => ({
        key: selectedKeyfigureId,
        label: t(`globalKeyFigureStatement:${selectedKeyfigureId}`),
        children: (
          <KeyFigureChart
            chartType={ChartType.column}
            report={getReportData(
              getCompanysKeyFigureIds(company, [selectedKeyfigureId]),
              'keyFigure',
              keyfigureStatements
            )}
          />
        )
      })),
    [selectedKeyfigureIds, keyfigureStatements, allRows, selectedRows]
  )

  const internalKeyFigureCharts = useMemo(
    () =>
      selectedInternalKeyfigureIds
        ?.map((id: number) => flattenObject(keyfigureStatements).find(f => f?.id === id))
        .filter((f: Formula) => !!f)
        ?.map((formula: Formula) => ({
          key: formula.name,
          label: formula.name,
          children: (
            <KeyFigureChart
              chartType={ChartType.column}
              report={getReportData([formula.id], 'internalKeyFigure', keyfigureStatements)}
            />
          )
        })),
    [selectedInternalKeyfigureIds, keyfigureStatements, allRows, selectedRows]
  )

  const collapseItems: CollapseProps['items'] = [...keyFigureCharts, ...internalKeyFigureCharts]

  const reportData = [
    ...getReportData(getCompanysKeyFigureIds(company, selectedKeyfigureIds), 'keyFigure', keyfigureStatements),
    ...getReportData(selectedInternalKeyfigureIds, 'internalKeyFigure', keyfigureStatements)
  ]

  return (
    <>
      <LoadingWrapper loading={loading.fiscalYears || loading.dimensions || false}>
        <ReportTable
          toolbar={{
            hidden: true
          }}
          yScroll={window.innerHeight * 0.5}
          renderCell={renderCell}
          rowClassName={record => record.className}
          report={reportData}
        />
        {showCharts && (
          <>
            <Divider />
            <Collapse items={collapseItems} />
          </>
        )}
      </LoadingWrapper>
    </>
  )
}

export default MultiPurposeDashboardDefaultSections
