import React from 'react'
import classNames from 'classnames'
import { WhiteLabel, customerThemes } from '../../config/customerThemes'

type Props = {
  type?: WhiteLabel
  style?: React.CSSProperties
}

const Logo = ({ type = 'app', style }: Props) => {
  const logoType: WhiteLabel = type in customerThemes ? (type as WhiteLabel) : ('app' as WhiteLabel)

  const LogoComponent = customerThemes[logoType].logo

  if (LogoComponent === null || LogoComponent === undefined) {
    return null
  }

  if (typeof LogoComponent === 'function') {
    return LogoComponent
  }

  return (
    <img
      src={customerThemes[logoType].logo as string}
      alt={logoType}
      className={classNames('logo', logoType)}
      style={style}
    />
  )
}

export default Logo
