import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import MultiPurposeDashboard from './MultiPurposeDashboard'
import Loading from '../../../shared/components/Loading'
import { multiPurposeDashboardPageSelector } from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { useDimensionCompactDashboardsPage } from '../../../features/dashboards'

const MultiPurposeDashboardCompact: React.FC = () => {
  const { t } = useTranslation()
  const { data: dashboards, isPending } = useDimensionCompactDashboardsPage()
  const dimensionList = useSelector(dimensionListSelector)
  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)

  const activeDashboardData = useMemo(
    () => dashboards?.find(d => d.id === activeDashboard),
    [activeDashboard, dashboards]
  )

  if (isPending) return <Loading />

  return (
    <MultiPurposeDashboard
      pageTitle={`${t('menu:/dashboard')} - ${t('menu:/dashboard/dimensions')} ${t(`dashboardPage:compact`)}`}
      settingsUrl="/settings/dashboards/dimensionsCompact/order"
      dashboards={dashboards}
      allRows={dimensionList}
      selectedKeyfigureIds={activeDashboardData?.keyFigures || []}
      selectedInternalKeyfigureIds={activeDashboardData?.formulas || []}
      selectedRows={activeDashboardData?.dimensions}
    />
  )
}
export default MultiPurposeDashboardCompact
