import { ProCard } from '@ant-design/pro-components'
import { Col, Row, Statistic, theme } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InvestmentsPageSummary } from '../../../redux/pages/investments/types'

interface InvestmentSummaryProps {
  summary?: InvestmentsPageSummary
}

const summaryFields: Array<keyof InvestmentsPageSummary> = [
  'actualResidual',
  'budgetResidual',
  'difference',
  'taxResidual'
]

const InvestmentSummary: React.FC<InvestmentSummaryProps> = ({ summary }) => {
  const { t, i18n } = useTranslation()
  const {
    token: { colorBorder }
  } = theme.useToken()

  const colProps = {
    xs: 12,
    md: 6
  }

  return (
    <ProCard>
      <Row gutter={24}>
        {summaryFields.map((key, i, arr) => (
          <Col
            key={key}
            {...colProps}
            style={i < arr.length - 1 ? { borderRight: '1px solid', borderColor: colorBorder } : undefined}
          >
            {summary?.[key] !== undefined && (
              <Statistic
                title={`${t(`investmentsPage:${key}`)}`}
                value={summary[key]}
                suffix={t('global:currency-symbol')}
                formatter={val => {
                  return val.toLocaleString(i18n.language, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </ProCard>
  )
}

export default InvestmentSummary
