import React, { useContext, useState } from 'react'
import { Cascader } from 'antd/es'
import { useSelector } from 'react-redux'
import { FolderOutlined } from '@ant-design/icons'
import { customReportTreeSelector } from '../../../../../redux/context/customReports/selectors'
import { CustomReportData } from '../settings/CustomReportOrder'
import { CustomReportSection } from '../../../../../redux/context/customReports/typesSection'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import { CustomReport } from '../../../../../redux/context/customReports/types'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { LayoutsContext, LayoutsContextType } from '../layout/LayoutContext'

interface Option {
  value?: string | number | null
  label: React.ReactNode
  labelText: string
  children?: Option[]
  isLeaf?: boolean
  isGroup?: boolean
  disabled?: boolean
  expandIcon?: React.ReactNode
  categories?: CustomReportCategory[]
  variables?: CustomReportVariable[]
  type: any
}

interface SectionCascaderProps {
  disabled?: boolean
  onChange: (value: any, selectOptions: any) => void
}

const generateCascaderData = (
  node: (CustomReportData | any) & { sections?: CustomReportSection[]; disabled?: boolean }
): Option => {
  return {
    value: node.id,
    labelText: node.title,
    label: (
      <>
        {node.group && <FolderOutlined />} {node.title}
      </>
    ),
    disabled: node.sections && node.sections.length < 1,
    children:
      node?.children?.map((child: any) => {
        return generateCascaderData(child)
      }) || [],
    isGroup: node.group,
    isLeaf: !!node.reportId,
    categories: node?.categories,
    variables: node?.variables,
    type: node?.type
  }
}

const sortDataByPosition = (layout: ReactGridLayout.Layout[], data: CustomReportSection[]): CustomReportSection[] => {
  return [...data].sort((a, b) => {
    const layoutA = layout.find(item => item.i === a.id.toString())
    const layoutB = layout.find(item => item.i === b.id.toString())
    if (layoutA && layoutB) {
      if (layoutA.y === layoutB.y) {
        return layoutA.x - layoutB.x
      }
      return layoutA.y - layoutB.y
    }
    return 0
  })
}

const SectionCascader: React.FC<SectionCascaderProps> = ({ onChange, disabled }) => {
  const companyId = useSelector(contextCompanyIdSelector)
  const reportRequest = useBackend(`/api/companies/{companyId}/reporting/custom/reports/{reportId}`)
  const customReportTree = useSelector(customReportTreeSelector)
  const [options, setOptions] = useState<Option[]>(customReportTree.map(generateCascaderData))
  const { currentBreakPoint } = useContext(LayoutsContext) as LayoutsContextType

  const loadData = async (selectedOptions: any[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    if (targetOption.isGroup) return

    const response = (await reportRequest.get({
      urlParams: { companyId, reportId: targetOption.value }
    })) as CustomReport

    const map = ({ children, ...option }: Option): Option => {
      if (option.value === targetOption.value) {
        return {
          ...option,
          children: (response.layouts?.[currentBreakPoint]
            ? sortDataByPosition(response.layouts?.[currentBreakPoint], response.sections)
            : response.sections
          )?.map(generateCascaderData)
        }
      }

      return {
        ...option,
        children: children?.map(map)
      }
    }

    setOptions(options.map(map))
  }

  return (
    <Cascader
      placeholder=""
      style={{ width: '100%' }}
      disabled={disabled}
      options={options}
      allowClear
      onChange={onChange}
      loadData={loadData}
      showSearch={{
        filter(inputValue, path) {
          return path.some(option => {
            return option?.labelText?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          })
        }
      }}
    />
  )
}

export default SectionCascader
