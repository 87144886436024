import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { contextCompanyFormSelector, contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import BudgetingInputSettingsForm from './components/BudgetingInputSettingsForm'
import { contextRequest } from '../../../../../redux/context/actions'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'
import { companySettingsLoadingSelector } from '../../../../../redux/context/companySettings/selectors'
import { getCompanySettingsRequest } from '../../../../../redux/context/companySettings/actions'
import { getFinancialStatementRequest } from '../../../../../redux/context/reports/actions'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import { AppDispatch } from '../../../../../redux/store'
import { useFetchAccounts } from '../../../../../features/account/queries/useFetchAccounts'

const BudgetingInputSettings: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const budgetingInputLoading = useSelector(companySettingsLoadingSelector)
  const { isLoading: companyAccountsLoading } = useFetchAccounts()
  const companyForm = useSelector(contextCompanyFormSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const { budgetingScenario } = useSelector(filtersSelector)

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getCompanySettingsRequest))
      dispatch(contextRequest(getFinancialStatementRequest))
    }
  }, [companyId, companyForm, budgetingScenario])

  return (
    <LoadingWrapper loading={budgetingInputLoading || companyAccountsLoading}>
      <Alert message={t('budgetingInputPage:infoText')} type="warning" />
      <BudgetingInputSettingsForm />
    </LoadingWrapper>
  )
}

export default BudgetingInputSettings
