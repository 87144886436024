import { Form, Input, Modal } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Currency } from '../../../types/currency/Currency'
import { MultiDashboard, MultiDashboardMutation } from '..'
import { useCreateDimensionDashboard } from '../queries/useCreateDimensionDashboard'
import { DashboardPageType } from '../../../pages/dashboard/types'
import { useResetFormOnCloseModal } from '../../../components/Form/hooks'
import { useCreateScenarioDashboard } from '../queries/useCreateScenariosDashboard'
import { useCreateCompaniesDashboard } from '../queries/useCreateCompaniesDashbord'

interface Values {
  name: string
}

interface DashboardModalProps {
  visible: boolean
  multiPurposePage: DashboardPageType
  setVisible: (visible: boolean) => void
}

const initialParams: Partial<MultiDashboard> = {
  startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
  endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
  currency: Currency.EUR,
  companies: [],
  dimensions: [],
  keyFigures: [],
  budgetingScenarios: [],
  formulas: []
}

const mutations: {
  [key in DashboardPageType]: MultiDashboardMutation
} = {
  [DashboardPageType.compact]: useCreateDimensionDashboard,
  [DashboardPageType.default]: useCreateDimensionDashboard,
  [DashboardPageType.scenarios]: useCreateScenarioDashboard,
  [DashboardPageType.companies]: useCreateCompaniesDashboard
}

const MultiDashboardModal: React.FC<DashboardModalProps> = ({ visible, multiPurposePage, setVisible }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<Values>()
  const { isPending, mutate } = mutations[multiPurposePage]()

  useResetFormOnCloseModal({ form, open: visible })

  const handleSave = async () => {
    try {
      const data = await form.validateFields()
      mutate({ ...initialParams, ...data })
      setVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('dashboardPage:add-new-dashboard')}
      destroyOnClose
      open={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText={t('global:save')}
      maskClosable={false}
      cancelText={t('global:cancel')}
      width="unset"
      style={{ maxWidth: 700 }}
      confirmLoading={isPending}
    >
      <Form
        form={form}
        name="multiPurposeDashboard"
        layout="vertical"
        onKeyUp={event => {
          if (event.code === 'Enter') {
            handleSave()
          }
        }}
      >
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        {(multiPurposePage === DashboardPageType.compact || multiPurposePage === DashboardPageType.default) && (
          <Form.Item initialValue={multiPurposePage?.toUpperCase()} hidden name="subType">
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={t('global:name')}
          rules={[{ required: true, message: t('global:required-field') }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MultiDashboardModal
