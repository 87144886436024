import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useWatch } from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form, Spin, Table } from 'antd'
import ScenarioSelect from '../../../../../../components/Scenario/ScenarioSelect'
import { subsidiariesSelector } from '../../../../../../redux/context/subsidiaries/selectors'
import { Company } from '../../../../../../types/company/Company'
import { ReportDataType } from '../../../../../../redux/context/reports/types'
import { BackendRequest, useBackend } from '../../../../../../services/backend'
import { BudgetingScenario } from '../../../../../../types/budgetingScenario/BudgetingScenario'

interface ScenarioMapProps {
  selectedGroup?: Company
  dataType: ReportDataType.forecast | ReportDataType.budget
}

const ForecastCursorCell: React.FC<{ record: any }> = ({ record }) => {
  const form = Form.useFormInstance()
  const [loading, setLoading] = useState<boolean>(false)
  const [forecastsAndScenarios, setForecastsAndScenarios] = useState<BudgetingScenario[]>([])
  const budgetinScenariosRequest = useBackend(`/api/companies/{companyId}/budgeting/budgeting-scenarios`)
  const forecastsRequest = useBackend(`/api/companies/{companyId}/budgeting/forecasts`)
  const [forecastCursor, setForecastCursor] = useState<string | undefined>(undefined)
  const subsidiariesData = useWatch(['subsidiaries', record.id], form)

  const getData = async (url: BackendRequest, selectedCompany: string) => {
    const data = await url
      .get({
        urlParams: {
          companyId: selectedCompany
        }
      })
      .then((response: BudgetingScenario[]) => {
        return response
      })
    return data
  }

  useEffect(() => {
    if (subsidiariesData) {
      const fetchForecastCursorData = async () => {
        try {
          setLoading(true)
          if (forecastsAndScenarios.length === 0) {
            // TODO: jos joskus sais bäkkiin GET per yks ennuste/skenaario niin se sitten tämän tilalle
            const [budgetingScenariosData, forecastsData] = await Promise.all<BudgetingScenario[]>([
              getData(budgetinScenariosRequest, record.id),
              getData(forecastsRequest, record.id)
            ])
            setForecastsAndScenarios([...budgetingScenariosData, ...forecastsData])
            const data = [...budgetingScenariosData, ...forecastsData].find(
              f => f.id === form.getFieldValue(['subsidiaries', record.id])
            )
            setForecastCursor(data?.forecastCursor)
          } else {
            const data = forecastsAndScenarios.find(f => f.id === form.getFieldValue(['subsidiaries', record.id]))
            setForecastCursor(data?.forecastCursor)
          }
        } catch (error) {
          console.error('Error fetching forecast cursor data:', error)
        } finally {
          setLoading(false)
        }
      }
      fetchForecastCursorData()
    }
  }, [record.id, subsidiariesData])

  if (loading) {
    return <Spin size="small" />
  }

  return <>{forecastCursor ? dayjs(forecastCursor).endOf('month').format('DD.MM.YYYY') : ''}</>
}

const ScenarioMapTable = ({ selectedGroup, dataType }: ScenarioMapProps) => {
  const { t } = useTranslation()
  const subsidiaries = useSelector(subsidiariesSelector)

  const columns = [
    {
      title: t('global:company'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text: string, record: any) => record?.name
    },
    {
      title: t('global:scenario'),
      dataIndex: 'id',
      key: 'id',
      width: '45%',
      render: (text: string) => (
        <ScenarioSelect companyId={text} dataType={dataType} required={dataType === ReportDataType.forecast} />
      )
    },
    ...(dataType === ReportDataType.forecast
      ? [
          {
            title: t('global:actuals_until', { date: '' }),
            dataIndex: 'forecastCursor',
            key: 'forecastCursor',
            width: '15%',
            render: (value: string, record: any) => <ForecastCursorCell key={record.id} record={record} />
          }
        ]
      : [])
  ]

  return (
    <Table
      pagination={false}
      rowKey={(val: any) => val.id}
      dataSource={[selectedGroup || {}, ...(subsidiaries || [])]}
      columns={columns}
    />
  )
}

export default ScenarioMapTable
