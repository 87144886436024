import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Table, Space } from 'antd'
import { ColumnType } from 'antd/es/table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { useColumnSearchProps } from '../../../../../../components/Table/useColumSearchProps'
import { deleteFormulaRequest, updateFormulaOrdersRequest } from '../../../../../../redux/context/formulas/actions'
import { Formula } from '../../../../../../types/formula/Formula'
import { contextCompanyIdSelector } from '../../../../../../redux/context/company/selectors'
import { loadingSelector } from '../../../../../../redux/loading/selectors'
import { AppDispatch } from '../../../../../../redux/store'
import DraggableTableRow from '../../../../../../components/Table/DraggableTableRow'
import { propertySorterProps } from '../../../../../../utils/helpers'
import CodeBlock from '../../../../../../components/Misc/CodeBlock'

interface FormulaTableProps {
  formulas: Formula[]
  setFormulas: (formulas: Formula[]) => void
  handleEdit: (formulas: Formula) => void
}

const FormulaTable: React.FC<FormulaTableProps> = ({ formulas, setFormulas, handleEdit }) => {
  const companyId = useSelector(contextCompanyIdSelector)
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { updateFormulaOrders, formulas: formulasLoading, deleteFormula } = useSelector(loadingSelector)

  const columns: ColumnType<Formula>[] = [
    {
      key: 'key',
      width: 30
    },
    {
      key: 'name',
      title: t('global:name'),
      dataIndex: 'name',
      width: 250,
      ellipsis: true,
      sorter: propertySorterProps('name'),
      ...useColumnSearchProps('name')
    },
    {
      key: 'code',
      title: t('global:code'),
      width: 250,
      dataIndex: 'code',
      ellipsis: true,
      sorter: propertySorterProps('code'),
      ...useColumnSearchProps('code')
    },
    {
      key: 'source',
      title: t('global:formula'),
      dataIndex: 'source',
      ellipsis: true,
      sorter: propertySorterProps('source'),
      render: source => {
        return <CodeBlock code={source.replace(/(\r\n|\n|\r)/gm, '')} />
      }
    },
    {
      title: t('global:actions'),
      key: 'action',
      width: 80,
      align: 'right',
      fixed: 'right',
      render: (record: Formula) => (
        <Space>
          <Button size="small" type="text" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Popconfirm
            placement="bottomRight"
            title={t('global:delete-confirm')}
            onConfirm={() => companyId && dispatch(deleteFormulaRequest(companyId, record.id))}
            okText={t('global:yes')}
            cancelText={t('global:no')}
          >
            <Button danger size="small" type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = formulas.findIndex(i => i.id.toString() === active.id)
      const overIndex = formulas.findIndex(i => i.id.toString() === over?.id)
      const updatedArray = arrayMove(formulas, activeIndex, overIndex)
      const orderArray = updatedArray.map((row, index) => {
        return {
          id: +row.id,
          order: index
        }
      })

      setFormulas(updatedArray)
      companyId && dispatch(updateFormulaOrdersRequest(companyId, orderArray))
    }
  }

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext items={formulas.map(i => i.id.toString())} strategy={verticalListSortingStrategy}>
        <Table
          loading={updateFormulaOrders || formulasLoading || deleteFormula}
          scroll={{ x: 800 }}
          dataSource={formulas}
          columns={columns}
          pagination={false}
          rowKey={record => record?.id?.toString()}
          components={{
            body: {
              row: DraggableTableRow
            }
          }}
        />
      </SortableContext>
    </DndContext>
  )
}

export default FormulaTable
