import { createSelector } from 'reselect'
import { loadingSelector } from '../../loading/selectors'
import { Store } from '../../types'

export const groupSettingsSelector = (store: Store) => store.context?.groupSettings

export const budgetGroupSettingsSourceSelector = (store: Store) => store.context?.groupSettings?.budget?.source

export const actualsGroupSettingsSourceSelector = (store: Store) => store.context?.groupSettings?.actuals?.source

export const groupSettingsLoadingSelector = createSelector(loadingSelector, loading => loading.groupSettings)
