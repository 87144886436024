import { useMutation, useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useTranslation } from 'react-i18next'
import { updateCompaniesDashboardsOrder } from '../service/dashboardService'
import { useCompaniesDashboardsOptions } from './useCompaniesDasboards'
import { Orderable } from '../../../types/orderable/Orderable'
import { MultiDashboard } from '../types/MultiDashboard'

export default function useUpdateCompaniesDashboardsOrder() {
  const queryClient = useQueryClient()
  const options = useCompaniesDashboardsOptions()
  const { notification } = App.useApp()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (order: Orderable[]) => updateCompaniesDashboardsOrder(order),
    mutationKey: ['companies'],
    retry: 2,
    onSuccess: updatedOrder => {
      queryClient.setQueryData(options.queryKey, oldItems => {
        notification.success({ message: t('message:UPDATE_CUSTOM_REPORT_ORDER_SUCCESS') })
        return oldItems
          ?.map(item => {
            const { order } = updatedOrder.find(({ id }) => item.id === id) || {}
            return { ...item, order: order ?? item.order }
          })
          .filter(Boolean) as MultiDashboard[]
      })
    }
  })
}
