/* eslint-disable no-nested-ternary */
import { ProCard } from '@ant-design/pro-components'
import { Statistic } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatValueByType } from '../../../utils/helpers'
import { LoanSummary } from '../types/LoanSummary'

interface LoanSummaryProps {
  isExisting: boolean
  balanceSheetRowId?: number
  summary?: LoanSummary
}
const { Divider } = ProCard
const LoanSummaryStats: React.FC<LoanSummaryProps> = ({ isExisting, summary, balanceSheetRowId }) => {
  const { t } = useTranslation()

  return (
    <ProCard.Group direction="row">
      <ProCard>
        <Statistic
          title={
            balanceSheetRowId
              ? `${t(`balanceSheet:${balanceSheetRowId}`)} ${t('loans:actualBalanceShort')}`
              : !isExisting
              ? t('loans:loan-count')
              : t('loans:actualBalance')
          }
          value={summary?.actualBalance}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
      <Divider type="vertical" />
      <ProCard>
        <Statistic
          title={
            balanceSheetRowId
              ? `${t(`balanceSheet:${balanceSheetRowId}`)} ${t('loans:budgetBalanceShort')}`
              : !isExisting
              ? t('loans:total-new-loans')
              : t('loans:budgetBalance')
          }
          value={summary?.budgetBalance}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
      <Divider type="vertical" />
      <ProCard>
        <Statistic
          title={t('loans:difference')}
          value={summary?.difference}
          suffix={t('global:currency-symbol')}
          formatter={val => formatValueByType(val)}
        />
      </ProCard>
    </ProCard.Group>
  )
}

export default LoanSummaryStats
