import React, { ReactNode } from 'react'

interface ConditionalRenderProps {
  render: boolean
  children?: ReactNode
}

const ConditionalRender = ({ children, render }: ConditionalRenderProps) => {
  if (!render) return null
  return <>{children}</>
}

export default ConditionalRender
