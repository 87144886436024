import { Modal, Form } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyFigureFact } from '../../../types/key-figures/KeyFigureFact'
import { FormattedInputNumber } from '../../../components/Misc/FormattedInputNumber'
import { useResetFormOnCloseModal } from '../../../components/Form/hooks'
import { useUpdatePersonnel } from '..'

interface PersonnelFormProps {
  personnelData: KeyFigureFact | null
  visible: boolean
  closeModal: () => void
}

const PersonnelModal = ({ personnelData, visible, closeModal }: PersonnelFormProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<Pick<KeyFigureFact, 'value' | 'fiscalYearId'>>()
  const updatePersonnel = useUpdatePersonnel()

  useEffect(() => {
    personnelData && form.setFieldsValue(personnelData)
  }, [personnelData])

  useResetFormOnCloseModal({ form, open: visible })

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      await updatePersonnel.mutateAsync(values)
      closeModal()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      title={t('personnelPage:edit-personnel') + personnelData?.year}
      open={visible}
      maskClosable={false}
      onCancel={() => {
        closeModal()
      }}
      onOk={handleSubmit}
      confirmLoading={updatePersonnel.isPending}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('personnelPage:personnel')}
          name="value"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
        >
          <FormattedInputNumber value={undefined} />
        </Form.Item>
        <Form.Item name="fiscalYearId" hidden>
          <FormattedInputNumber />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PersonnelModal
