import React, { useEffect } from 'react'
import { Button, Result } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import Bugsnag from '@bugsnag/js'
import { currentUserHomepageSelector } from '../../redux/session/currentUser/selectors'

// TODO: Käännökset erityyppisille erroreille
function BoundaryFallback({ error, resetErrorBoundary }: any) {
  const homepage = useSelector(currentUserHomepageSelector)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goHome = () => {
    const url = homepage
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    resetErrorBoundary()
    navigate(url)
  }

  const extra = () => (
    <Button type="primary" onClick={goHome}>
      {t('global:back')}
    </Button>
  )

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error)
      console.log('error', error)
    }
  }, [error])

  if (error.status === 401) {
    return (
      <Result
        status="403"
        title="Unathorized"
        subTitle="Sorry, you are not authorized to access this page."
        extra={extra()}
      />
    )
  }

  return <Result status="error" title={t('errorMessage:DEFAULT')} subTitle={error.cause} extra={extra()} />
}
export default BoundaryFallback
