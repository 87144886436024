import { BudgetingConfig } from '../../../redux/config/budgeting/types'
import { CashFlowStatementConfig } from '../../../redux/config/cashFlowStatement/types'
import { FinancialStatementConfig } from '../../../redux/config/financialStatement/types'
import { LiabilityMapping } from '../../../redux/config/liabilityMapping/types'
import { Config } from '../../../redux/config/types'
import request from '../request'

export const getBudgetingConfig = async () => {
  return request<BudgetingConfig>({
    method: 'GET',
    url: '/config/budgeting'
  })
}

export const getCashFlowStatementConfig = async () => {
  return request<CashFlowStatementConfig>({
    method: 'GET',
    url: '/config/cash-flow-statement'
  })
}

export const getFinancialStatementConfig = async () => {
  return request<FinancialStatementConfig>({
    method: 'GET',
    url: '/config/financial-statement'
  })
}

export const getLiabilityMappingConfig = async () => {
  return request<LiabilityMapping>({
    method: 'GET',
    url: '/config/liability-mapping'
  })
}

export async function fetchConfigs() {
  try {
    const [budgeting, cashFlowStatement, financialStatement, liabilityMapping] = await Promise.all([
      getBudgetingConfig(),
      getCashFlowStatementConfig(),
      getFinancialStatementConfig(),
      getLiabilityMappingConfig()
    ])

    // Return an object with the results
    return {
      budgeting,
      cashFlowStatement,
      financialStatement,
      liabilityMapping
    } as Config
  } catch (error) {
    // Handle any error from the requests
    console.error('Error occurred while fetching configs:', error)
    throw error
  }
}
