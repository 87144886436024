import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import investmentQueryKeys from '../queries/investmentQueryKeys'
import { getInvestmentDepreciations } from '../service/investmentService'

export const useDepreciations = (investmentId: number) => {
  const companyId = useSelector(contextCompanyIdSelector)!

  return useQuery({
    queryKey: investmentQueryKeys.depreciations({ companyId, investmentId }),
    queryFn: getInvestmentDepreciations
  })
}
