import { Dispatch } from 'react'
import { ReportTableRow } from '../../../components/Table/types'
import { BackendRequest } from '../../../services/backend'
import { Company } from '../../../types/company/Company'
import { PeriodGroup } from '../../../types/periodGroup/PeriodGroup'
import { store } from '../../../redux/store'
import { contextRequest } from '../../../redux/context/actions'
import {
  getFinancialStatementRequest,
  getKeyPerformanceIndicatorStatmentRequest
} from '../../../redux/context/reports/actions'
import { setAllDimensionsRowData } from '../../../redux/context/incomeStatenentDimensions/actions'
import { BudgetingScenario } from '../../../types/budgetingScenario/BudgetingScenario'
import { FinancialStatement, ReportResponse } from '../../../redux/context/reports/types'
import { DimensionQueryOperator } from '../../../types/dimension/Dimension'
import { chunkArray } from '../../../utils/helpers'

export const dispatchFinancialStatementRequests = () => {
  store.dispatch(contextRequest(getFinancialStatementRequest) as any)
  store.dispatch(contextRequest(getKeyPerformanceIndicatorStatmentRequest) as any)
}

export const fetchDimensionsData = async (
  rowDimensionDataRequest: BackendRequest,
  companyId: Company['id'],
  accountCode?: string | number | null,
  dimensionIds?: string[],
  statementRowId?: string | number | null,
  budgetingScenarioId?: number,
  periodGroups?: PeriodGroup[]
): Promise<ReportResponse<FinancialStatement>> => {
  return rowDimensionDataRequest
    .get({
      urlParams: { companyId },
      body: {
        params: {
          statementRowId,
          accountCode,
          dimensions: JSON.stringify(
            dimensionIds?.map(id => ({
              k: id,
              o: DimensionQueryOperator.has,
              v: id
            }))
          ),
          budgetingScenarioId,
          periodGroups
        }
      }
    })
    .then((res: ReportTableRow) => res)
    .catch(() => [])
}

export const getRowDimensionDataReq = async (
  dimensionsArray: string[],
  row: ReportTableRow,
  companyId: Company['id'],
  rowDimensionDataRequest: BackendRequest,
  dispatch: Dispatch<any>,
  budgetingScenario?: BudgetingScenario,
  periodGroups?: PeriodGroup[]
) => {
  const s = 6 // Number of simultaneous requests (bäkkiserverien määrä)
  const k = 12 // Maximum dimensions per request.

  // Function to send a batch of requests in parallel.
  const sendBatch = async (dimensionIdBatch: string[]): Promise<ReportResponse<FinancialStatement>> => {
    // eslint-disable-next-line no-return-await
    return await fetchDimensionsData(
      rowDimensionDataRequest,
      companyId,
      row.accountCode,
      dimensionIdBatch,
      row.statementRowId,
      budgetingScenario && budgetingScenario.id,
      periodGroups
    )
  }

  // Chunk the items into arrays of size k.
  const dimensionChunks = chunkArray(dimensionsArray, k)

  // Function to send requests in parallel.
  async function sendRequestsInParallel() {
    let responses: Partial<ReportTableRow>[] = []
    for (let i = 0; i < dimensionChunks.length; i += s) {
      const batch = dimensionChunks.slice(i, i + s)
      // eslint-disable-next-line no-await-in-loop
      const awaited = await Promise.all(batch.map(sendBatch))
      for (const a of awaited) {
        responses = [...responses, ...a]
      }
    }
    return responses
  }

  // Start sending requests.
  const responses = await sendRequestsInParallel()
  responses &&
    dispatch(
      setAllDimensionsRowData({
        key: `${row.accountCode || row.statementRowId}`,
        data: responses.map((d: Partial<ReportTableRow>) => ({
          dimensionId: d.dimensionKey,
          ...d.data
        }))
      })
    )
}
