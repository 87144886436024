import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

const useDeleteQueryItem = () => {
  const queryClient = useQueryClient()

  const deleteQueryItem = useMemo(
    () => (deleteItem: { id: number | string }, options: any) => {
      queryClient.setQueryData(options.queryKey, (oldItems: { id: number }[] = []) => {
        return oldItems.filter(item => item.id !== deleteItem.id)
      })
    },
    []
  )

  return deleteQueryItem
}

export default useDeleteQueryItem
