import { setAppConfigs } from '../../../redux/config/actions'
import { setEntities } from '../../../redux/entities/actions'
import { setSessionContract } from '../../../redux/session/contract/actions'
import { setCurrentUser } from '../../../redux/session/currentUser/actions'
import { store } from '../../../redux/store'
import { fetchConfigs } from '../urls/configs'
import { getContract } from '../urls/contracts'
import { fetchEntities } from '../urls/entities'
import { getCurrentUser } from '../urls/users'

export const initilData = async () => {
  try {
    const user = await getCurrentUser()
    store.dispatch(setCurrentUser(user))
    const configs = await fetchConfigs()
    store.dispatch(setAppConfigs(configs))
    const entities = await fetchEntities()
    store.dispatch(setEntities(entities))
    const contract = await getContract(user.contractId)
    store.dispatch(setSessionContract(contract))
  } catch (error) {
    throw Error('Cannot get initial data')
  }
}
