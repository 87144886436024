import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { setMultiPurposeDashboardActiveDashboard } from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { MultiDashboard } from '../types/MultiDashboard'
import { useCompaniesDashboardsOptions } from './useCompaniesDasboards'
import { createCompaniesDashboard } from '../service/dashboardService'

export const useCreateCompaniesDashboard = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const options = useCompaniesDashboardsOptions()

  return useMutation({
    mutationFn: (data: Partial<MultiDashboard>) => createCompaniesDashboard(data),
    onSuccess: newItem => {
      queryClient.setQueryData(options.queryKey, (oldItems = []) => {
        return [...oldItems, newItem]
      })
      dispatch(setMultiPurposeDashboardActiveDashboard(newItem.id))
    }
  })
}
