import { Collapse } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { contextCompanySelector } from '../../../../redux/context/company/selectors'
import { dimensionListSelector } from '../../../../redux/context/dimensions/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { Balance } from '../../../../redux/context/reports/types'
import { multiPurposeDashboardPageSelector } from '../../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { currentUserSortedCompaniesSelector } from '../../../../redux/session/currentUser/selectors'
import { Formula } from '../../../../types/formula/Formula'
import { PeriodGroup } from '../../../../types/periodGroup/PeriodGroup'
import { formatValueToNumberByType } from '../../../../utils/helpers'
import CompetitorChart from '../../../industryAndCompetitorComparison/components/Chart'
import { getDashboardStartAndEndDates, flattenObject } from '../utils'
import { generatePeriodGroupPeriods } from '../../../../utils/periodGroups/utils'
import { MultiDashboard } from '../../../../features/dashboards'
import { MultiPurposeDashboardContext, MultiPurposeDashboardContextType } from '../MultiPurposeDashboardProvider'

const MultiPurposeDashboardFormulaCharts = () => {
  const { keyfigureStatements, formulas, dimensionKeyfigureStatements } = useSelector(multiPurposeDashboardPageSelector)
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const dimensions = useSelector(dimensionListSelector)
  const contextCompany = useSelector(contextCompanySelector)
  const { periodGroups } = useSelector(filtersSelector)
  const { dashboard } = useContext(MultiPurposeDashboardContext) as MultiPurposeDashboardContextType

  const { startDate, endDate } = getDashboardStartAndEndDates(
    dashboard ||
      ({
        startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('year').format('YYYY-MM-DD')
      } as MultiDashboard),
    periodGroups
  )

  const generateFormulaChartSeries = (formula?: Formula) => {
    const series: any = []
    if (!formula) return series
    // Make series
    let mapCompanies = dashboard?.companies || []
    if (contextCompany && mapCompanies?.length < 1) {
      mapCompanies = [contextCompany.id]
    }
    mapCompanies
      ?.filter((id: string) => myCompanies.find(c => c.id === id))
      .forEach((companyId: string) => {
        const company = myCompanies?.find(c => c.id === companyId)

        const serie = {
          name: company?.name,
          type: 'column',
          data: [] as (number | null)[]
        }
        if (!company) return
        if (keyfigureStatements[companyId]) {
          const { actuals } = _.keyBy(keyfigureStatements[companyId], 'id')[formula.id] || []
          for (const { month, year } of generatePeriodGroupPeriods({
            id: dayjs(startDate).year(),
            startDate: startDate || '',
            endDate: endDate || ''
          } as PeriodGroup)) {
            const foundBalance =
              actuals && actuals.find((balance: Balance) => balance.year === year && balance.month === month)
            serie.data.push(foundBalance ? formatValueToNumberByType(foundBalance.value, formula.type) : 0)
          }
        }
        series.push(serie)
      })

    if (dimensionKeyfigureStatements) {
      Object.entries(dimensionKeyfigureStatements).forEach(([dimensionId, dimensionDatas]: any) => {
        dimensionDatas
          ?.filter((dimData: any) => dimData.id === formula.id)
          .forEach((dimensionData: any) => {
            const dimension = dimensions?.find(c => c.id === dimensionId)
            const serie = {
              name: dimension?.name,
              type: 'column',
              data: [] as (number | null)[]
            }
            if (!dimensionData || !dimension) return
            const { actuals } = dimensionData
            for (const { month, year } of generatePeriodGroupPeriods({
              id: dayjs(startDate).year(),
              startDate: startDate || '',
              endDate: endDate || ''
            } as PeriodGroup)) {
              const foundBalance =
                actuals && actuals.find((balance: Balance) => balance.year === year && balance.month === month)
              serie.data.push(foundBalance ? formatValueToNumberByType(foundBalance.value, dimensionData.type) : 0)
            }
            series.push(serie)
          })
      })
    }

    return series
  }

  const items = useMemo(
    () =>
      dashboard &&
      (dashboard?.formulas || [])
        .map((id: number) => flattenObject(formulas).find(f => f.id === id))
        .filter((f: Formula) => !!f)
        ?.map((formula: any) => ({
          key: formula.name,
          label: formula.name,
          children: (
            <CompetitorChart
              categories={generatePeriodGroupPeriods({
                id: dayjs(startDate).year(),
                startDate: startDate || '',
                endDate: endDate || ''
              } as PeriodGroup).map(period => `${period.month} / ${period.year}`)}
              series={generateFormulaChartSeries(formula)}
            />
          )
        })),
    [dashboard?.formulas, formulas, keyfigureStatements, periodGroups, dimensionKeyfigureStatements]
  )

  if (!dashboard) return null

  return (
    <div style={{ marginTop: 16 }}>
      {dashboard?.formulas && dashboard?.formulas?.length > 0 && <Collapse items={items} destroyInactivePanel />}
    </div>
  )
}

export default MultiPurposeDashboardFormulaCharts
