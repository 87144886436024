import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Checkbox, Button } from 'antd'
import { currentUserSelector, currentUserTermsConditionsSelector } from '../../redux/session/currentUser/selectors'
import { termsConditionsAgreedRequest } from '../../redux/session/currentUser/actions'
import { useLoggedin } from '../../redux/session/currentUser/hooks'
import { useDocsURL } from '../../utils/docs'
import { AppDispatch } from '../../redux/store'

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const [isVisible, setIsVisible] = useState(false)
  const [agreedTermsAndConditions, setAgreedCheckbox] = useState(false)
  const isLoggedIn = useLoggedin()
  const currentUser = useSelector(currentUserSelector)
  const termsConditionsAgreed = useSelector(currentUserTermsConditionsSelector)
  const dispatch: AppDispatch = useDispatch()

  const agreedTermsAndConditionsAction = (checked: any) => {
    setAgreedCheckbox(checked.target.checked)
  }

  const isVisibleHandler = () => {
    if (isLoggedIn && !termsConditionsAgreed) {
      setIsVisible(true)
    }
  }

  useEffect(isVisibleHandler, [currentUser])

  const termsConditionsAccepted = () => {
    agreedTermsAndConditions && dispatch(termsConditionsAgreedRequest())
    setIsVisible(false)
  }

  return (
    <Modal
      title={t('terms:title')}
      closable={false}
      maskClosable={false}
      open={isVisible}
      footer={[
        <Button key="confirm" type="primary" onClick={termsConditionsAccepted} disabled={!agreedTermsAndConditions}>
          {t('global:confirm')}
        </Button>
      ]}
    >
      <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
        <a href={`${docsURL}terms/general`} target="_blank" rel="noopener noreferrer">
          1)
          {t('terms:supplier-terms')}
        </a>
      </div>
      <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
        <a href={`${docsURL}terms/data-protection`} target="_blank" rel="noopener noreferrer">
          2)
          {t('terms:software-terms')}
        </a>
      </div>
      <div style={{ marginLeft: '20px', marginBottom: '5px' }}>
        <a href={`${docsURL}terms/data-usage`} target="_blank" rel="noopener noreferrer">
          3)
          {t('terms:data-terms')}
        </a>
      </div>
      <div>
        <Checkbox style={{ marginTop: '20px', marginLeft: '20px' }} onChange={agreedTermsAndConditionsAction}>
          {t('terms:accept-terms')}
        </Checkbox>
      </div>
    </Modal>
  )
}

export default TermsAndConditions
