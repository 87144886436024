const dashboardEndpoints = {
  companyOrder: (companyId: string) => `/companies/${companyId}/dashboards/order` as const,
  companies: '/users/current/dashboards/multi-company' as const,
  companiesDetail: (id: number) => `/users/current/dashboards/multi-company/${id}` as const,
  companiesOrder: '/users/current/dashboards/multi-company/order' as const,
  dimensions: (companyId: string) => `/companies/${companyId}/dashboards/dimensions` as const,
  dimensionDetail: (companyId: string, id: number) => `/companies/${companyId}/dashboards/dimensions/${id}` as const,
  dimensionsOrder: (companyId: string) => `/companies/${companyId}/dashboards/dimensions/order` as const,
  scenarios: (companyId: string) => `/companies/${companyId}/dashboards/budgeting-scenarios` as const,
  scenarioDetail: (companyId: string, id: number) =>
    `/companies/${companyId}/dashboards/budgeting-scenarios/${id}` as const,
  scenariosOrder: (companyId: string) => `/companies/${companyId}/dashboards/budgeting-scenarios/order` as const
}

export default dashboardEndpoints
