import { Select } from 'antd'
import React from 'react'
import { Loan } from '../types/Loan'
import { Purpose } from '../../../types/purpose/Purpose'

interface LoanPurposesSelectProps {
  onChange: any
  value: Purpose[]
  record: Loan
}

const LoanPurposesSelect: React.FC<LoanPurposesSelectProps> = ({ record, onChange, value }) => {
  return (
    <Select
      maxTagCount="responsive"
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      mode="tags"
      showSearch
      optionFilterProp="children"
    >
      {(record.purposes as unknown as string[])?.map(name => (
        <Select.Option key={name} value={name}>
          {name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default LoanPurposesSelect
