import { useSelector } from 'react-redux'
import { useMemo, useCallback } from 'react'
import i18n from 'i18next'
import { currentUserRoleSelector, currentUserPermissionSelector } from '../../redux/session/currentUser/selectors'
import { contextContractProductSelector, contextContractSelector } from '../../redux/context/contract/selectors'
import { contextCompanySelector } from '../../redux/context/company/selectors'
import { Authority } from './types'
import { groupSettingsSelector } from '../../redux/context/groupSettings/selectors'

export type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

type TestEntries = Entries<Authority>

const authorizeData = (authoritites: Authority | Authority[] | undefined, userValues: Authority): boolean => {
  if (!authoritites) return true
  if (userValues?.role === 'superuser') return true

  const tester = (val: TestEntries[number]) => {
    const [testKey, testValue] = val || []
    if (!testKey || !testValue) return true

    let auhtorizedTest = false

    // Custom function test
    if (testKey === 'custom' && typeof testValue === 'function') {
      if (Array.isArray(testValue)) {
        for (const custom of testValue) {
          if (custom()) {
            auhtorizedTest = custom()
          } else {
            return false
          }
        }
      } else {
        auhtorizedTest = testValue()
      }
    }
    // Else tests
    else if (Array.isArray(userValues[testKey]) && Array.isArray(testValue)) {
      auhtorizedTest = (testValue as string[]).every(tv => (userValues?.[testKey] as string[])?.includes(tv))
    } else if (Array.isArray(testValue)) {
      auhtorizedTest = testValue.includes(userValues[testKey] as never)
    } else if (Array.isArray(userValues[testKey])) {
      auhtorizedTest = (userValues?.[testKey] as string[])?.includes(testValue as never)
    } else {
      auhtorizedTest = testValue === userValues[testKey]
    }

    return auhtorizedTest
  }

  const authorize = (authority: Authority) => {
    if (!authority) return true
    // Test one authority

    return (Object.entries(authority) as TestEntries).every(tester)
  }

  return Array.isArray(authoritites) ? authoritites.some(authorize) : authorize(authoritites)
}

const useUserValues = () => {
  const role = useSelector(currentUserRoleSelector)!
  const permissions = useSelector(currentUserPermissionSelector)
  const contextCompany = useSelector(contextCompanySelector)!
  const product = useSelector(contextContractProductSelector)!
  const groupSettings = useSelector(groupSettingsSelector)
  const { franchise } = useSelector(contextContractSelector) || {}

  const userValues: Authority = useMemo(
    () => ({
      role: role?.label,
      product,
      franchise,
      permission: permissions?.map(pp => pp.label),
      accountingSoftware: contextCompany?.accountingSoftware,
      country: contextCompany?.country
    }),
    [role, contextCompany, permissions, product, franchise, groupSettings]
  )

  return userValues
}

export const useAuthorizedData = <T>(data: T): T => {
  const userValues = useUserValues()

  const traverse = (d: any): any => {
    if (typeof d === 'object' && d !== null) {
      if (Array.isArray(d)) {
        return d
          .filter((obj: any) => {
            if (obj?.authority) {
              return authorizeData(obj?.authority, userValues)
            }
            return true
          })
          .map((child: any) => traverse(child))
      }
      return Object.fromEntries(
        Object.entries(d)
          .filter(([, val]: any) => authorizeData(val?.authority, userValues))
          .map((child: any) => traverse(child))
      )
    }
    return d
  }
  const authorizedData = useMemo(() => {
    return traverse(data)
  }, [...Object.values(userValues), i18n.language, data])

  return authorizedData
}

export const useAuthority = (authority: Authority): boolean => {
  const userValues = useUserValues()

  const authorized = useMemo(() => authorizeData(authority, userValues), [userValues])
  return authorized
}

interface AuthorizeFunction {
  (authority?: Authority | Authority[]): boolean
}

export const useAuthorize = (): AuthorizeFunction => {
  const userValues = useUserValues()

  const authorize: AuthorizeFunction = useCallback(
    authority => {
      return authorizeData(authority, userValues)
    },
    [userValues]
  )

  return authorize
}
