import { Button, Table, TableColumnsType } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { KeyFigureFact } from '../../../../types/key-figures/KeyFigureFact'
import { Loading } from '../../../../components/Misc/Loading'
import { PersonnelModal, usePersonnel } from '../../../../features/personnel'
import RequestError from '../../../../shared/components/RequestError'

const PersonnelPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: personnel, isLoading, isFetching, error, refetch } = usePersonnel()
  const [editinPersonnel, setEditinPersonnel] = useState<KeyFigureFact | null>(null)

  const closeModal = () => {
    setEditinPersonnel(null)
  }

  const columns: TableColumnsType<any> = [
    {
      title: t(`personnelPage:fiscalYear`),
      dataIndex: 'year'
    },
    {
      title: t(`personnelPage:personnel`),
      dataIndex: 'value',
      align: 'right'
    },
    {
      title: t(`personnelPage:actions`),
      dataIndex: 'actions',
      align: 'right',
      render: (_, record) => (
        <Button
          size="small"
          icon={<EditOutlined />}
          type="link"
          onClick={() => {
            setEditinPersonnel(record)
          }}
        />
      )
    }
  ]

  if (isLoading) return <Loading />

  if (error) return <RequestError error={error} refetch={refetch} />

  return (
    <>
      <PersonnelModal personnelData={editinPersonnel} visible={!!editinPersonnel} closeModal={closeModal} />
      <Table loading={isFetching} columns={columns} dataSource={personnel} rowKey="year" />
    </>
  )
}

export default PersonnelPage
