import React, { ReactNode, ReactText, useEffect, useMemo, useState } from 'react'
import dotProp from 'dot-prop'
import _ from 'lodash'
import { FormInstance, TreeProps } from 'antd'
import { TimePeriodOptions } from '../../../../../redux/context/customReports/types'
import { useBackend } from '../../../../../services/backend'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { BudgetingScenario } from '../../../../../types/budgetingScenario/BudgetingScenario'
import { CustomTree } from '../customReportTable/CustomTree'
import { getDimensionTree } from '../../../../../components/Dimension/utils'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { getReferenceTitle } from '../../../../../redux/context/customReports/utils'

const generateParentCollectionPath = (key: string) => {
  const keys = key.split('-').slice(1)
  return keys.reduce((str, currentKey, index, arr) => {
    if (index === 0) return currentKey
    if (index === arr.length - 1) return str.concat('.children')
    return str.concat(`.children.${currentKey}`)
  }, '')
}

const generateItemCollectionPath = (key: string) => {
  const keys = key.split('-').slice(1)
  return keys.reduce((str, currentKey, index) => {
    if (index === 0) return `${currentKey}.children`
    return str.concat(`.${currentKey}.children`)
  }, '')
}

const generateItemPath = (key: string) => {
  const keys = key.split('-').slice(1)
  if (keys.length === 1) return keys[0].toString()
  return keys.reduce((str, currentKey, index, arr) => {
    if (index === arr.length - 1) return str.concat(currentKey)
    return str.concat(`${currentKey}.children.`)
  }, '')
}

function mapCategoryIds(
  this: string,
  { children: child, ...o }: CustomReportCategory,
  index: number
): CustomReportCategory {
  const path = `${this || 0}-${index}`
  return {
    ...o,
    id: path,
    children: child?.map(mapCategoryIds, path) // recursive call
  }
}

const getNextId = (key: string | null, collection: CustomReportCategory[]) => {
  const currentHighestId = Math.max(
    ...collection.map(cat => parseInt((cat.id! as string).split('-').pop() || '', 10) || 0)
  )
  if (key === null) return collection.length === 0 ? '0' : `${currentHighestId + 1}`
  if (collection.length === 0) return `${key}-0`

  return `${key}-${currentHighestId + 1}`
}

export type CategoryContextType = {
  categories: CustomReportCategory[]
  dimensionMap: { [key: string]: Dimension[] }
  budgetingScenarioMap: { [key: string]: BudgetingScenario[] }
  forecastMap: { [key: string]: BudgetingScenario[] }
  isCategoriesValid: boolean
  dimensionLoading: boolean
  budgetingScenariosLoading: boolean
  forecastsLoading: boolean
  sectionForm?: FormInstance
  categoryTree: CustomTree
  setSectionForm: (val: FormInstance) => void
  setCategories: (categories: CustomReportCategory[]) => void
  addCompany: () => void
  deleteObject: (id: string) => void
  addDimension: (key: string) => void
  addFunction: (key: string) => void
  addFunctionCompany: (key: string) => void
  addRootFunction: () => void
  addPeriodGroup: (key: string) => void
  editObject: (key: string, category: CustomReportCategory) => void
  reset: () => void
  getPathContext: (path?: string) => any
  onDrop: TreeProps['onDrop']
}

export const CategoryContext = React.createContext<CategoryContextType | null>(null)

interface CategoryProviderProps {
  visible: boolean
  categories?: CustomReportCategory[]
  children?: ReactNode
}

const initialCategories: CustomReportCategory[] = [
  {
    type: 'company',
    value: undefined,
    children: []
  }
]

const CategoryProvider: React.FC<CategoryProviderProps> = ({ children, categories: sectionCategories, visible }) => {
  const dimensionRequest = useBackend(`/api/companies/{companyId}/accounting/dimensions`)
  const budgetinScenariosRequest = useBackend(`/api/companies/{companyId}/budgeting/budgeting-scenarios`)
  const forecastsRequest = useBackend(`/api/companies/{companyId}/budgeting/forecasts`)
  const [categoryTree] = useState(new CustomTree('0'))

  const [dimensionMap, setDimensionMap] = useState<{
    [k: string]: Dimension[]
  }>({})
  const [budgetingScenarioMap, setBudgetingScenarioMap] = useState<{
    [k: string]: BudgetingScenario[]
  }>({})
  const [forecastMap, setForecastMap] = useState<{
    [k: string]: BudgetingScenario[]
  }>({})
  const [categories, setCategories] = useState<CustomReportCategory[]>(
    (sectionCategories || initialCategories).map(mapCategoryIds)
  )

  const [isCategoriesValid, setIsCategoriesValid] = useState(false)
  const [dimensionLoading, setDimensionLoading] = useState(false)
  const [sectionForm, setSectionForm] = useState<FormInstance>()
  const [budgetingScenariosLoading, setBudgetingScenariosLoading] = useState(false)
  const [forecastsLoading, setForecastsLoading] = useState(false)

  useMemo(() => categoryTree.build(categories), [categories])

  useEffect(() => {
    if (sectionCategories) {
      const cats = _.cloneDeep(sectionCategories).map(mapCategoryIds)
      setCategories(cats)
    }
  }, [sectionCategories])

  const fetchDimensions = async () => {
    setDimensionLoading(true)
    const newDimensionsCompanys = categoryTree.companies.filter(value => value && !dimensionMap[value as ReactText])
    const newDimensions = await Promise.all(
      newDimensionsCompanys.map(companyId => {
        return dimensionRequest
          .get({
            urlParams: {
              companyId
            }
          })
          .catch(() => {
            console.log('ei dataa')
          })
      })
    )
    setDimensionMap({
      ...dimensionMap,
      ...Object.fromEntries(newDimensionsCompanys.map((c, i) => [c, getDimensionTree(newDimensions[i])]))
    })

    setDimensionLoading(false)
  }

  const fetchBudgetingScenarios = async () => {
    setBudgetingScenariosLoading(true)
    try {
      const newBudgetingScenariosCompanys = categoryTree.companies.filter(
        value => value && !budgetingScenarioMap[value as ReactText]
      )
      const newBudgetingScenarios = await Promise.all(
        newBudgetingScenariosCompanys.map(companyId => {
          return budgetinScenariosRequest.get({
            urlParams: {
              companyId
            }
          })
        })
      )
      setBudgetingScenarioMap({
        ...budgetingScenarioMap,
        ...Object.fromEntries(newBudgetingScenariosCompanys.map((c, i) => [c, newBudgetingScenarios[i]]))
      })
    } catch (error) {
      console.log(error)
    } finally {
      setBudgetingScenariosLoading(false)
    }
  }

  const fetchForecasts = async () => {
    setForecastsLoading(true)
    try {
      const newForecastCompanys = categoryTree.companies.filter(value => value && !forecastMap[value as ReactText])
      const newForecasts = await Promise.all(
        newForecastCompanys.map(companyId => {
          return forecastsRequest.get({
            urlParams: {
              companyId
            }
          })
        })
      )
      setForecastMap({
        ...forecastMap,
        ...Object.fromEntries(newForecastCompanys.map((c, i) => [c, newForecasts[i]]))
      })
    } catch (error) {
      console.log(error)
    } finally {
      setForecastsLoading(false)
    }
  }

  const checkValidity = (c: CustomReportCategory[]): boolean => {
    let isValid = true
    function recurse(data: CustomReportCategory[]) {
      for (const cat of data) {
        if (cat) {
          if (cat.value === 'custommonth' && !cat.startDate) {
            isValid = false
            break
          }
          if (!cat.value) {
            isValid = false
            break
          }
          if (cat.type === 'reference') {
            if (getReferenceTitle(cat, c)?.includes('undefined')) {
              isValid = false
              break
            }
          }
          if (
            (cat.type === 'company' || cat.type === 'dimension' || cat.type === 'function') &&
            (cat?.children?.length || 0) < 1
          ) {
            isValid = false
            break
          }
        }
        if (cat.children) {
          recurse(cat.children)
          if (!isValid) {
            break
          }
        }
      }
    }

    recurse(c)
    return isValid
  }

  useEffect(() => {
    if (visible) {
      fetchDimensions()
      fetchForecasts()
      fetchBudgetingScenarios()
    }

    setIsCategoriesValid(checkValidity(categories))
  }, [categories, visible])

  const addCategory = (key: string, data: CustomReportCategory) => {
    const collectionPath = generateItemCollectionPath(key)
    const collection: CustomReportCategory[] = dotProp.get(categories, collectionPath) || []
    const item: CustomReportCategory | undefined = dotProp.get(categories, generateItemPath(key))
    if (!item?.id) return
    if (!collection) return

    const nextId = getNextId(item.id, collection)

    const newData = {
      ...data,
      id: nextId,
      children: data?.children?.map((child, index) => ({
        ...child,
        id: `${nextId}-${index}`
      }))
    }

    const newCollection = [...collection, newData]
    const newCategories = dotProp.set(categories, collectionPath, newCollection)

    setCategories([...newCategories])
  }

  const addFunctionCompany = (key: string) =>
    addCategory(key, {
      type: 'company',
      value: undefined,
      children: []
    })

  const addCompany = () => {
    const nextId = getNextId(null, categories)
    setCategories([
      ...categories,
      {
        id: `0-${nextId}`,
        type: 'company',
        value: undefined,
        children: []
      }
    ])
  }

  const addDimension = (key: string) =>
    addCategory(key, {
      type: 'dimension',
      value: '',
      children: []
    })

  const addRootFunction = () => {
    const nextId = getNextId(null, categories)
    const newFunction: CustomReportCategory = {
      id: `0-${nextId}`,
      type: 'function',
      value: 'sum',
      children: [
        {
          id: `0-${nextId}-0`,
          type: 'company',
          value: ''
        },
        {
          id: `0-${nextId}-1`,
          type: 'company',
          value: ''
        }
      ]
    }

    const newCategories = [...categories, newFunction]

    setCategories(newCategories)
  }

  const addFunction = (key: string) =>
    addCategory(key, {
      type: 'function',
      value: 'diff',
      children: [
        {
          type: 'periodGroup',
          value: TimePeriodOptions.CurrentMonth
        },
        {
          type: 'periodGroup',
          value: TimePeriodOptions.CurrentMonth
        }
      ]
    })

  const addPeriodGroup = (key: string) =>
    addCategory(key, {
      type: 'periodGroup',
      value: TimePeriodOptions.CurrentMonth
    })

  function resetDimensions(this: string, { children: child, ...o }: CustomReportCategory): CustomReportCategory {
    return {
      ...o,
      value: o.type === 'dimension' ? '' : o.value,
      children: child?.map(resetDimensions) // recursive call
    }
  }

  const editObject = (key: string, category: CustomReportCategory) => {
    const updateIndex = key.split('-').pop() as string
    const collectionPath = generateParentCollectionPath(key)

    let collection: CustomReportCategory[] | undefined
    let newCollection: CustomReportCategory[] = []
    let newCategories: CustomReportCategory[] = []

    if (collectionPath.includes('children')) {
      collection = dotProp.get(categories, collectionPath)

      if (!collection) return

      newCollection = collection.map((item, index) => {
        if (index !== +updateIndex) return item
        return {
          ...item,
          ...category
        }
      })

      newCategories = dotProp.set(categories, collectionPath, newCollection)
    } else {
      collection = categories

      newCollection = collection.map((item, index) => {
        if (index !== +updateIndex) return item
        return {
          ...item,
          ...{
            ...category,
            children: category.children?.map(resetDimensions)
          }
        }
      })
      newCategories = newCollection
    }

    setCategories([...newCategories])
  }

  const deleteObject = (key: string) => {
    const deleteIndex = key.split('-').pop() as string
    const collectionPath = generateParentCollectionPath(key)

    if (collectionPath.includes('children')) {
      const collection: CustomReportCategory[] | undefined = dotProp.get(categories, collectionPath)
      if (!collection) return
      const newCollection = collection?.filter((item, index) => index !== +deleteIndex)
      const newCategories = dotProp.set(categories, collectionPath, newCollection)
      setCategories([...newCategories])
    } else {
      const newCategories = categories.filter((item, index) => index !== +deleteIndex)
      setCategories([...newCategories])
    }
  }

  const reset = () => {
    setDimensionMap({})
    setCategories(_.cloneDeep(sectionCategories)?.map(mapCategoryIds) || initialCategories.map(mapCategoryIds))
  }

  type PathContext = {
    [key in CustomReportCategory['type']]?: ReactText | ReactText[]
  } & {
    companyId?: ReactText | ReactText[]
    dimensionId?: ReactText | ReactText[]
  }

  const getPathContext = (path?: string): PathContext => {
    if (!path) return {}
    const context: PathContext = {}
    path
      .split('-')
      ?.slice(1)
      ?.reduce((cats: CustomReportCategory[], key) => {
        const cat = cats?.[+key]
        if (!cat) return cats
        if (cat?.type === 'company' || cat?.type === 'dimension') {
          context[`${cat.type}Id`] = cat.value
        }
        context[`${cat.type}`] = cat.value

        return cat?.children || []
      }, categories)
    return context
  }

  const onDrop: TreeProps['onDrop'] = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: CustomReportCategory[],
      key: React.Key,
      callback: (node: CustomReportCategory, i: number, data: CustomReportCategory[]) => void
    ) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].id === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
      return null
    }

    const data = [...categories]

    // Find dragObject
    let dragObj: CustomReportCategory
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        // where to insert
        item.children.unshift(dragObj)
      })
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || []
        // where to insert
        item.children.unshift(dragObj)
      })
    } else {
      let ar: CustomReportCategory[] = []
      let i: number
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }
    setCategories(data)
  }

  return (
    <CategoryContext.Provider
      value={{
        dimensionMap,
        forecastMap,
        budgetingScenarioMap,
        categories,
        isCategoriesValid,
        dimensionLoading,
        budgetingScenariosLoading,
        forecastsLoading,
        sectionForm,
        categoryTree,
        setSectionForm,
        onDrop,
        addCompany,
        setCategories,
        addDimension,
        addFunction,
        addFunctionCompany,
        addRootFunction,
        addPeriodGroup,
        editObject,
        deleteObject,
        reset,
        getPathContext
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider
