const investmentEndpoints = {
  list: (companyId: string) => `/companies/${companyId}/budgeting/investments` as const,
  template: () => '/templates/investment-import' as const,
  import: (companyId: string) => `/companies/${companyId}/budgeting/investments/import` as const,
  export: (companyId: string) => `/companies/${companyId}/budgeting/investments/summary/export` as const,
  detail: (companyId: string, investmentId: number) =>
    `/companies/${companyId}/budgeting/investments/${investmentId}` as const,
  summary: (companyId: string) => `/companies/${companyId}/budgeting/investments/summary` as const,
  depreciation: (companyId: string, depreciationId: number) =>
    `/companies/${companyId}/budgeting/deprecation-plans/${depreciationId}/corrections` as const,
  depreciationDetail: (companyId: string, depreciationId: number, correctionId: number) =>
    `/companies/${companyId}/budgeting/deprecation-plans/${depreciationId}/corrections/${correctionId}` as const,
  depreciations: (companyId: string, investmentId: number) =>
    `/companies/${companyId}/budgeting/investments/${investmentId}/deprecations` as const
}

export default investmentEndpoints
