import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BackendRequest, useBackend } from '../../services/backend'
import { Company } from '../../types/company/Company'
import { BudgetingScenario } from '../../types/budgetingScenario/BudgetingScenario'
import { Loading } from '../Misc/Loading'
import { budgetingScenariosAndForecastsSelector } from '../../redux/context/budgetingScenarios/selectors'
import { ReportDataType } from '../../redux/context/reports/types'

const { Option } = Select

interface ScenarioSelectProps {
  initialValue?: number
  filterLockedScenarios?: boolean
  multiple?: 'multiple' | 'tags'
  companyId?: Company['id']
  dataType?: ReportDataType
  required?: boolean
}

const ScenarioSelect: React.FC<ScenarioSelectProps> = ({
  initialValue,
  filterLockedScenarios,
  multiple,
  companyId,
  dataType,
  required = false
}: ScenarioSelectProps) => {
  const { t } = useTranslation()
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const [forecastsAndScenarios, setForecastsAndScenarios] =
    useState<BudgetingScenario[]>(budgetingScenariosAndForecasts)
  const budgetinScenariosRequest = useBackend(`/api/companies/{companyId}/budgeting/budgeting-scenarios`)
  const forecastsRequest = useBackend(`/api/companies/{companyId}/budgeting/forecasts`)

  const getData = async (url: BackendRequest, selectedCompany: string) => {
    const data = await url
      .get({
        urlParams: {
          companyId: selectedCompany
        }
      })
      .then((response: BudgetingScenario[]) => {
        return response
      })
    return data
  }

  useEffect(() => {
    if (companyId) {
      const dataFetch = async () => {
        if (dataType === ReportDataType.budget)
          setForecastsAndScenarios(await getData(budgetinScenariosRequest, companyId))
        else if (dataType === ReportDataType.forecast)
          setForecastsAndScenarios(await getData(forecastsRequest, companyId))
        else
          setForecastsAndScenarios([
            ...(await getData(budgetinScenariosRequest, companyId)),
            ...(await getData(forecastsRequest, companyId))
          ])
      }
      dataFetch()
    } else {
      setForecastsAndScenarios(budgetingScenariosAndForecasts)
      if (filterLockedScenarios) {
        setForecastsAndScenarios(forecastsAndScenarios.filter(f => !f.isLocked))
      }
    }
  }, [companyId, budgetingScenariosAndForecasts])

  let formName: string | string[] = multiple ? 'budgetingScenarios' : 'budgetingScenario'
  if (companyId) {
    formName = ['subsidiaries', companyId]
  }

  if (companyId && (budgetinScenariosRequest.loading || forecastsRequest.loading)) {
    return <Loading />
  }

  return (
    <div style={{ width: '100%' }}>
      <Form.Item
        noStyle
        name={formName}
        initialValue={initialValue}
        rules={[
          {
            required,
            message: t('global:required-field')
          }
        ]}
      >
        <Select
          mode={multiple}
          placeholder={companyId ? t('global:default') : t('contextDrawer:not-applied')}
          allowClear
          maxTagCount="responsive"
          style={{ width: '100%', float: 'left' }}
        >
          {forecastsAndScenarios.map(bs => (
            <Option key={bs.id} value={bs.id}>
              {bs.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default ScenarioSelect
