import React from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { Loan } from '../types/Loan'
import RepaymentSchedule from './LoanRepaymentScedule'
import loanQueryKeys from '../queries/loanQueryKeys'

interface LoanRepaymentScheduleProps {
  loan: Loan | null
  handleOk: () => void
}

const LoanRepaymentSceduleModal: React.FC<LoanRepaymentScheduleProps> = ({
  loan,
  handleOk
}: LoanRepaymentScheduleProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return (
    <Modal
      style={{ maxWidth: '1200px' }}
      title={t('loans:repayment-schedule')}
      open={!!loan}
      onOk={() => {
        handleOk()
        queryClient.invalidateQueries({
          queryKey: loanQueryKeys.summaries()
        })
      }}
      onCancel={() => {
        handleOk()
        queryClient.invalidateQueries({
          queryKey: loanQueryKeys.summaries()
        })
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
      width="unset"
    >
      {loan && <RepaymentSchedule loanData={loan} />}
    </Modal>
  )
}

export default LoanRepaymentSceduleModal
