import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { App } from 'antd'
import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface QueryClientWrapperProps {
  children: ReactNode
}

const QueryClientWrapper = ({ children }: QueryClientWrapperProps) => {
  const { notification } = App.useApp()
  const { t } = useTranslation()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            experimental_prefetchInRender: true,
            // globally default to 20 seconds
            staleTime: 1000 * 20
          },
          mutations: {
            onError: () => {
              notification.error({
                message: t('mutation:error:message:default'),
                description: t('mutation:error:description:default')
              })
            }
          }
        }
      })
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryClientWrapper
