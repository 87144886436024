import { Col, DatePicker, Input, Row, Select, Form, Typography } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { contextCompanySelector } from '../../../../../redux/context/company/selectors'
import { loadingSelector } from '../../../../../redux/loading/selectors'
import { useAccounts } from '../../../../../features/account/queries/useFetchAccounts'
import { useBackend } from '../../../../../services/backend'
import { Customer } from '../../../../../types/customer/customer'

export const VoucherSearchFilter: React.FC = () => {
  const { t } = useTranslation()
  const { data: accounts } = useAccounts()
  const company = useSelector(contextCompanySelector)
  const { accounts: accountsLoading } = useSelector(loadingSelector)
  const [voucherTypes, setVoucherTypes] = useState<string[]>([])
  const voucherTypeRequest = useBackend(`/reporting/vouchers/types`, process.env.REACT_APP_INTEGRATION_URL)
  const [customer, setCustomer] = useState<Customer[]>([])
  const customerRequest = useBackend(`/reporting/customers/accounting`, process.env.REACT_APP_INTEGRATION_URL)

  const onContextCompanyChange = () => {
    if (company) {
      voucherTypeRequest
        .get({ body: { params: { companyId: company.id } } })
        .then((data: any) => setVoucherTypes(data))
        .catch(() => {
          setVoucherTypes([])
        })
      customerRequest
        .get({ body: { params: { companyId: company.id } } })
        .then((data: Customer[]) => {
          setCustomer(data.sort((a, b) => a.name.localeCompare(b.name)))
        })
        .catch(() => {
          setCustomer([])
        })
    }
  }

  useEffect(onContextCompanyChange, [company])

  return (
    <>
      <Row gutter={16}>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('vouchersPage:voucherType')} name="voucherType">
            <Select
              loading={voucherTypeRequest.loading}
              disabled={voucherTypeRequest.loading}
              allowClear
              mode="multiple"
            >
              {voucherTypes.map(voucherType => (
                <Select.Option key={voucherType} value={voucherType}>
                  {voucherType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('global:customer')} name="invoicePartnerId">
            <Select
              loading={customerRequest.loading}
              disabled={customerRequest.loading}
              optionFilterProp="children"
              allowClear
              showSearch
              mode="multiple"
            >
              {customer.map(c => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('vouchersPage:accounts')} name="accounts">
            <Select
              loading={accountsLoading}
              disabled={accountsLoading}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => {
                const localeString = option && `${option.key}`
                return localeString ? localeString.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
              }}
              mode="multiple"
            >
              {(_.uniqBy(accounts, 'code') || []).map(account => {
                return (
                  <Select.Option key={`${account.code} - ${account.name}`} value={account.code}>
                    {`${account.code} - ${account.name}`}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('vouchersPage:transactionDesc')} name="transactionDesc">
            <Input style={{ width: '100%' }} allowClear />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('vouchersPage:invoiceNumber')} name="invoiceNumber">
            <Input style={{ width: '100%' }} allowClear />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6} xxl={3}>
          <Form.Item label={t('vouchersPage:from')} name="from">
            <DatePicker allowClear style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6} xxl={3}>
          <Form.Item label={t('vouchersPage:until')} name="until">
            <DatePicker allowClear style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6} xxl={3}>
          <Form.Item label={t('vouchersPage:transactionMinValue')} name="transactionMinValue">
            <FormattedInputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={6} xxl={3}>
          <Form.Item label={t('vouchersPage:transactionMaxValue')} name="transactionMaxValue">
            <FormattedInputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24} sm={12} md={12} xxl={12}>
          <Form.Item label={t('vouchersPage:dimension')} name="dimension">
            <Typography.Text type="secondary">{t('vouchersPage:dimensionDescription')}</Typography.Text>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
